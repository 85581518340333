import React from "react";
import axios from "axios";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import { useState, useEffect } from "react";
import Profile from "./Profile";
import "../styling/EmpAttendance.css";
import Footer from "../components/Footer";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar2 from "../components/NavbarForAllPagesHR";
function EmpAttendance() {
  const navigate = useNavigate();
  const [attenData, setAttenData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nameCodeFilter, setNameCodeFilter] = useState("");
  const [desigFilter, setDesigFilter] = useState("");
  const [deptFilter, setDeptFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [exitFilter, setExitFilter] = useState(null);
  const [clockOutBool, setClockOutBool] = useState(false);
  const [dataForClockout, setDataForClockout] = useState([]);
  const [checked, setchecked] = useState(true);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  // const axiosJWT = JwtRequestHandler();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const today = new Date();

  function Attendence() {
    setStartDateFilter(dateValueExtractor(today));
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/empattendance`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setAttenData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });
    return () => {
      source.cancel();
    };
  }
  useEffect(Attendence, []);

  const desigs = [...new Set(attenData.map((item) => item.DESIGNATION))];
  const depts = [...new Set(attenData.map((item) => item.DEPATRTMENT))];

  function dateValueExtractor(value) {
    var date = new Date(value);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }

  function calculateHours(intime, outime) {
    if (outime === null) {
      return "Waiting...";
    }
    const inTimeValue = new Date(intime);
    const ouTimeValue = new Date(outime);
    const inTime = inTimeValue.getTime();
    const ouTime = ouTimeValue.getTime();
    const timeDiff = ouTime - inTime;
    const dateObj = new Date(timeDiff);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();
    const timeString =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return timeString;
  }

  const filteredData = attenData
    .filter((item) => {
      if (nameCodeFilter === "") {
        return item;
      } else if (
        item.EMPCODE.toLowerCase().includes(nameCodeFilter.toLowerCase()) |
        item.EMPLOYEENAME.toLowerCase().includes(nameCodeFilter.toLowerCase())
      ) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      if (desigFilter === "") {
        return item;
      } else if (item.DESIGNATION.toLowerCase() == desigFilter.toLowerCase()) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      if (deptFilter === "") {
        return item;
      } else if (item.DEPATRTMENT.toLowerCase() == deptFilter.toLowerCase()) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      if (startDateFilter === "") {
        return item;
      } else if (
        dateValueExtractor(item.DATE) === dateValueExtractor(startDateFilter)
      ) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      if ((exitFilter === "") | (exitFilter === null)) {
        return item;
      } else if (item.EXITED == exitFilter) {
        return item;
      } else {
        return null;
      }
    });
  const handleEmployeeCheckbox = (empCode) => {
    if (selectedEmployees.includes(empCode)) {
      setSelectedEmployees(
        selectedEmployees.filter((code) => code !== empCode)
      );
    } else {
      setSelectedEmployees([...selectedEmployees, empCode]);
    }
  };

  function getClockOutData() {
    if (checked === true) {
      setIsLoading(true);
      setClockOutBool(true);
      axios
        .get(`${BASEURL}/dataForClockOut`, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          setDataForClockout(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (axios.AxiosError) {
            alert("Server Error.");
            setClockOutBool(false);
            setIsLoading(false);
          }
        });
    } else {
      alert("You can't Clock Out you have selected 'On Leave' ");
    }
  }
  useEffect(getClockOutData, []);
  const handleTableRowClick = (empCode, exited) => {
    if (!exited) {
      const updatedSelectedEmp = selectedEmp.includes(empCode)
        ? selectedEmp.filter((code) => code !== empCode)
        : [...selectedEmp, empCode];
      setSelectedEmp(updatedSelectedEmp);
    }
  };
  async function clockOut() {
    if (selectedEmp.length === 0) {
      alert("Please select an employee.");
      return;
    }

    setIsLoading(true);
    const clockOutRequests = selectedEmp.map((empCode) => {
      return axios.post(
        `${BASEURL}/empattenclockout`,
        { data: { empCode } }, // Send empCode as an object
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
    });

    try {
      await Promise.all(clockOutRequests);
      alert("Clocked Out Successfully");
      window.location.reload();
    } catch (error) {
      alert("An Error Occurred. Please retry.");
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <Navbar2 />
      <div className="end">
        <header className="header__of__main">
          <h1 className="header__of__page"> Employee Attendance</h1>
        </header>
        <div className="align__emp__reset">
          {" "}
          <div className="">
            <Link to={"/RecordAttendance"}>
              <button className="Reset__btn">Record Attendance</button>
            </Link>
          </div>
          <div className="">
            <button
              className="Reset__btn"
              onClick={(e) => {
                e.preventDefault();
                const confirmBox = window.confirm(
                  "Are you sure you want to submit?"
                );
                if (confirmBox === true) {
                  clockOut();
                }
              }}
            >
              Clock Out
            </button>
          </div>
          <div>
            <button
              className="Reset__btn"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      <div>
        <h2 style={{ textAlign: "center" }} className="Titles"></h2>
      </div>

      <div className="filter_for_attendance">
        <div className="end_date">
          <p>Search: </p>
          <input
            className="filters_for_Search"
            placeholder="Employee Code or Name"
            type="text"
            onChange={(e) => {
              setNameCodeFilter(e.target.value);
            }}
          />
        </div>
        <div className="end_date">
          <p>Department: </p>
          <select
            className="filters_for_Department"
            onChange={(e) => {
              setDeptFilter(e.target.value);
            }}
          >
            <option value="">Select</option>
            {depts.map((item) => (
              <option>{item}</option>
            ))}
          </select>
        </div>
        <div className="end_date">
          <p>Designation: </p>
          <select
            className="filters_for_Designation"
            onChange={(e) => {
              setDesigFilter(e.target.value);
            }}
          >
            <option value="">Select</option>
            {desigs.map((item) => (
              <option>{item}</option>
            ))}
          </select>
        </div>
        <div className="end_date">
          <p>Date: </p>
          <input
            className="filters_for_Date"
            type="date"
            defaultValue={moment(today).format("YYYY-MM-DD")}
            onChange={(e) => {
              setStartDateFilter(e.target.value);
            }}
          />
        </div>
        <div className="end_date">
          <p>Exited: </p>
          <select
            className="filters_for_Exited"
            onChange={(e) => {
              setExitFilter(e.target.value);
            }}
          >
            <option value={""}>Select</option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>

      <div className="scroll">
        <div style={{ padding: "20px" }}>
          <table
            style={{ textTransform: "uppercase" }}
            className="employee_data_header"
          >
            <thead className="thead">
              <tr style={{ padding: "15px" }} className="employee_tr">
                <th
                  style={{
                    width: "100px",
                    textAlign: "left",
                    padding: " 0px 10px",
                  }}
                >
                  Clock Out
                </th>
                <th style={{ padding: "0px 10px ", width: "200px" }}>Date</th>
                <th
                  style={{
                    width: "130px",
                    padding: "0px 10px",
                    textAlign: "right",
                  }}
                >
                  InTime
                </th>
                <th
                  style={{
                    width: "130px",
                    padding: "0px 10px",
                    textAlign: "right",
                  }}
                >
                  Outime
                </th>
                <th
                  style={{
                    width: "230px",
                    padding: "0px 10px",
                    textAlign: "right",
                  }}
                >
                  Employee code
                </th>
                <th
                  style={{
                    width: "230px",
                    textAlign: "left",
                    padding: " 0px 10px",
                  }}
                >
                  Employee Name
                </th>
                <th
                  style={{
                    width: "230px",
                    textAlign: "left",
                    padding: " 0px 10px",
                  }}
                >
                  {" "}
                  Designation
                </th>
                <th
                  style={{
                    width: "230px",
                    textAlign: "left",
                    padding: " 0px 10px",
                  }}
                >
                  {" "}
                  Department
                </th>
                <th
                  style={{
                    width: "130px",
                    textAlign: "left",
                    padding: " 0px 10px",
                  }}
                >
                  Exited
                </th>
                {/* <th style={{ padding: "20px" }}>OnLeave</th>
              <th style={{ padding: "6px" }}>Leave Description</th> */}
                <th
                  style={{
                    width: "230px",
                    textAlign: "right",
                    padding: " 10px",
                  }}
                >
                  Hours Worked
                </th>
              </tr>
            </thead>
            <tbody className="employee_tbody">
              {filteredData.map((item) => (
                <tr
                  style={{ padding: "10px" }}
                  className="employee_tbody_tr"
                  key={item.EMPNAME}
                  onClick={() => {
                    handleTableRowClick(item.EMPCODE, item.EXITED);
                  }}
                >
                  <td
                    style={{
                      width: "100px",
                      textAlign: "left",
                      padding: " 0px 10px",
                    }}
                  >
                    <input
                      type="checkbox"
                      disabled={item.EXITED}
                      checked={selectedEmp.includes(item.EMPCODE)}
                    />
                  </td>
                  <td style={{ padding: "0px 10px ", width: "200px" }}>
                    {moment(item.DATE).format("DD/MM/YYYY")}
                  </td>
                  <td
                    className="table_align"
                    style={{
                      width: "130px",
                      padding: "0px 10px",
                      textAlign: "right",
                    }}
                  >
                    {item.INTIME ? item.INTIME.split("T")[1].slice(0, -5) : ""}
                  </td>
                  <td
                    style={{
                      width: "130px",
                      padding: "0px 10px",
                      textAlign: "right",
                    }}
                  >
                    {item.OUTIME ? item.OUTIME.split("T")[1].slice(0, -5) : ""}
                  </td>
                  <td style={{ padding: "17px", textAlign: "right" }}>
                    {item.EMPCODE}
                  </td>
                  <td
                    style={{
                      width: "330px",
                      textAlign: "left",
                      padding: " 0px 10px",
                    }}
                  >
                    {item.EMPLOYEENAME}
                  </td>
                  <td
                    style={{
                      width: "230px",
                      textAlign: "left",
                      padding: " 0px 10px",
                    }}
                  >
                    {item.DESIGNATION}
                  </td>
                  <td
                    style={{
                      width: "230px",
                      textAlign: "left",
                      padding: " 0px 10px",
                    }}
                  >
                    {item.DEPATRTMENT}
                  </td>
                  {/* <td>{moment(item.DATE).format("DD/MM/YYYY")}</td>
                <td>{item.INTIME ? item.INTIME.split("T")[1].slice(0, -5) : ""}</td>
                <td>{item.OUTIME ? item.OUTIME.split("T")[1].slice(0, -5) : ""}</td> */}
                  <td
                    style={{
                      width: "130px",
                      textAlign: "left",
                      padding: " 0px 10px",
                    }}
                  >
                    {item.EXITED ? "Yes" : "No"}
                  </td>
                  {/* <td>{item.ONLEAVE}</td>
                <td>{item.LEAVEDESCRIPTION}</td> */}
                  <td
                    style={{
                      width: "230px",
                      textAlign: "right",
                      padding: " 0px 10px",
                    }}
                  >
                    {calculateHours(item.INTIME, item.OUTIME)}
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <div>
          < p>counter</ p>
          <input></input>
        </div> */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
export default EmpAttendance;
