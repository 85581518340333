import React, { useEffect, useState } from "react";
import LoadingScreen from "../components/LoadingScreen";
import "../styling/ViewViolation.css";
import Profile from "./Profile";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar2 from "../components/NavbarForAllPagesHR";

function ViewViolations() {
  const navigate = useNavigate();
  const [detailTable, setDetailTable] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [mainTable, setMainTable] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [violationDrop, setViolationDrop] = useState([]);
  const [deptDrop, setDeptDrop] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [completeDetailsTable, setCompleteDetailsTable] = useState(false);
  const [completeDetailsData, setCompleteDetailsData] = useState([]);
  const BASEURL = process.env.REACT_APP_BASEURL;

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const dates = {
    startDate: moment(firstDayOfMonth).format("YYYY-MM-DD"),
    endDate: moment(lastDayOfMonth).format("YYYY-MM-DD"),
  };
  const [dateFilter, setDateFilter] = useState(dates);
  const [selectedVioType, setSelectedVioType] = useState("");
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");

  function getPageData() {
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/allviolationtypes`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setViolationDrop(response.data);
        axios
          .get(`${BASEURL}/deptForViolations`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setDeptDrop(response.data);
            setLoading(false);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else if (axios.AxiosError) {
              navigate("/authfailed");
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });
    return () => {
      source.cancel();
    };
  }
  useEffect(getPageData, []);

  function getDetails() {
    setLoading(true);
    axios
      .post(
        `${BASEURL}/violationFilterQuery`,
        {
          nameFilter: nameFilter,
          dateFilter: dateFilter,
          selectedVioType: selectedVioType,
          dept: selectedDept,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setMainData(response.data);
        setMainTable(true);
        setDetailTable(false);
        setCompleteDetailsTable(false);
        setLoading(false);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setLoading(false);
        navigate("/");
      });
  }

  function getDataForDetails(vioType, nameFil) {
    setLoading(true);
    if (nameFil === "") {
      axios
        .post(
          `${BASEURL}/getViolatorfromTypes`,
          {
            violationType: vioType,
            dateFilter: dateFilter,
            dept: selectedDept,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setDetailData(response.data);
          setMainTable(false);
          setDetailTable(true);
          setCompleteDetailsTable(false);
          setLoading(false);
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setLoading(false);
          navigate("/");
        });
    } else if (nameFil !== "") {
      axios
        .post(
          `${BASEURL}/getCompleteDetailData`,
          {
            nameFilter: nameFil,
            dateFilter: dateFilter,
            violation: vioType,
            dept: selectedDept,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setCompleteDetailsData(response.data);
          setLoading(false);
          setMainTable(false);
          setDetailTable(false);
          setCompleteDetailsTable(true);
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setLoading(false);
          navigate("/");
        });
    }
  }

  function calcTotalCount(array) {
    const totalCount = array.reduce(
      (total, current) => Number(total) + Number(current.VIOLATIONS),
      0
    );
    return totalCount;
  }

  if (loading === true) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Navbar2 />
      <div className="empdetials">
        <div className="end">
          <div>
            <header className="header__of__main">
              <h1 className="header__of__page"> Violations </h1>
            </header>
          </div>
          <div className="align__voilation__reset__get">
            <p>
              <button
                className="View"
                onClick={() => {
                  navigate("/newviolation");
                }}
              >
                Add New Violation
              </button>
            </p>
            <p>
              <button
                className="Various_search_btn"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reset
              </button>
            </p>
          </div>
        </div>
        <div>
          <div className="Violations_title">
            {/* <div style={{ textAlign: "center" }}>
                            <h2 className='Violations'>Violations Database</h2>

                        </div> */}
          </div>
          <div className="box_padding">
            <div>
              <div className="View_voliations">
                <div className="filters_for_violation">
                  <div className="media_filters">
                    <label htmlFor="search">Search Employee: </label>
                    {/* <div></div> */}
                    <input
                      // style={{ width: "154px" }}
                      className="inputs "
                      name="search"
                      type="text"
                      defaultValue={nameFilter}
                      onChange={(e) => {
                        setNameFilter(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="media_filters">
                    <label>Department :</label>
                    <select
                      className="inputs"
                      value={selectedDept}
                      onChange={(e) => {
                        setSelectedDept(e.target.value);
                      }}
                      style={{ width: "154px" }}
                    >
                      <option value={""}>Select</option>
                      {deptDrop.map((item) => (
                        <option value={item.DEPARTMENT}>
                          {item.DEPARTMENT}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="media_filters">
                    <label htmlFor="viotypeselector">
                      Select Violation Type:{" "}
                    </label>
                    <select
                      className="inputs "
                      // style={{ width: "154px" }}
                      name="viotypeselector"
                      value={selectedVioType}
                      onChange={(e) => {
                        setSelectedVioType(e.target.value);
                      }}
                    >
                      <option value={""}>Select</option>
                      {violationDrop.map((viol) => (
                        <option value={viol["VIOLATION TYPE"]}>
                          {viol["VIOLATION TYPE"]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className='View_voliations_filter_dates'> */}
                <div className="Date_filter_violation">
                  <div className="media_filter">
                    <label htmlFor="startDatePicker">Start Date: </label>
                    <input
                      style={{ width: "154px" }}
                      className="inputs "
                      name="startDatePicker"
                      defaultValue={moment(dateFilter.startDate).format(
                        "YYYY-MM-DD"
                      )}
                      type="date"
                      onChange={(e) => {
                        setDateFilter({
                          ...dateFilter,
                          ["startDate"]: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                  <div className="media_filter_end_date">
                    <label htmlFor="endDatePicker">End Date: </label>
                    <input
                      style={{ width: "154px" }}
                      className="inputs "
                      name="endDatePicker"
                      type="date"
                      defaultValue={moment(dateFilter.endDate).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(e) => {
                        setDateFilter({
                          ...dateFilter,
                          ["endDate"]: e.target.value,
                        });
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn_alignment">
              <button
                className="View"
                onClick={() => {
                  getDetails();
                }}
              >
                Get Details
              </button>
            </div>
          </div>

          <div className="scroll">
            <div>
              {mainTable ? (
                <div className="scroll">
                  <table
                    style={{ textTransform: "uppercase" }}
                    className="employee_data_header"
                  >
                    <thead className="thead">
                      <tr className="employee_tr">
                        <th
                          style={{
                            width: "200px",
                            padding: "0px 11px 0px",
                          }}
                        >
                          Violation Type
                        </th>
                        <th style={{ textAlign: "center" }}>Count</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="employee_tbody">
                      {mainData.map((main) => (
                        <tr key={main["DRIVER CODE"]}>
                          <td
                            style={{
                              width: "250px",
                              padding: "0px 10px 0px 10px",
                              textTransform: "uppercase",
                            }}
                          >
                            {main["VIOLATION TYPE"]}
                          </td>
                          <td
                            style={{
                              width: "30px",
                              textAlign: "right",
                              padding: "0px 60px 0px 0px",
                            }}
                          >
                            {main["VIOLATIONS"]}
                          </td>
                          <td>
                            <button
                              className="View"
                              onClick={() => {
                                var x = "";
                                getDataForDetails(
                                  main["VIOLATION TYPE"],
                                  nameFilter
                                );
                              }}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}

                      {/* <div style={{ backgroundColor: "grey", width: "300px" }}> */}
                      <tr
                        style={{ backgroundColor: "#e8eafe", width: "300px" }}
                      >
                        <td
                          style={{
                            padding: "10px",
                            textTransform: "uppercase",
                          }}
                        >
                          Total Count:
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            padding: "0px 60px 0px 0px",
                          }}
                        >
                          {calcTotalCount(mainData)}
                        </td>
                        <td></td>
                      </tr>
                      {/* </div> */}
                    </tbody>
                  </table>{" "}
                </div>
              ) : null}

              {detailTable ? (
                <div className="scroll">
                  <div className="go_back_btn2">
                    <button
                      className="View"
                      onClick={() => {
                        setDetailTable(false);
                        setMainTable(true);
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                  <div className="scroll">
                    <div>
                      <table
                        style={{ textTransform: "uppercase" }}
                        className="employee_data_header"
                      >
                        <thead className="thead">
                          <tr className="employee_tr">
                            <th style={{ padding: "10px" }}>Driver Code</th>
                            <th>Driver Name</th>
                            <th style={{ width: "300px" }}>
                              Count of Selected Violation
                            </th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="employee_tbody">
                          {detailData.map((detail) => (
                            <tr key={detail["DRIVER CODE"]}>
                              <td
                                style={{
                                  padding: "10px",
                                  textTransform: "uppercase",
                                }}
                              >
                                {detail["DRIVER CODE"]}
                              </td>
                              <td style={{ textTransform: "uppercase" }}>
                                {detail["DRIVER NAME"]}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  padding: "0px 40px",
                                }}
                              >
                                {detail["VIOLATIONS"]}
                              </td>
                              <td>
                                <button
                                  className="View"
                                  onClick={() => {
                                    setNameFilter(detail["DRIVER NAME"]);
                                    var x = "";
                                    getDataForDetails(
                                      detail["VIOLATION TYPE"],
                                      detail["DRIVER NAME"]
                                    );
                                  }}
                                >
                                  View Details
                                </button>
                              </td>
                            </tr>
                          ))}
                          <tr
                            style={{
                              backgroundColor: "#e8eafe",
                              width: "300px",
                            }}
                          >
                            <td></td>
                            <td
                              style={{
                                padding: "10px",
                                textTransform: "uppercase",
                              }}
                            >
                              Total Count:
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                padding: "0px 40px 0px 0px",
                              }}
                            >
                              {calcTotalCount(detailData)}
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div className='go_back_btn2'>
                                <button className='View' onClick={() => {
                                    setDetailTable(false);
                                    setMainTable(true);
                                }}>Go Back</button>
                            </div> */}
                </div>
              ) : null}
              {completeDetailsTable ? (
                <div className="scroll">
                  <div className="go_back_btn">
                    <button
                      className="View"
                      onClick={() => {
                        setCompleteDetailsTable(false);
                        setMainTable(true);
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                  <table
                    style={{ textTransform: "uppercase" }}
                    className="employee_data_header new__width"
                  >
                    <thead className="thead">
                      <tr className="employee_tr">
                        <th
                          style={{
                            padding: "10px",
                            textAlign: "right",
                            paddingRight: "40px",
                          }}
                        >
                          JOURNEY NO
                        </th>
                        <th>VIOLATION TYPE</th>
                        <th>DATE OF MONITORING</th>
                        <th>DATE OF VIOLATION</th>
                        <th style={{ width: "300px " }}>NARRATION</th>
                      </tr>
                    </thead>
                    <tbody className="employee_tbody">
                      {completeDetailsData.map((item) => (
                        <tr>
                          <td
                            style={{
                              padding: "10px",
                              paddingRight: "40px",
                              width: "130px",
                              textAlign: "right",
                            }}
                          >
                            {item["JOURNEY NO"]}
                          </td>
                          <td>{item["VIOLATION TYPE"]}</td>
                          <td>
                            {moment(item["DATE OF MONITORING"]).format(
                              "DD/MM/YYYY"
                            )}
                            , &nbsp;
                            {moment(item["DATE OF MONITORING"]).format(
                              "HH:mm:ss"
                            )}
                          </td>
                          <td>
                            {item["DATE OF VIOLATION"] ? (
                              <>
                                {moment(item["DATE OF VIOLATION"]).format(
                                  "DD/MM/YYYY"
                                )}
                                , &nbsp;
                                {moment(item["DATE OF VIOLATION"]).format(
                                  "HH:mm:ss"
                                )}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{item["MONITORING NARRATION"]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <div className='go_back_btn'>
                                <button className='View' onClick={() => {
                                    setCompleteDetailsTable(false);
                                    setMainTable(true);
                                }}>Go Back</button>
                            </div> */}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ViewViolations;
