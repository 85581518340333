import "../../src/styling/Navbar.css";
import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { empDetails, logIn, loginState } from "../redux/actions/actions";
import { useNavigate} from "react-router-dom";
import { CgProfile } from "react-icons/cg";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import axios from "axios";

const Navbar3 = () => {
  const dispatch = useDispatch();
  const [logoutVisible, setLogoutVisible] = useState(false);
  const ulRef = useRef(null);
  // const axiosJWT = JwtRequestHandler();
  // const authToken = window.sessionStorage.getItem("auth-token");
  const user = useSelector((state) => state.loginreducer);
  const BASEURL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setLogoutVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const ProfileIcon = ({ onClick }) => (
    <div className="profile-icon" onClick={onClick}>
      {/* <img src="profile-icon.png" alt="Profile Icon" /> */}
      <CgProfile />
    </div>
  );
const navigate = useNavigate();
  return (
    <div>
      <nav className="nav__bar">
        <header className="header__nav">
        <h2 
            onClick={()=>{
              navigate("/Home");
            }}
            >
         HR MODULE{""}
         </h2>
          <div>
            <button
              onClick={() => {
                window.history.back();
              }}
              className="Logout__btn"
            >
              Back
            </button>

            <button
              className="Logout__btn"
              onClick={() => {
                navigate("/");
                dispatch(logIn([]));
                dispatch(loginState(false));
              //   axios
              //     .post(
              //       `${BASEURL}/logout`,
              //       { token: user[0].refreshToken },
              //       {
              //         headers: {
              //          "auth-token": authToken,
              //          },
              //         }
              //     )
              //     .then((response) => {
              //       alert(response.data.message);
              //     })
              //     .catch((err) => {
              //       if (axios.AxiosError) {
              //         console.log("An Error Occurred.");
              //       }
              //     });
              //   dispatch(logIn([]));
              //   dispatch(loginState(false));
              }}
              
            >
              Logout
            </button>
          </div>
        </header>
      </nav>
    </div>
  );
};

export default Navbar3;
