import { combineReducers } from "redux";
import loginreducer from "./loginreducer";
import loginstatereducer from "./loginstatereducer";
import empreducer from "./empreducer";


const rootReducers = combineReducers({
    loginreducer,
    loginstatereducer,
    empreducer,
});

export default rootReducers;