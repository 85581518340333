import React, { useEffect, useRef } from "react";
import "../styling/Login.css";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logIn, loginState } from "../redux/actions/actions";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "../components/LoadingScreen";
import loginImg from "../Image/loginImgN.jpg";
import Footer from "../components/Footer";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
function Login() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.loginstatereducer);
  console.log(isLoggedIn);
  const [loginMode, setLoginMode] = useState(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [imageData, setImageData] = useState(null);
  const [ModuleName, setModuleName] = useState("");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const errRef = useRef();
  const values = {
    email: "",
    password: "",
    moduleName: "",
  };
  const [logindetails, setLogindetails] = useState(values);

  function handleInputs(e) {
    const { name, value } = e.target;
    setLogindetails({ ...logindetails, [name]: value });
  }

  function logUserIn(e) {
    e.preventDefault();
    console.log(logindetails);
    // if (loginMode === "1") {
    //   console.log(loginMode);
    //   setModuleName("HR");
    // } else if (loginMode === "0") {
    //   setModuleName("EMPLOYEE SELF SERVICE");
    // }

    if (loginMode === null) {
      setErrMsg("Please select mode of login.");
    } else {
      setShowPassword(false);
      setIsLoading(true);
      axios
        .post(`${BASEURL}/hrlogin`, {
          userDetails: logindetails,
        })
        .then((response) => {
          if (response.data.message) {
            setErrMsg(response.data.message);
            setIsLoading(false);
          } else {
            console.log(response);
            dispatch(logIn(response.data));
            dispatch(loginState(true));

            window.sessionStorage.setItem(
              "auth-token",
              response.data[0].authenticationToken
            );
            if (response.data[0].isAdmin == true) {
              navigate("/home");
            } else if (response.data[0].isAdmin == false) {
              setIsLoading(false);
              //Create one employee dashboard page and navigate from here
              navigate("/ClientHome");
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (axios.AxiosError) {
            setErrMsg("A server error has occurred.");
          } else {
          }
        });
    }
  }

  useEffect(() => {
    setErrMsg("");
  }, [logindetails.email, logindetails.password, loginMode]);

  const Imgfun = () => {
    setImageData(loginImg);
  };

  useEffect(() => {
    Imgfun();
  }, []);
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <div className="pic__align_login">
        <picture className="picture__login">
          <h2>HR APPLICATION</h2>
          {imageData ? (
            <img
              src={imageData}
              alt="login image"
              loading="lazy"
              style={{
                width: "650px",
                height: "350px",
              }}
            />
          ) : (
            <div className="default-image-placeholder">
              {/* Default styling when imageData is not available */}
              <div id="card">
                <div class="card-image">
                  <div class="block pulsate"></div>
                  <div class="block pulsate"></div>
                  <div class="block pulsate"></div>
                </div>
                <div class="card-image">
                  <div class="block pulsate"></div>
                  <div class="block pulsate"></div>
                </div>
                <div class="card-content">
                  <div class="block2 pulsate"></div>
                  <div class="block3 pulsate"></div>
                  <div class="circle__login pulsate"></div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </picture>
        <div className="login-box">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h2>LOGIN</h2>
          <form>
            <div className="mode__of__Login">
              <p>Mode of login</p>
              <select
                className=""
                value={loginMode ? loginMode : ""}
                onChange={(e) => {
                  setLoginMode(e.target.value);

                  logindetails.moduleName = e.target.value;
                }}
              >
                <option value={null}> Select</option>
                <option value="HR">Hr</option>
                <option value="EMPLOYEE SELF SERVICE">Employee</option>
              </select>
              {/* <p>Mode of login</p> */}
            </div>
            <div className="form__mail__pass">
              <p>Email</p>
              <input
                required
                type="email"
                id="user"
                // className='Email'
                // placeholder='Email'
                name="email"
                onChange={(e) => handleInputs(e)}
              />
            </div>
            <div className="form__mail__pass">
              <p>Password</p>
              <input
                // className='Password'
                // placeholder='Password'
                id="pass"
                required
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={(e) => handleInputs(e)}
              />
            </div>
            <div className="forgot__show__password">
              <div className="show__password__align">
                <p className="show__password"> Show Password&nbsp;</p>
                <input
                  type="checkbox"
                  onClick={() => setShowPassword(!showPassword)}
                />
                {/* <span className='forword'>Forgot pasword?</span> */}
              </div>
              <button
                type="button"
                onClick={() => {
                  alert("Please contact administrator.");
                }}
                className=""
              >
                Forgot pasword?
              </button>
            </div>
            <Link
              onClick={(e) => {
                logUserIn(e);
              }}
              href="#"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Log In
            </Link>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
