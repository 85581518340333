import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";

const EMPLOYEESALARYDETAILS = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);
  const user = state[0]?.email?.split("@")[0]?.toUpperCase();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [employeeName, setEmployeeName] = useState("");
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [leavesApplicable, setLeavesApplicable] = useState(null);
  const [listOfDepartments, setListOfDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const hist = useRef(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [systemId, setSystemId] = useState("");
  const [basicPay, setBasicPay] = useState("");
  const [currencyType, setCurrencyType] = useState(null);
  const [lstAmount, setLstAmount] = useState("");
  const [cashBenefits, setCashBenefits] = useState(null);
  const [cashAmount, setCashAmount] = useState("");
  const [nonCashBenefits, setNonCashBenefits] = useState(null);
  const [nonCashAmount, setNonCashAmount] = useState("");
  const [freeze, setFreeze] = useState(false);
  // Filters
  const filtDepartments = listOfDepartments?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["OM_DEPARTMENT_CODE", "OM_DEPARTMENT_NAME"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    return textMatch;
  });

  useEffect(() => {
    getDepartment();
  }, []);

  // Fetching List
  const getDepartment = () => {
    axios
      .get(`${BASEURL}/getDepartments`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setListOfDepartments(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Departments /getDepartment",
          err
        );
      });
  };

  // Options for the "Leaves Applicable" field
  const leavesOptions = [
    { value: "sick_leave", label: "Sick Leave" },
    { value: "casual_leave", label: "Casual Leave" },
    { value: "earned_leave", label: "Earned Leave" },
  ];

  useEffect(() => {
    // Any initial data fetching can be placed here if needed
  }, []);

  async function Save() {
    setIsLoading(true);
    try {
      // Implement the save functionality here
      // For example, you can make an API call to save the data
      setIsLoading(false);
      alert("Data saved successfully!");
    } catch (error) {
      setIsLoading(false);
      console.error("Error while saving data", error);
      alert("Error while saving data.");
    }
  }
  const currencyOptions = [
    { value: "usd", label: "USD" },
    { value: "zmk", label: "ZMK" },
  ];

  const cashBenefitsOptions = [
    { value: "housing", label: "Housing" },
    { value: "transport", label: "Transport" },
    // Add more options as needed
  ];

  const nonCashBenefitsOptions = [
    { value: "health_insurance", label: "Health Insurance" },
    { value: "gym_membership", label: "Gym Membership" },
    // Add more options as needed
  ];

  const handleSaveBasicSalary = () => {
    // Handle the save action for basic salary
    alert("Basic salary saved!");
  };

  const handleSaveLSTAmount = () => {
    // Handle the save action for LST amount
    alert("LST amount saved!");
  };

  const handleUpdateLSTToZero = () => {
    // Handle the action to update LST to 0 for all employees
    alert("Updated LST to 0 for all employees!");
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50">
      <div className="bg-slate-50 block print:hidden">
        <NavbarForAllPagesHR />
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">
            {menu ? menu : "LEAVE APPLICABLE SETUP FOR THE YEAR"}
          </h1>
        </header>

        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <section className="flex gap-3 items-center">
              <div className="  flex items-center gap-2">
                <p className="block text-gray-700 ">Search by</p>
                <Select className="w-[300px]" />
              </div>
              <div className="searchhhhhh">
                <p>Search:</p>
                <input
                  className="input_1"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
                <div className="search__icon">
                  {" "}
                  <FaSearch />
                </div>
              </div>
            </section>

            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>DEPARTMENT CODE</td>
                  <td>DEPARTMENT NAME</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filtDepartments.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_CODE
                          ? item.OM_DEPARTMENT_CODE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_NAME
                          ? item.OM_DEPARTMENT_NAME
                          : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          hist.current = true;
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {" "}
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>

      {/* Print */}
      <div className="bg-slate-50 print:block hidden">
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>DEPARTMENT CODE</td>
                  <td>DEPARTMENT NAME</td>
                </thead>
                <tbody>
                  {filtDepartments.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_CODE
                          ? item.OM_DEPARTMENT_CODE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_NAME
                          ? item.OM_DEPARTMENT_NAME
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:w-[95%] p-4 shadow-lg bg-white">
        <section className="lg:flex  items-center w-full gap-2">
          <div className="mb-4 lg:w-[350px]">
            <label className="block text-gray-700">Selected Employee</label>
            <input
              type="text"
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4 lg:w-[200px]">
            <label className="block text-gray-700">Employee Code</label>
            <input
              type="text"
              value={employeeCode}
              onChange={(e) => setEmployeeCode(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4 lg:w-[200px]">
            <label className="block text-gray-700">System ID</label>
            <input
              type="text"
              value={systemId}
              onChange={(e) => setSystemId(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
        </section>
        <section className="lg:flex justify-between items-center w-full">
          <div className="mb-4 lg:w-[250px]">
            <label className="block text-gray-700">Basic Pay</label>
            <input
              type="text"
              value={basicPay}
              onChange={(e) => setBasicPay(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4 lg:w-[150px]">
            <label className="block text-gray-700">Currency Type</label>
            <Select
              options={currencyOptions}
              value={currencyType}
              onChange={setCurrencyType}
              className="w-full"
            />
          </div>
          <button
            onClick={handleSaveBasicSalary}
            className="px-2 h-9 bg-[#002d62] text-white rounded-md text-sm"
          >
            Save Basic Salary
          </button>
          <div className="mb-4 lg:w-[150px]">
            <label className="block text-gray-700">LST Amount</label>
            <input
              type="text"
              value={lstAmount}
              onChange={(e) => setLstAmount(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <button
            onClick={handleSaveLSTAmount}
            className="px-2 h-9 bg-[#002d62] text-white rounded-md text-sm"
          >
            Save LST Amount
          </button>
          <button
            onClick={handleUpdateLSTToZero}
            className="px-2 h-9 bg-[#002d62] text-white rounded-md text-sm"
          >
            Update LST to 0 for All Employees
          </button>
        </section>
        <div className="flex gap-2"></div>
        <main className="flex justify-between gap-3">
          <aside className="w-1/2 bg-slate-50 px-2 rounded">
            <section className="lg:flex justify-between w-full mt-4">
              <div className="mb-4 lg:w-[300px]">
                <label className="block text-gray-700">Cash Benefits</label>
                <Select
                  options={cashBenefitsOptions}
                  value={cashBenefits}
                  onChange={setCashBenefits}
                  className="w-full"
                />
              </div>
              <div className="mb-4 lg:w-[250px]">
                <label className="block text-gray-700">
                  Amount (Local Currency)
                </label>
                <input
                  type="text"
                  value={cashAmount}
                  onChange={(e) => setCashAmount(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                />
              </div>
            </section>
            <section className="flex justify-end gap-3">
              <div className="flex gap-4 mt-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={freeze}
                    onChange={(e) => setFreeze(e.target.checked)}
                    className="form-checkbox h-4 w-4"
                  />
                  <span className="ml-2 text-gray-700">Freeze</span>
                </label>
              </div>
              <div className="flex gap-2 mt-4">
                <button
                  onClick={() => window.location.reload()}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  NEW
                </button>
                <button
                  onClick={() => alert("Save clicked!")}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  SAVE
                </button>
                <button
                  onClick={() => window.print()}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  PRINT
                </button>
              </div>
            </section>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>DEPARTMENT CODE</td>
                  <td>DEPARTMENT NAME</td>
                </thead>
                <tbody>
                  {filtDepartments.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_CODE
                          ? item.OM_DEPARTMENT_CODE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_NAME
                          ? item.OM_DEPARTMENT_NAME
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </aside>

          <aside className="w-1/2 bg-slate-50 px-2 rounded">
            <section className="lg:flex justify-between w-full mt-4">
              <div className="mb-4 lg:w-[300px]">
                <label className="block text-gray-700">Non-Cash Benefits</label>
                <Select
                  options={nonCashBenefitsOptions}
                  value={nonCashBenefits}
                  onChange={setNonCashBenefits}
                  className="w-full"
                />
              </div>
              <div className="mb-4 lg:w-[250px]">
                <label className="block text-gray-700">
                  Amount (Local Currency)
                </label>
                <input
                  type="text"
                  value={nonCashAmount}
                  onChange={(e) => setNonCashAmount(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                />
              </div>
            </section>
            <section className="flex justify-end gap-3">
              <div className="flex gap-4 mt-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={freeze}
                    onChange={(e) => setFreeze(e.target.checked)}
                    className="form-checkbox h-4 w-4"
                  />
                  <span className="ml-2 text-gray-700">Freeze</span>
                </label>
              </div>
              <div className="flex gap-2 mt-4">
                <button
                  onClick={() => window.location.reload()}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  NEW
                </button>
                <button
                  onClick={() => alert("Save clicked!")}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  SAVE
                </button>
                <button
                  onClick={() => window.print()}
                  className="px-4 h-9 bg-[#002d62] text-white rounded-md"
                >
                  PRINT
                </button>
              </div>
            </section>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>DEPARTMENT CODE</td>
                  <td>DEPARTMENT NAME</td>
                </thead>
                <tbody>
                  {filtDepartments.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_CODE
                          ? item.OM_DEPARTMENT_CODE
                          : "N/A"}
                      </td>
                      <td className="lg:min-w-[300px]">
                        {item.OM_DEPARTMENT_NAME
                          ? item.OM_DEPARTMENT_NAME
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </aside>
        </main>
      </div>
    </div>
  );
};

export default EMPLOYEESALARYDETAILS;
