import React, { useState, useEffect } from "react";
import Select from "react-select";
import ClientNavbar2 from "../../components/ClientNavbarAllPages";

function Expensespostingsbyoneself() {
  const [DocumentType, setDocumentType] = useState("");
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  // Select expense Type
  // Date
  // Narration
  // Job No
  // Amount
  // Submit
  return (
    <div>
      {" "}
      <ClientNavbar2 />
      <div className="end">
        <header className="header__of__main">
          <h1 className="header__of__page">Expenses postings by oneself</h1>
        </header>
      </div>
      <div className="banner__align mt-4">
        <div className="background_color">
          <div className="client__datas ">
            <div>
              <label>Select expense Type: </label>
              <Select
                className="lg:ml-2"
                styles={customStyless}
                onChange={(e) => {
                  if (e.value === "") {
                    alert("Kindly choose proper job status");
                  } else {
                    setDocumentType(e.value);
                  }
                }}
                // value={
                //   jobstatus
                //     ? {
                //         label: jobstatus,
                //         value: jobstatus,
                //       }
                //     : { label: "", value: "" }
                // }
                options={[
                  { value: "", label: "" },
                  //
                ]}
              />
            </div>

            <div className="lg:h-16 bg-white rounded items-center lg:flex p-1 lg:ml-2 lg:w-[490px]">
              <p style={{ width: "120px" }} className="lg:ml-2">
                NARRATION :{" "}
              </p>
              <textarea className="border border-1 h-14 border-black lg:ml-4 rounded  w-80 "></textarea>
            </div>
          </div>
          <div className="client__datas ">
            <div>
              <label>Date: </label>
              <input
                type="date"
                className="border border-solid-1px border-black rounded lg:ml-2"
              ></input>
            </div>
            <div>
              <label>Amount: </label>
              <input className="border border-solid-1px border-black rounded"></input>
            </div>
          </div>

          <div className="client__datas dates__select">
            <div>
              <label>Job No: </label>
              <input className="border border-solid-1px border-black rounded"></input>
            </div>
            <p>
              {" "}
              <button className="bg-blue-800 p-1 lg:ml-2 text-white rounded">
                New
              </button>
              <button className="bg-blue-800 p-1 ml-2 text-white rounded">
                Submit
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expensespostingsbyoneself;
