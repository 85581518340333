import React from "react";
import "../styling/EmployeeDetails.css";
import "../pages/Client/clientStyles.css";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Sidebar from "../components/Sidebar";
import person from "../Image/person.jpg";
import LoadingScreen from "../components/LoadingScreen";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar3 from "../components/Navbar3";
function EmployeeDetails() {
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //     content: () => componentRef.current,
  // });
  const navigate = useNavigate();
  const [datas, setData] = useState([]);
  const [imgData, setImgData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.loginreducer);
  // const axiosJWT = JwtRequestHandler();
  const [test, customTest] = useState("");
  const authToken = window.sessionStorage.getItem("auth-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const empid = useSelector((state) => state.empreducer);

  function Employeeinfo() {
    const source = axios.CancelToken.source();
    axios
      .post(
        `${BASEURL}/employeedetails`,
        { empid: empid },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setData(response.data.recordset);
        console.log(response.data.recordset[0].PORTPASSEXPIRYDATE);
        const binaryData = new Uint8Array(
          response.data.recordset[0].IMAGEBINARY?.data
        );
        const blob = new Blob([binaryData], { type: "image/jpeg" });

        const imgUrl = URL.createObjectURL(blob);
        setImgData(imgUrl);

        setIsLoading(false);

        return response; // Pass the response to the next .then block
      })
      .then((response) => {
        // Use the response from the previous .then block to access PORTPASSEXPIRYDATE

        setTimeout(() => {
          showExpiryAlertOld(response.data.recordset[0].PORTPASSEXPIRYDATE);
        }, 0);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          // navigate('/authfailed')
        }
      });

    return () => {
      source.cancel();
    };
  }
  useEffect(Employeeinfo, []);
  useEffect(() => {
    datas.forEach((item) => {
      const expiryDate = moment(item.PORTPASSEXPIRYDATE);

      if (
        expiryDate.isValid() &&
        expiryDate.isBefore(moment()) &&
        item.PORTPASSEXPIRYDATE !== "1900-01-01T00:00:00.000Z"
      ) {
        handleShowAlert();
      }
    });
  }, [datas]);

  function showExpiryAlertOld(expiryDate) {
    const today = moment();
    const expiry = moment(expiryDate);

    // Check if expiry date is in the past
    if (expiry.isBefore(today)) {
      const duration = moment.duration(today.diff(expiry));
      const years = duration.years();
      const months = duration.months();
      const days = duration.days();

      let alertMessage = "Port Pass has already expired that is ";

      if (years > 0) {
        alertMessage += `${years} ${years === 1 ? "year" : "years"}`;
      }
      if (months > 0) {
        alertMessage += ` ${months} ${months === 1 ? "month" : "months"}`;
      }
      if (days > 0) {
        alertMessage += ` ${days} ${days === 1 ? "day" : "days"}`;
      }

      if (years === 0 && months === 0 && days === 0) {
        alertMessage += " today!";
      } else {
        alertMessage += " ago!";
      }

      customTest(alertMessage); // Display the alert message
    }
  }

  const [showAlert, setShowAlert] = useState(false);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <Navbar3 />

      <div className="">
        <div>
          <header className="header__of__main">
            <h1 className="header__of__page"> Employee Profile </h1>
          </header>
        </div>
      </div>
      <div>
        {showAlert && (
          <div className="custom-alert button__Style">
            <p>{test}</p>
            <p className="align__button">
              {" "}
              <button onClick={handleCloseAlert}>Close</button>
            </p>
          </div>
        )}
      </div>
      <div className="empdetials">
        {/* <button onClick={handlePrint} className="print__button">  Print </button> */}
        {datas.map((item) => (
          <div className="Employee_details">
            <div className="banner__align">
              <div className="background_color">
                <div className="">
                  <div>
                    <img
                      src={imgData}
                      alt="No Image"
                      style={{
                        borderRadius: "50%",
                        height: "200px",
                        width: "200px",
                      }}
                    ></img>
                  </div>
                </div>
                <div className="client__datas">
                  <div>
                    <label htmlFor="search">EMPLOYEE ID:&nbsp;</label>

                    <p> {item.EMPID}</p>
                  </div>
                  <div>
                    <label htmlFor="search">EMPLOYEE CODE:&nbsp; </label>

                    <p> {item.EMPCODE}</p>
                  </div>
                </div>

                <div className="client__datas">
                  <div>
                    <lable>NAME: &nbsp;</lable>
                    <p>{item.EMPNAME}</p>
                  </div>
                  <div>
                    <lable>DESIGNATION: &nbsp;</lable>
                    <p>{item.DESIGNATION}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div>
                {" "}
                <Sidebar />
              </div> */}
            {/* <div className="image_section">
                <img
                  src={imgData}
                  alt="No Image"
                  style={{
                    borderRadius: "50%",
                    height: "200px",
                    width: "200px",
                  }}
                ></img>
               
                <div>EMPLOYEE ID : {item.EMPID}</div>
                <div style={{ width: "auto" }}>
                  EMPLOYEE CODE: {item.EMPCODE}
                </div>
                <div style={{ width: "300px" }}> NAME: {item.EMPNAME}</div>
              </div> */}
            <div className="employee__card__client  ">
              {/* <div className="line-1"></div> */}
              <div className="vl"></div>
              <div className="second_divission">
                <div className="second_info">
                  <div className="align">
                    <div>
                      <b>Name</b>
                    </div>
                    <span>{item.EMPNAME}</span>
                  </div>
                  <div className="align">
                    <div>
                      <b>Email</b>
                    </div>{" "}
                    <span style={{ textTransform: "lowercase " }}>
                      {item.EMAIL}
                    </span>
                  </div>

                  <div className="align">
                    <div>
                      <b>JoinDate</b>{" "}
                    </div>{" "}
                    <span>{moment(item.JOINDATE).format("DD/MM/YYYY")}</span>
                  </div>
                  <div className="align">
                    <div>
                      <b>National Id</b>{" "}
                    </div>{" "}
                    <span>
                      {" "}
                      {item.NATIONALID.length > 0 ? (
                        <div>{item.NATIONALID}</div>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </div>
                  <div className="align">
                    {" "}
                    <div>
                      <b>Telephone</b>
                    </div>{" "}
                    <span>
                      {" "}
                      {item.TELEPHONE.length > 0 ? (
                        <div>{item.TELEPHONE}</div>
                      ) : (
                        "N/A"
                      )}
                    </span>{" "}
                  </div>
                  <div className="align">
                    {" "}
                    <div>
                      <b>Account No</b>
                    </div>{" "}
                    <span>
                      {item.ACCOUNTNO > 0 ? <div>{item.ACCOUNTNO}</div> : "N/A"}
                    </span>
                  </div>
                  <div className="align">
                    <div>
                      <b>Designation</b>
                    </div>{" "}
                    <span> {item.DESIGNATION}</span>
                  </div>
                  <div className="align">
                    <div>
                      <b>Overtime</b>{" "}
                    </div>
                    <span> {item.OVERTIME ? "Yes" : "No"}</span>
                  </div>
                  <div className="align">
                    <div>
                      <b>Department</b>{" "}
                    </div>{" "}
                    <span> {item.DEPARTMENT}</span>
                  </div>
                  {/* <div className='align'><div><b>Vechicle Allocated</b> </div> <span>{item.VEHICLEALLOCATED.length > 0 ? <div>{item.VEHICLEALLOCATED}</div> : "N/A"}</span></div> */}
                  {/* <div className='align'><div><b>Freeze</b></div> <span> {item.FREEZE ? "Yes" : "No"}</span></div> */}
                  <div className="align">
                    <div>
                      <b>Casual</b>{" "}
                    </div>
                    <span> {item.CASUAL ? "Yes" : "No"} </span>
                  </div>
                </div>
                <div className="second_info2">
                  <div className="align2">
                    <div>
                      <b>Vechicle Allocated</b>{" "}
                    </div>{" "}
                    <span>
                      {item.VEHICLEALLOCATED.length > 0 ? (
                        <div>{item.VEHICLEALLOCATED}</div>
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </div>
                  {/* <div className='align2'><div><b>Casual</b> </div><span> {item.CASUAL ? "Yes" : "No"} </span></div> */}
                  <div className="align2">
                    <div>
                      <b>Subcontracted</b>{" "}
                    </div>
                    <span> {item.SUBCONTRACTED ? "Yes" : "No"}</span>
                  </div>
                  <div className="align2">
                    <div>
                      <b>Created By</b>{" "}
                    </div>{" "}
                    <span style={{ textTransform: "uppercase" }}>
                      {item.CREATEDBY}{" "}
                    </span>
                  </div>
                  <div className="align2">
                    <div>
                      <b> Created Date </b>
                    </div>{" "}
                    <span>{moment(item.CREATEDATE).format("DD/MM/YYYY")}</span>
                  </div>
                  <div className="align2">
                    <div>
                      {" "}
                      <b>Company Code </b>
                    </div>
                    <span> {item.COMPCODE} </span>
                  </div>
                  <div className="align2">
                    <div>
                      <b>Confirmed </b>
                    </div>{" "}
                    <span>{item.CONFIRMED ? "Yes" : "No"}</span>
                  </div>
                  <div className="align2">
                    <div>
                      <b>Kpa Port Pass Number</b>
                    </div>{" "}
                    <span>{item.KPAPORTPASS ? "Yes" : "No"}</span>
                  </div>
                  <div className="align2">
                    <div>
                      <b>Port Pass Number</b>
                    </div>{" "}
                    <span>
                      {item.PASSPORTNO ? <div>{item.PASSPORTNO}</div> : "N/A"}
                    </span>
                  </div>
                  <div className="align2">
                    <div>
                      <b>Port Pass Expiry Date </b>
                    </div>
                    <span>
                      {item.PORTPASSEXPIRYDATE !== "1900-01-01T00:00:00.000Z"
                        ? moment(item.PORTPASSEXPIRYDATE).format("DD/MM/YYYY")
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className='Profile_details1'><Profile /></div> */}
            </div>
          </div>
        ))}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default EmployeeDetails;
