import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center p-4">
      <FaExclamationTriangle className="text-6xl text-red-500 mb-4" />
      <h1 className="text-4xl font-bold mb-2">404 - Page Not Found</h1>
      <p className="text-lg mb-4">
        Sorry, the page you are looking for does not exist.
      </p>
      <a href="/Home" className="text-blue-500 underline">
        <button className="bg-blue-800 rounded px-4 text-white text-lg">
          Back
        </button>{" "}
      </a>
    </div>
  );
};

export default NotFoundPage;
