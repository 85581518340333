import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { RiDeleteBin2Line } from "react-icons/ri";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../components/LoadingScreen";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
const EmployeeOfferLetterCreation = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [offerNo, setOfferNo] = useState(null);
  const [offerDate, setOfferDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [applicantName, setApplicantName] = useState("");
  const [applicantAddress1, setApplicantAddress1] = useState("");
  const [applicantAddress2, setApplicantAddress2] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [validityDays, setValidityDays] = useState("");
  const [processedBy, setProcessedBy] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedTerm, setselectedTerm] = useState(null);
  const [descriptions, setDescriptions] = useState([]);
  const [descriptionOptions, setDescriptionOptions] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [OfferTermsheader, setOfferTermsheader] = useState([]);
  const [OfferTermsDetails, setOfferTermsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedOfferNo, setSelectedOfferNo] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const state = useSelector((state) => state.loginreducer);
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  //console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );
  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };
  console.log(state?.[0]?.email);
  const userLoggedIN = state?.[0]?.email;
  useEffect(() => {
    fetchOfferNo();
    fetchEmployees();
  }, []);

  const fetchOfferNo = async () => {
    axios
      .get(`${BASEURL}/getOfferLetterHeader`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setOfferTermsheader(response.data);
        setIsLoading(false);
      });

    try {
      const response = await axios.get(`${BASEURL}/getNewOfferNo`, {
        headers: {
          "auth-token": authToken,
        },
      });
      setOfferNo(response?.data?.[0]?.NEWOFFERID);
      console.log(response?.data?.[0]?.NEWOFFERID);
    } catch (error) {
      console.error("Error fetching getNewOfferNo:", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`${BASEURL}/getOfferTermsheader`, {
        headers: {
          "auth-token": authToken,
        },
      });
      console.log(response);
      const employeeOptions = response.data.map((employee) => ({
        value: employee?.OM_OFFER_TERM_ID,
        label: employee?.OM_OFFER_TERM_NAME,
      }));
      setEmployees(employeeOptions);
      console.log(employeeOptions);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const handleEmployeeChange = async (selectedOption) => {
    setselectedTerm(selectedOption);
    console.log(selectedOption);
    try {
      const response = await axios.get(
        `${BASEURL}/getOfferTermDetails?OM_OFFER_TERM_ID=${selectedOption.value}`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      console.log(response);
      setDescriptionOptions(response.data);
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error fetching descriptions:", error);
    }
  };

  const handleAddDescription = (description) => {
    setDescriptions([...descriptions, description]);
    setIsPopupOpen(false);
  };

  const handleDeleteDescription = (index) => {
    setDescriptions(descriptions.filter((_, i) => i !== index));
  };
  const handleSubmit = async () => {
    const dateToPass =
      offerDate.slice(8, 10) +
      "/" +
      offerDate.slice(5, 7) +
      "/" +
      offerDate.slice(0, 4);
    if (!offerNo) {
      alert(" PLEASE PROVIDE OFFER NO");
      return;
    }
    if (!dateToPass) {
      alert(" PLEASE PROVIDE OFFER DATE");
      return;
    }
    if (!applicantName) {
      alert(" PLEASE PROVIDE APPLICANT NAME");
      return;
    }
    if (!applicantAddress1) {
      alert(" PLEASE PROVIDE ADDRESS 1");
      return;
    }
    if (!applicantAddress2) {
      alert(" PLEASE PROVIDE ADDRESS 2");
      return;
    }
    if (!emailAddress) {
      alert(" PLEASE PROVIDE EMAIL ADDRESS");
      return;
    }
    if (!mobileNumber) {
      alert(" PLEASE PROVIDE MOBILE NUMBER");
      return;
    }

    if (!validityDays) {
      alert(" PLEASE PROVIDE VALIDITY DAYS");
      return;
    }
    const confirm = window.confirm("Are you sure you want to save these datas");

    if (!confirm) {
      return;
    }

    const payload = {
      header: {
        OFFERNO: offerNo,
        OFFERDATE: dateToPass,
        OFFERTOAPPLICANTNAME: applicantName,
        OFFERTOAPPLICANTADDRESS1: applicantAddress1,
        OFFERTOAPPLICANTADDRESS2: applicantAddress2,
        OFFERTOMOBILENUMBER: mobileNumber,
        OFFERTOEMAILADDRESS: emailAddress,
        OFFERVALIDITYDAYS: validityDays,
        OFFERPROCESSEDBY: userLoggedIN,
      },
      details: descriptions.map((description) => ({
        OFFERNO: offerNo,
        OFFERTERMID: selectedTerm.value,
        OFFERTERMDESCRIPTION: description,
      })),
    };

    try {
      setIsLoading(true);

      const response = await axios.post(
        `${BASEURL}/insertOfferLetter`,
        payload,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      alert(response.data.message);
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error("Error submitting data:", error);
      alert("Error submitting data. Please try again.");
    }
  };

  const filteredData = OfferTermsheader.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const offerDate = new Date(item.OT_OFFER_DATE);
    const fromDate = new Date(fromDateFilter);
    const toDate = new Date(toDateFilter);

    // Check if the offer date falls within the selected date range
    const isWithinDateRange =
      (!fromDateFilter || offerDate >= fromDate) &&
      (!toDateFilter || offerDate <= toDate);

    // If there is no search term, only apply the date filter
    if (searchData.length === 0) {
      return isWithinDateRange;
    }

    // Apply text search filter
    const textMatch = searchData.every((term) => {
      return [
        "OT_OFFER_TO_MOBILE_NUMBER",
        "OT_OFFER_NO",
        "OT_OFFER_DATE",
        "OT_OFFER_TO_APPLICANT_NAME",
        "OT_OFFER_TO_APPLICANT_ADDRESS_1",
        "OT_OFFER_TO_APPLICANT_ADDRESS_2",
        "OT_OFFER_TO_MOBILE_NUMBER",
        "OT_OFFER_TO_EMAIL_ADDRESS",
        "OT_OFFER_VALIDITY_DAYS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return isWithinDateRange && textMatch;
  });

  function refresh() {
    window.location.reload();
  }
  function HandeEditChanges(item) {
    setOfferNo(item.OT_OFFER_NO);
    setOfferDate(item.OT_OFFER_DATE.slice(0, 10));
    setApplicantName(item.OT_OFFER_TO_APPLICANT_NAME);
    setApplicantAddress1(item.OT_OFFER_TO_APPLICANT_ADDRESS_1);
    setApplicantAddress2(item.OT_OFFER_TO_APPLICANT_ADDRESS_2);
    setEmailAddress(item.OT_OFFER_TO_EMAIL_ADDRESS);
    setMobileNumber(item.OT_OFFER_TO_MOBILE_NUMBER);
    setValidityDays(item.OT_OFFER_VALIDITY_DAYS);
    fetchTermDescriptions(item.OT_OFFER_NO);
  }
  const fetchTermDescriptions = async (offerNo) => {
    try {
      const response = await axios.post(
        `${BASEURL}/getDetailedOfferTerms`,
        {
          offerLetterID: offerNo,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      console.log(response, "response");
      const descriptionsData = response.data.map(
        (detail) => detail.OM_OFFER_TERM_DESCRIPTION
      );
      console.log(descriptionsData, "descriptionsData");
      setDescriptions(descriptionsData);
    } catch (error) {
      console.error("Error fetching term descriptions:", error);
    }
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleRowClick = (offerNo) => {
    // Set the selected offer number
    setSelectedOfferNo(offerNo);
    // Open the popup
    setShowPopup(true);
    // Fetch the detailed offer terms
    fetchDetailedOfferTerms(offerNo);
  };
  const fetchDetailedOfferTerms = async (offerNo) => {
    try {
      const response = await axios.post(
        `${BASEURL}/getDetailedOfferTerms`,
        {
          offerLetterID: offerNo,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setOfferTermsDetails(response.data);
    } catch (error) {
      console.error("Error fetching detailed offer terms:", error);
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50 min-h-screen ">
      <NavbarForAllPagesHR />

      <header className="lg:flex justify-center my-1 header__of__main">
        <h1 className="header__of__page"> Employee Offer Letter Creation </h1>
      </header>
      <div className="container mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Offer Details</h2>
        <div className="lg:flex justify-between lg:flex-wrap gap-4 mb-6">
          <section className="lg:flex w-full justify-between">
            <aside className="lg:flex justify-between lg:w-[45%]">
              <div>
                <label className="block text-gray-700">OFFER NO</label>
                <input
                  type="text"
                  value={offerNo || ""}
                  readOnly
                  className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
                />
              </div>
              <div>
                <label className="block text-gray-700">OFFER DATE</label>
                <input
                  type="date"
                  value={offerDate}
                  onChange={(e) => setOfferDate(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
                />
              </div>
            </aside>
            <div className="lg:w-[45%]">
              <label className="block text-gray-700">APPLICANT NAME</label>
              <input
                type="text"
                value={applicantName}
                onChange={(e) => setApplicantName(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
              />
            </div>
          </section>
          <div className="lg:w-[45%]">
            <label className="block text-gray-700"> ADDRESS 1</label>
            <textarea
              type="text"
              value={applicantAddress1}
              onChange={(e) => setApplicantAddress1(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
            />
          </div>
          <div className="lg:w-[45%]">
            <label className="block text-gray-700"> ADDRESS 2</label>
            <textarea
              type="text"
              value={applicantAddress2}
              onChange={(e) => setApplicantAddress2(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
            />
          </div>
          <section className="lg:flex w-full justify-between">
            <div className="lg:w-[45%]">
              <label className="block text-gray-700"> EMAIL ADDRESS</label>
              <input
                type="email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
              />
            </div>{" "}
            <aside className="lg:flex justify-between lg:w-[45%]">
              <div className="">
                <label className="block text-gray-700"> MOBILE NUMBER</label>
                <input
                  type="number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
                />
              </div>
              <div className="">
                <label className="block text-gray-700">
                  OFFER VALIDITY DAYS
                </label>
                <input
                  type="number"
                  value={validityDays}
                  onChange={(e) => setValidityDays(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
                />
              </div>
            </aside>
          </section>
        </div>

        <h2 className="text-2xl font-semibold mb-4">Offer Terms</h2>
        <div className="mb-4 lg:w-[500px]">
          <label className="block text-gray-700">Select Terms</label>
          <Select
            options={employees}
            onChange={handleEmployeeChange}
            className="mt-1"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 uppercase">Descriptions</label>
          {descriptions.map((description, index) => (
            <div key={index} className="lg:flex items-center mb-2">
              <input
                type="text"
                value={description}
                readOnly
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm"
              />
              <button
                onClick={() => handleDeleteDescription(index)}
                className="ml-2 p-2 bg-red-500 text-white rounded-md"
              >
                <RiDeleteBin2Line />
              </button>
            </div>
          ))}
        </div>
        <div className="lg:flex justify-between">
          <button
            onClick={handleSubmit}
            className="px-4 py-1 bg-blue-500 text-white rounded-md"
          >
            Submit
          </button>
        </div>
      </div>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 lg:flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[80%] lg:w-[50%]">
            <div className="flex items-baseline justify-between">
              <h3 className="text-xl font-semibold mb-4">
                Choose Term Descriptions
              </h3>
              <button
                onClick={() => setIsPopupOpen(false)}
                className=" p-1 text-lg bg-red-500 text-white rounded-md"
              >
                <IoMdClose />
              </button>
            </div>

            <aside className="table__video__app uppercase">
              <table class="custom-table bg-white">
                <thead class="header-cell">
                  <td> OFFER TERM DESCRIPTION </td>
                  <td> </td>

                  {/* <td>EDIT</td> */}
                </thead>
                <tbody>
                  {descriptionOptions.map((desc, index) => (
                    <tr key={index} class="table-row">
                      <td>{desc.OM_OFFER_TERM_DESCRIPTION}</td>
                      <td>
                        <button
                          onClick={() =>
                            handleAddDescription(desc.OM_OFFER_TERM_DESCRIPTION)
                          }
                          className="ml-2 px-2 py-1 bg-blue-500 text-white rounded-md"
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      )}

      <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded text-sm justify-center lg:flex-col items-center">
        <section className="lg:flex">
          <div className="searchhhhhh">
            <p>Search:</p>
            <input
              className="h-7"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>
          </div>
          <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
            <div className="lg:ml-[10px] md:ml-0 flex items-center ">
              <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
              <input
                type="date"
                value={fromDateFilter}
                onChange={handleFromDateFilterChange}
              />
            </div>
            <div className="lf:ml-[10px] md:ml-0 flex items-center ">
              <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
              <input
                type="date"
                value={toDateFilter}
                onChange={handleToDateFilterChange}
              />
            </div>
          </section>
        </section>
        <aside className="table__video__app uppercase">
          <table class="custom-table bg-white">
            <thead class="header-cell">
              <td> OFFER NO.</td>
              <td>OFFER DATE</td>
              <td>APPLICANT NAME</td>
              <td>ADDRESS 1</td>
              <td>ADDRESS 2</td>
              <td>MOBILE NUMBER </td>
              <td>EMAIL </td>
              <td>VALIDITY DAYS</td>

              <td>EDIT</td>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index} class="table-row cursor-pointer">
                  <td
                    className="text-right"
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_NO ? item.OT_OFFER_NO : "N/A"}
                  </td>
                  <td
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_DATE
                      ? item.OT_OFFER_DATE.slice(8, 10) +
                        "/" +
                        item.OT_OFFER_DATE.slice(5, 7) +
                        "/" +
                        item.OT_OFFER_DATE.slice(0, 4)
                      : "N/A"}
                  </td>
                  <td
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_TO_APPLICANT_NAME
                      ? item.OT_OFFER_TO_APPLICANT_NAME
                      : "N/A"}
                  </td>
                  <td
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_TO_APPLICANT_ADDRESS_1
                      ? item.OT_OFFER_TO_APPLICANT_ADDRESS_1
                      : "N/A"}
                  </td>
                  <td
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_TO_APPLICANT_ADDRESS_2
                      ? item.OT_OFFER_TO_APPLICANT_ADDRESS_2
                      : "N/A"}
                  </td>
                  <td
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_TO_MOBILE_NUMBER
                      ? item.OT_OFFER_TO_MOBILE_NUMBER
                      : "N/A"}
                  </td>
                  <td
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_TO_EMAIL_ADDRESS
                      ? item.OT_OFFER_TO_EMAIL_ADDRESS
                      : "N/A"}
                  </td>

                  <td
                    className="text-right"
                    onClick={() => {
                      handleRowClick(item.OT_OFFER_NO);
                    }}
                  >
                    {item.OT_OFFER_VALIDITY_DAYS
                      ? item.OT_OFFER_VALIDITY_DAYS
                      : "N/A"}
                  </td>

                  <td
                    onClick={(e) => {
                      HandeEditChanges(item);
                      scrollToSection();
                    }}
                  >
                    {" "}
                    <FaRegEdit />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </aside>

        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 lg:flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[80%] lg:w-[50%]">
              <div className="flex items-baseline justify-between">
                <h3 className="text-xl font-semibold mb-4">
                  Offer Details for OFFER NO. {selectedOfferNo}
                </h3>
                <button
                  onClick={() => setShowPopup(false)}
                  className=" p-1 text-lg bg-red-500 text-white rounded-md"
                >
                  <IoMdClose />
                </button>
              </div>
              <aside className="table__video__app uppercase">
                <table class="custom-table bg-white">
                  <thead class="header-cell">
                    <td> OFFER LETTER ID </td>
                    <td>OFFER LETTER TERMS DESCRIPTION</td>
                  </thead>
                  <tbody>
                    {OfferTermsDetails.map((item, index) => (
                      <tr key={index} class="table-row">
                        <td className="text-right">
                          {item.OT_OFFER_LETTER_ID
                            ? item.OT_OFFER_LETTER_ID
                            : "N/A"}
                        </td>

                        {/* <td>
                    {item.OT_OFFER_LETTER_TERMS_DESCRIPTION
                      ? item.OT_OFFER_LETTER_TERMS_DESCRIPTION
                      : "N/A"}
                  </td> */}

                        <td>
                          {item.OM_OFFER_TERM_DESCRIPTION
                            ? item.OM_OFFER_TERM_DESCRIPTION
                            : "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </aside>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeOfferLetterCreation;
