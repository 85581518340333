import React, { useState } from "react";
import ClientNavbar2 from "../../components/ClientNavbarAllPages";
import { RxCross2 } from "react-icons/rx";
import Footer from "../../components/Footer";
const EmailToEmployer = () => {
  const [emails, setEmails] = useState({
    to: [""],
    cc: [""],
    bcc: [""],
  });
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (type, index, value) => {
    const updatedEmails = { ...emails };
    updatedEmails[type][index] = value;
    setEmails(updatedEmails);
  };

  const handleAddEmail = (type) => {
    setEmails({ ...emails, [type]: [...emails[type], ""] });
  };

  const handleRemoveEmail = (type, index) => {
    const updatedEmails = { ...emails };
    updatedEmails[type].splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleFileChange = (e) => {
    setAttachments([...attachments, ...e.target.files]);
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  const handleSubmit = () => {
    let formErrors = {};

    if (emails.to.some((email) => !validateEmail(email))) {
      formErrors.to = "Invalid email format in To field";
    }
    if (showCc && emails.cc.some((email) => !validateEmail(email))) {
      formErrors.cc = "Invalid email format in CC field";
    }
    if (showBcc && emails.bcc.some((email) => !validateEmail(email))) {
      formErrors.bcc = "Invalid email format in BCC field";
    }
    if (!subject) formErrors.subject = "Subject is required";
    if (!body) formErrors.body = "Email body is required";

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      // Send email logic here
      console.log("Email sent successfully!");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <ClientNavbar2 />
      <div className="max-w-2xl mx-auto p-6 bg-white shadow-md mt-10 rounded-md">
        <h1 className="text-2xl font-semibold mb-6">Compose Email</h1>
        <div className="mb-4">
          <div className="flex justify-end gap-5">
            <button
              type="button"
              className="text-blue-500"
              onClick={() => setShowBcc(!showBcc)}
            >
              {showBcc ? "Remove BCC" : "Add BCC"}
            </button>
            <button
              type="button"
              className="text-blue-500"
              onClick={() => setShowCc(!showCc)}
            >
              {showCc ? "Remove CC" : "Add CC"}
            </button>
          </div>
          <label className="block text-gray-700">To:</label>
          {emails.to.map((email, index) => (
            <div key={index} className="flex items-center mt-2">
              <input
                type="email"
                value={email}
                onChange={(e) => handleEmailChange("to", index, e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500"
              />
              <button
                type="button"
                onClick={() => handleRemoveEmail("to", index)}
                className="ml-2 text-red-500"
              >
                <RxCross2 className="h-5 w-5" />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddEmail("to")}
            className="mt-2 text-blue-500"
          >
            Add Another
          </button>
        </div>
        <div className="mb-4">
          {showCc && (
            <div className="mt-2">
              <label className="block text-gray-700">CC:</label>
              {emails.cc.map((email, index) => (
                <div key={index} className="flex items-center mt-2">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) =>
                      handleEmailChange("cc", index, e.target.value)
                    }
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveEmail("cc", index)}
                    className="ml-2 text-red-500"
                  >
                    <RxCross2 className="h-5 w-5" />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddEmail("cc")}
                className="mt-2 text-blue-500"
              >
                Add Another
              </button>
            </div>
          )}
        </div>
        <div className="mb-4">
          {showBcc && (
            <div className="mt-2">
              <label className="block text-gray-700">BCC:</label>
              {emails.bcc.map((email, index) => (
                <div key={index} className="flex items-center mt-2">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) =>
                      handleEmailChange("bcc", index, e.target.value)
                    }
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500"
                  />

                  <button
                    type="button"
                    onClick={() => handleRemoveEmail("bcc", index)}
                    className="ml-2 text-red-500"
                  >
                    <RxCross2 className="h-5 w-5" />
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddEmail("bcc")}
                className="mt-2 text-blue-500"
              >
                Add Another
              </button>
            </div>
          )}
        </div>
        {errors.to && <p className="text-red-500 text-sm">{errors.to}</p>}
        {errors.cc && <p className="text-red-500 text-sm">{errors.cc}</p>}
        {errors.bcc && <p className="text-red-500 text-sm">{errors.bcc}</p>}
        <div className="mb-4">
          <label className="block text-gray-700">Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500 mt-2"
          />
          {errors.subject && (
            <p className="text-red-500 text-sm">{errors.subject}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Body:</label>
          <textarea
            value={body}
            onChange={(e) => setBody(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500 mt-2 h-32"
          />
          {errors.body && <p className="text-red-500 text-sm">{errors.body}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Attachments:</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-indigo-500 mt-2"
          />
          {attachments.length > 0 && (
            <div className="border rounded-md p-3 mt-3">
              <h2 className="text-lg font-semibold mb-2">Attached Files:</h2>
              <ul className="list-disc pl-5">
                {attachments.map((file, index) => (
                  <li key={index} className="flex items-center">
                    {file.name}
                    <button
                      type="button"
                      onClick={() => handleRemoveAttachment(index)}
                      className="ml-2 text-red-500"
                    >
                      <RxCross2 className="h-5 w-5" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleSubmit}
            className="w-[300px] bg-indigo-500 text-white px-3 py-2 rounded-md hover:bg-indigo-600 focus:outline-none"
          >
            Send
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmailToEmployer;
