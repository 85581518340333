import React from "react";
import ClientNavbar2 from "../../components/ClientNavbarAllPages";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";
import Footer from "../../components/Footer";
function LeaveRequest() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [datas, setdatas] = useState("");
  const [Department, setDepartment] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const state = useSelector((state) => state.loginreducer);
  console.log(state);
  console.log(state[0].employeeCode);
  function EmpDetails() {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/employeedetails`,
        {
          empid: state[0].employeeCode,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.recordset[0].EMPNAME);
        setdatas(response.data.recordset[0].EMPNAME);
        setDepartment(response.data.recordset[0].DEPARTMENT);
        setIsLoading(false);

        return response; // Pass the response to the next .then block
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
      });
  }

  useEffect(EmpDetails, []);
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50">
      <ClientNavbar2 />{" "}
      <div className="end">
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <header className="header__of__main">
            <h1 className="header__of__page"> Employee Leave Request Form</h1>
          </header>
        </div>
      </div>
      <div className="max-w-3xl mx-auto p-4 bg-white shadow-md mt-2">
        <div className="mb-4">
          <label className="block mb-2">Employee Name</label>
          <input
            className="w-full rounded-md border border-current px-3 py-1"
            type="text"
            value={datas}
          />
        </div>

        <div className="flex justify-between mb-4">
          <div className="w-1/2 pr-2">
            <label className=" mb-2">Department</label>
            <input
              className="w-full rounded-md border border-current px-3 py-1"
              type="text"
              value={Department}
            />
          </div>
          <div className="w-1/2 pl-2">
            <label className="] mb-2">Supervisor Name</label>
            <input
              className="w-full rounded-md border border-current px-3 py-1"
              type="text"
            />
          </div>
        </div>

        <div className="flex justify-between mb-4">
          <div className="w-1/2 pr-2">
            <label className="block mb-2">Date</label>
            <input
              className="w-full rounded-md border border-current px-3 py-1"
              type="date"
            />
          </div>
        </div>

        <div className=" py-2 mb-2">
          <h2 className="text-lg font-semibold mb-2">Reason for Leave</h2>
          <div className="grid grid-cols-3 gap-4">
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Vacation
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Sick - Self
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Sick - Family
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Civil Leave / Jury Duty
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Military
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Sick - Dr. Appointment
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Worker's Comp
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Family and Medical
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Leave of Absence
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Funeral Relationship
            </label>
            <label className="flex items-center col-span-3">
              <input type="checkbox" className="mr-2" />
              Other
              <input
                type="text"
                className="ml-2 border border-current px-2 py-1 flex-grow"
              />
            </label>
          </div>
        </div>

        <div className="  py-2 ">
          <h2 className="text-lg font-semibold mb-2">Leave Requested</h2>
          <div className="grid grid-cols-2 gap-4 mb-2">
            <div>
              <label className=" mb-2">From</label>
              <input
                className="w-full rounded-md border border-current px-3 py-1 mb-2"
                type="date"
              />
              <input
                className="w-full rounded-md border border-current px-3 py-1"
                type="time"
              />
            </div>
            <div>
              <label className=" mb-2">To</label>
              <input
                className="w-full rounded-md border border-current px-3 py-1 mb-2"
                type="date"
              />
              <input
                className="w-full rounded-md border border-current px-3 py-1"
                type="time"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-2">
            <div className="mb-2">
              <label className=" mb-2">Total Number of Hours Requested</label>
              <input
                className="w-full rounded-md border border-current px-3 py-1"
                type="number"
              />
            </div>
            <div>
              <label className=" mb-2">Total Number of Days Requested</label>
              <input
                className="w-full rounded-md border border-current px-3 py-1"
                type="number"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 ">
            <div className="mb-4">
              <label className=" mb-2">Employee Signature</label>
              <input
                className="w-full rounded-md border border-current px-3 py-1"
                type="text"
              />
            </div>

            <div className="mb-4">
              <label className=" mb-2">Date</label>
              <input
                className="w-full rounded-md border border-current px-3 py-1"
                type="date"
              />
            </div>
          </div>
        </div>

        <div className="  py-2 mb-2">
          <h2 className="text-lg font-semibold mb-2">Supervisor Use Only</h2>
          <div className="mb-2">
            <label className="block mb-2">Comments</label>
            <textarea className="w-full rounded-md border border-current px-3 py-1"></textarea>
          </div>
          <div className="mb-2">
            <label className="block mb-2">Approved By</label>
            <input
              className="w-full rounded-md border border-current px-3 py-1"
              type="text"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Supervisor Signature</label>
            <input
              className="w-full rounded-md border border-current px-3 py-1"
              type="text"
            />
          </div>
          <div>
            <label className="block mb-2">Date</label>
            <input
              className="w-full rounded-md border border-current px-3 py-1"
              type="date"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LeaveRequest;
