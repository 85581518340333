import "../../src/styling/Navbar.css";
import React, { useState, useEffect, useRef } from "react";

import { empDetails, logIn, loginState } from "../redux/actions/actions";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FaBars, FaTimes } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import axios from "axios";
import "../styling/Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const [ModuleAccess, setModuleAccess] = useState([]);
  const [logoutVisible, setLogoutVisible] = useState(false);
  const ulRef = useRef(null);
  // const axiosJWT = JwtRequestHandler();
  // const authToken = window.sessionStorage.getItem("auth-token");
  const user = useSelector((state) => state.loginreducer);
  const authToken = window.sessionStorage.getItem("auth-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const state = useSelector((state) => state.loginreducer);
  console.log(state);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setLogoutVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const ProfileIcon = ({ onClick }) => (
    <div className="profile-icon" onClick={onClick}>
      {/* <img src="profile-icon.png" alt="Profile Icon" /> */}
      <CgProfile />
    </div>
  );
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [openGroup, setOpenGroup] = useState(null);

  const [isloading, setIsLoading] = useState(false);
  // const authToken = window.sessionStorage.getItem("auth-token");
  const navbarRef = useRef(null);

  // console.log(state);
  const modulename = state?.user?.userName;
  // console.log(state.user.emailId);
  const email = state?.user?.emailId;
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    AuthorizseUserLogin();
  }, []);
  useEffect(() => {
    scrollToSection();

    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        // Click occurred outside of navbar, close it
        setIsOpen(false);
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const logOut = () => {
    dispatch(logIn([]));
    navigate("/");
  };
  const Dashboard = () => {
    // dispatch(logIn([]));
    navigate("/PropertyManagerDashboard");
  };

  function AuthorizseUserLogin() {
    // setIsLoading(true);
    console.log(state[0]?.email);
    axios
      .post(
        `${BASEURL}/getuserrights`,
        {
          EMAIL: state[0]?.email,
          MODULENAME: "HR",
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setModuleAccess(response.data);
        // console.log(response.data.recordsets[0], "setModuleAccess");
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error In User rights /getuserrights", err);
      });
  }
  useEffect(() => {
    AuthorizseUserLogin();
  }, []);
  const handleGroupClick = (index) => {
    setOpenGroup(openGroup === index ? null : index);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // setIsDeliveryNoteClicked(false);
  };
  const handleNavigation = (page) => {
    console.log(page);
    navigate(`/${page.link}`, { state: { menu: page.menu } });
  };
  return (
    <div>
      <nav ref={navbarRef} className="centernav uppercase">
        <div className="navbar-container">
          <nav className="navbar">
            <div className="">
              <div className="fabar" onClick={toggleMenu}>
                {isMenuOpen ? <FaTimes /> : <FaBars />}
              </div>
            </div>

            <ul className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
              {isloading ? (
                <p className="text-[14px] ml-2 my-2">Loading...</p>
              ) : (
                <aside>
                  {ModuleAccess.reduce((acc, item, index) => {
                    // Check if MENU_TYPE already exists in the accumulator
                    const existingType = acc.find(
                      (group) => group.type === item.OM_ACCOUNT_MODULE_MENU_TYPE
                    );

                    if (existingType) {
                      // If MENU_TYPE exists, add the current ID to its list
                      if (typeof item === "object") {
                        existingType.ids.push({
                          name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                          link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                          menu: item.OM_ACCOUNT_MODULE_MENU,
                        });
                        existingType.weblink.push(
                          item.OM_ACCOUNT_MODULE_WEB_PAGE
                        );
                      } else {
                        existingType.ids.push(item);
                        existingType.weblink.push(existingType.weblink[index]);
                      }
                    } else {
                      // If MENU_TYPE doesn't exist, create a new group with the current ID
                      const newId =
                        typeof item === "object"
                          ? {
                              name: item.OM_ACCOUNT_MODULE_MENU_DISPLAY_NAME,
                              link: item.OM_ACCOUNT_MODULE_WEB_PAGE,
                              menu: item.OM_ACCOUNT_MODULE_MENU,
                            }
                          : item;

                      acc.push({
                        type: item.OM_ACCOUNT_MODULE_MENU_TYPE,
                        ids: [newId],
                        weblink: [item.OM_ACCOUNT_MODULE_WEB_PAGE],
                      });
                    }
                    // console.log(existingType)
                    return acc;
                  }, []).map((group, index) => (
                    <div key={index} className="text-[14px] ml-2 my-2">
                      <p
                        style={{
                          cursor: "pointer",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                          fontWeight: "600",
                        }}
                        onClick={() => handleGroupClick(index)}
                      >
                        {group.type}
                      </p>

                      {openGroup === index && (
                        <ul
                          style={{ listStyleType: "none", paddingLeft: "15px" }}
                        >
                          {group.ids.map((id, idIndex) => (
                            <li key={idIndex} style={{ cursor: "pointer" }}>
                              {typeof id === "object" && (
                                <span
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleNavigation(id)}
                                >
                                  {id.name}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </aside>
              )}
            </ul>
          </nav>
          <div className="w-[80%] flex justify-between mr-4">
            <h4 className="hover-underline-animation  hidden lg:block dashboard name__head__big__screen">
              HR MODULE
            </h4>

            <ul
              className={`navbar_links ${
                isOpen ? "hidden lg:block active" : "hidden lg:block"
              }`}
            >
              <h2 className="hover-underline-animation ">
                &nbsp;Logged in as :{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {" "}
                  {state ? state[0]?.email?.split("@")[0] : "N/A"}
                </span>
              </h2>
            </ul>
          </div>
          <div className="flex gap-[8px]">
            {/* <aside>
              <button onClick={Dashboard} className="button__logout__navbar">
                {" "}
                DASHBOARD
              </button>
            </aside> */}
            <aside>
              <button onClick={logOut} className="button__logout__navbar">
                {" "}
                LOGOUT
              </button>
            </aside>
          </div>
          <div className="logout__fullscren">
            <button onClick={logOut} className="log__out__icon">
              {" "}
              <MdLogout />
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
