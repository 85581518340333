import React from 'react';
import "../styling/NotFound.css";
function Forbidden() {
  return (
    <div className="not_found">
        <h1>403</h1>
        <p>FORBIDDEN ACCESS</p>
    </div>
  )
}
export default Forbidden