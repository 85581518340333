import React from "react";
import { useState, useEffect } from "react";
import Footer from "../components/Footer";
import "../styling/EmployeeDatabase.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { empDetails } from "../redux/actions/actions";
import Profile from "./Profile";
import LoadingScreen from "../components/LoadingScreen";
import moment from "moment";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import axios from "axios";
import Navbar2 from "../components/NavbarForAllPagesHR";
function EmployeeDatabase() {
  const [datas, setData] = useState([]);
  const [dept, setDept] = useState([]);
  const [desg, setDesg] = useState([]);
  const [empFilter, setEmpFilter] = useState("");
  const [desigFilter, setDesigFilter] = useState("");
  const [deptFilter, setDeptFilter] = useState("");
  //
  const [sortBy, setSortBy] = useState("asc"); // Default sorting order
  const [sortedColumn, setSortedColumn] = useState(null); // Default sorted column

  // const [yearsWorked, setYearsWorked] = useState(null);
  const [years, setYear] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  function Employeeinfo() {
    setStartDateFilter(moment(firstDayOfMonth).format("YYYY-MM-DD"));
    setEndDateFilter(moment(lastDayOfMonth).format("YYYY-MM-DD"));
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/allemployees`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setData(response.data);

        axios
          .get(`${BASEURL}/alldepts`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setDept(response.data);

            axios
              .get(`${BASEURL}/alldesignations`, {
                headers: {
                  "auth-token": authToken,
                },
              })
              .then((response) => {
                setDesg(response.data);
                setIsLoading(false);
              })
              .catch((err) => {
                if (axios.isCancel(err)) {
                } else if (axios.AxiosError) {
                  navigate("/authfailed");
                }
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else if (axios.AxiosError) {
              navigate("/authfailed");
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Cancelled.");
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });

    return () => {
      source.cancel();
    };
  }
  useEffect(Employeeinfo, []);
  const handleSort = () => {
    if (sortBy === "asc") {
      setSortBy("desc");
    } else {
      setSortBy("asc");
    }
    setSortedColumn("JOININGDATE"); // Set the sorted column to 'JOININGDATE'
  };

  useEffect(handleSort, []);
  let current_date = new Date();
  let current_year = current_date.getFullYear();

  const filteredData = datas
    .filter((item) => {
      if (
        empFilter === "" &&
        desigFilter === "" &&
        deptFilter === "" &&
        startDateFilter === "" &&
        endDateFilter === "" &&
        years === null
      ) {
        return null;
      }
      if (empFilter === "") {
        return item;
      } else if (
        empFilter.length > 3 &&
        item.EMPNAME.toLowerCase().includes(empFilter.toLowerCase())
      ) {
        return item;
      }
    })
    .filter((item) => {
      if (desigFilter === "") {
        return item;
      } else if (item.DESIGNATION.toLowerCase() === desigFilter.toLowerCase()) {
        return item;
      }
    })
    .filter((item) => {
      if (deptFilter === "") {
        return item;
      } else if (item.DEPARTMENT.toLowerCase() === deptFilter.toLowerCase()) {
        return item;
      }
    })
    .filter((item) => {
      if (startDateFilter && endDateFilter === "") {
        return item.JOINDATE >= startDateFilter;
      } else if (startDateFilter && endDateFilter) {
        return (
          item.JOINDATE >= startDateFilter && item.JOINDATE <= endDateFilter
        );
      } else if (endDateFilter && startDateFilter === "") {
        return item.JOINDATE <= endDateFilter;
      } else {
        return item;
      }
    })
    .filter((item) => {
      if (years === null || years === "") {
        return item;
      } else if (current_year - moment(item.JOINDATE).format("YYYY") >= years) {
        return item;
      }
    });
  const sortedData = [...filteredData];
  if (sortedColumn === "JOININGDATE") {
    sortedData.sort((a, b) => {
      const dateA = new Date(a.JOINDATE);
      const dateB = new Date(b.JOINDATE);
      return sortBy === "asc" ? dateA - dateB : dateB - dateA;
    });
  }

  const totaldesigs = [
    ...new Set(datas.map((row) => row.DESIGNATION.toLowerCase())),
  ];
  const totaldepts = [
    ...new Set(datas.map((row) => row.DEPARTMENT.toLowerCase())),
  ];
  // const total = datas.map((item) => item.DEPARTMENT.length)

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="employee-details-container">
      <Navbar2 />
      {/* <div>
                <div className='icons'>

                    <Profile />
                </div>
            </div> */}
      <div className="Search">
        <div className="Application_name">
          <header className="header__of__main">
            <h1 className="header__of__page"> Employee Details</h1>
          </header>
          <span>
            <Profile />
          </span>
        </div>
        <div className="card">
          <div className="Viewe">
            <p> Employees : </p>
            {/* <Link className='links' style={{ color: "black", textDecoration: "none" }} to={"/allemployees"}>All Employees </Link> */}
            <p> {datas.length}</p>
          </div>
          <div className="Viewe">
            <p> Departments :</p> <p>{totaldepts.length}</p>
          </div>
          <div className="Viewe">
            <p> Designations :</p> <p>{totaldesigs.length}</p>
          </div>
        </div>
        <div></div>
        <div className="bar__style">
          <div className="background_color">
            <div className="filter_options">
              <div>
                <label>Departments: </label>
                <select
                  className="Info_filters"
                  name="department"
                  id="dept"
                  onChange={(e) => {
                    setShowTable(false);
                    setDeptFilter(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {dept.map((item) => (
                    <option value={item.OM_DEPARTMENT_NAME}>
                      {item.OM_DEPARTMENT_NAME}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label style={{ textAlign: "start" }} htmlFor="yearsWorked">
                  Years of experience:{" "}
                </label>
                <input
                  className="Info_filters_years_of_exp"
                  placeholder=""
                  type="number"
                  onChange={(e) => {
                    setShowTable(false);
                    setYear(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="filter_options">
              <div>
                <label>Designation: </label>
                <select
                  className="Info_filters"
                  name="designation"
                  id="desg"
                  onChange={(e) => {
                    setShowTable(false);
                    setDesigFilter(e.target.value);
                  }}
                >
                  <option value="">select</option>
                  {desg.map((item) => (
                    <option>{item.OM_DESIGNATION_NAME}</option>
                  ))}
                </select>
              </div>
              <div>
                <label>Start Date: </label>
                <input
                  className="Info_filters"
                  style={{ width: "170px" }}
                  type="date"
                  defaultValue={startDateFilter}
                  onChange={(e) => {
                    setShowTable(false);
                    setStartDateFilter(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="Datesfilter">
              <div>
                <label>Search By Name: </label>
                <input
                  className="Info_filters"
                  type="text"
                  title="Search"
                  onChange={(e) => {
                    setShowTable(true);
                    setEmpFilter(e.target.value);
                  }}
                />
              </div>
              {/* <div>
                                    <label>Start Date</label>
                                    <input className="Info_filters" style={{ width: "132px" }} type="date" onChange={(e) => { setStartDateFilter(e.target.value) }}></input>
                                </div> */}
              <div>
                <label>End Date: </label>
                <input
                  className="Info_filters"
                  style={{ width: "167px" }}
                  defaultValue={endDateFilter}
                  type="date"
                  onChange={(e) => {
                    setShowTable(false);
                    setEndDateFilter(e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ padding: "9px 39px", width: "100%" }}>
            <div className="search__db">
              <button
                className="Various_search_btn"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reset
              </button>
              <button
                className="Various_search_btn"
                onClick={() => {
                  setShowTable(true);
                }}
              >
                Search
              </button>
            </div>
          </div>
          {/* <span className='emp_search'>Search By Name: </span>
                        <input type="text" title="Search" onChange={(e) => {
                            setShowTable(false)
                            setEmpFilter(e.target.value)
                        }} /> */}
          <div className="scroll" style={{ padding: "20px" }}>
            {showTable && sortedData.length > 0 ? (
              <div>
                <div className="record__found">
                  <p>{sortedData.length} &nbsp; Record Found</p>
                </div>
                <table
                  style={{ textTransform: "uppercase" }}
                  className="employee_data_header"
                >
                  <thead className="thead">
                    <tr className="employee_tr">
                      <th style={{ padding: "0px 10px" }}>Department</th>
                      <th>Designation</th>
                      <th> Employee Name</th>
                      <th
                        style={{ padding: "0px 10px", cursor: "pointer" }}
                        onClick={handleSort}
                      >
                        Joining Date{" "}
                        {sortedColumn === "JOININGDATE" && (
                          <span>{sortBy === "asc" ? "▲" : "▼"}</span>
                        )}
                      </th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="employee_tbody">
                    {sortedData.map((item) => (
                      <tr className="employee_tbody_tr">
                        <td style={{ padding: "0px 10px" }}>
                          {item.DEPARTMENT}
                        </td>
                        <td style={{ textTransform: "uppercase" }}>
                          {item.DESIGNATION}
                        </td>
                        <td>{item.EMPNAME}</td>
                        <td style={{ padding: "0px 10px" }}>
                          {moment(item.JOINDATE).format("DD/MM/YYYY")}
                        </td>
                        <td
                          onClick={() => {
                            dispatch(empDetails(item.EMPCODE));
                            navigate("/EmployeeDetails");
                          }}
                        >
                          {" "}
                          <button className="register_btn">View </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>{" "}
              </div>
            ) : showTable && sortedData.length === 0 ? (
              <div style={{ padding: "40px" }}>No Records Found!</div>
            ) : null}
          </div>
          {/* {datas.filter((item) => {
                                if (empFilter.length <= 3) {
                                    return null
                                } else if (empFilter.length > 3 && item.EMPNAME.toLowerCase().includes(empFilter.toLowerCase())) {
                                    return item
                                } else if (item.EMPCODE === empFilter) {
                                    return item
                                } else if (item.EMPID.toString() === empFilter) {
                                    return item
                                }
                            }) */}
          {/* .map((item) => (
                            <div className="card" onClick={() => {
                                dispatch(empDetails(item.EMPID));
                                navigate("/EmployeeDetails");
                            }}
                            > */}
          {/* <div className="card-title">Emp Id: {item.EMPID}</div>
                                <div>Emp Code: {item.EMPCODE}</div>
                                <div>Name : {item.EMPNAME}</div>
                                <div > Email : {item.EMAIL}</div>
                                <div> Designation : {item.DESIGNATION}</div>
                                <div> Department : {item.DEPARTMENT}</div>
                            </div>
                                ))} */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EmployeeDatabase;
