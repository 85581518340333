import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
const PAYTAXBANDS = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);
  const user = state[0]?.email?.split("@")[0]?.toUpperCase();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [fromSalary, setFromSalary] = useState("");
  const [toSalary, setToSalary] = useState("");
  const [selResNonRes, setSelResNonRes] = useState("");
  const [taxPerc, setTaxPerc] = useState("");
  const [addedAmount, setAddedAmount] = useState("");
  const [listOfPayTaxBands, setListOfPayTaxBands] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  // Filters
  const filtPayTaxBands = listOfPayTaxBands?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["CONTRACTNO"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    getPayetaxbands();
  }, []);

  const getPayetaxbands = () => {
    axios
      .get(`${BASEURL}/getPayetaxbands`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        setListOfPayTaxBands(res?.data);
        setFromSalary(res?.data[res.data.length - 1].PM_TAX_TO_SALARY);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Departments /getPayetaxbands",
          err
        );
      });
  };

  // Adding to the Table *******************************************

  const AddItems = () => {
    if (Number(fromSalary) < 0) {
      alert("Please Supply From Salary");
    } else if (Number(toSalary) < Number(fromSalary)) {
      alert("Please Supply To Salary");
    } else if (Number(taxPerc) < 0) {
      alert("Please Supply Tax Percentage.");
    } else if (Number(addedAmount) < 0) {
      alert("Please Supply Added Amount.");
    } else if (!selResNonRes) {
      alert("Please Supply Resident / Non Resident");
    } else {
      const newItem = {
        PM_TAX_INDEX:
          selectedRowIndex !== null
            ? selectedRowIndex + 1
            : listOfPayTaxBands.length + 1,
        PM_TAX_FROM_SALARY: fromSalary,
        PM_TAX_TO_SALARY: toSalary,
        PM_TAX_PERC: taxPerc,
        PM_TAX_ADDED_AMOUNT: addedAmount,
        PM_TAX_RESIDENT_NON_RESIDENT: selResNonRes,
      };
      const existingRowIndex = listOfPayTaxBands.findIndex(
        (charge) =>
          charge.PM_TAX_FROM_SALARY === newItem.PM_TAX_FROM_SALARY &&
          charge.PM_TAX_TO_SALARY === newItem.PM_TAX_TO_SALARY
      );

      if (existingRowIndex !== -1) {
        // Row already exists, update it
        const updatedItems = [...listOfPayTaxBands];
        updatedItems[existingRowIndex] = newItem;
        setListOfPayTaxBands(updatedItems);
      } else {
        // Row doesn't exist, add it as a new one
        setListOfPayTaxBands([...listOfPayTaxBands, newItem]);
      }

      setFromSalary(
        selectedRowIndex !== null
          ? listOfPayTaxBands[listOfPayTaxBands.length - 1].PM_TAX_TO_SALARY
          : toSalary
      );
      setToSalary("");
      setAddedAmount("");
      setTaxPerc("");
      setSelResNonRes("");
      setSelectedRowIndex(null);
    }
  };

  const RemoveItems = () => {
    const updatedValues = [...listOfPayTaxBands];
    if (updatedValues.length === 0) {
      alert("There is no Items to be removed");
    } else {
      if (
        selectedRowIndex !== null &&
        selectedRowIndex >= 0 &&
        selectedRowIndex < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex, 1);
        setSelectedRowIndex(null);
      } else {
        updatedValues.pop();
      }
      const updatedValuesWithIndices = updatedValues.map((item, index) => ({
        ...item,
        PM_TAX_INDEX: index + 1,
      }));
      setListOfPayTaxBands(updatedValuesWithIndices);
      setSelResNonRes("");
      setFromSalary(
        updatedValuesWithIndices[updatedValuesWithIndices.length - 1]
          ?.PM_TAX_TO_SALARY
      );
      setToSalary("");
      setAddedAmount("");
      setTaxPerc("");
      setSelectedRowIndex(null);
    }
  };

  // Save
  async function Save() {
    setIsLoading(true);
    let successCount = 0;
    let successMessage = null;
    let errorCount = 0;
    let errorMessage = null;
    for (let index = 0; index < listOfPayTaxBands.length; index++) {
      const value = listOfPayTaxBands[index];
      try {
        const response = await axios.post(
          `${BASEURL}/insertPayeeTaxBands`,
          {
            TAXINDEX: Number(value.PM_TAX_INDEX),
            FROMSALARY: Number(value.PM_TAX_FROM_SALARY),
            TOSALARY: Number(value.PM_TAX_TO_SALARY),
            TAXPERC: Number(value.PM_TAX_PERC),
            TAXADDEDAMOUNT: Number(value.PM_TAX_ADDED_AMOUNT),
            TAXRESIDENTNONRESIDENT: value.PM_TAX_RESIDENT_NON_RESIDENT,
            TAXCREATEDBY: user,
          },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        if (response.status === 201) {
          successCount++;
          successMessage = response.data.message;
        } else {
          errorCount++;
          errorMessage = response.data.message;
        }
      } catch (error) {
        setIsLoading(false);
        console.error(`Error while Saving Paye Tax Bands `, error);
        alert(error.response.data.message);
      }
    }
    setIsLoading(false);
    if (successCount == listOfPayTaxBands.length && successMessage) {
      alert(successMessage);
    }
    if (errorMessage) {
      alert(errorMessage);
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50">
      <div className="bg-slate-50 block print:hidden">
        <NavbarForAllPagesHR />
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="container mx-auto lg:w-[90%] p-4 shadow-lg bg-white">
          <div className="flex justify-end gap-2 mb-2">
            <button
              onClick={() => {
                window.location.reload();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              NEW
            </button>
            <button
              onClick={async () => {
                await Save();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              SAVE
            </button>
            <button
              onClick={() => {
                window.print();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              PRINT
            </button>
          </div>
          <section className="lg:flex justify-between w-full">
            <div className="mb-4 lg:w-[300px]">
              <label className="block text-gray-700">
                RESIDENT / NON RESIDENT{" "}
              </label>
              <Select
                value={
                  selResNonRes
                    ? { value: selResNonRes, label: selResNonRes }
                    : { value: "", label: "Select Resident Type" }
                }
                onChange={(e) => {
                  setSelResNonRes(e.value);
                }}
                className="w-full"
                options={[
                  { value: "", label: "Select Resident Type" },
                  { value: "Resident", label: "Resident" },
                  { value: "Non Resident", label: "Non Resident" },
                ]}
              />
            </div>
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700">FROM SALARY</label>
              <input
                type="number"
                value={fromSalary}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right"
                disabled
              />
            </div>
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700">TO SALARY</label>
              <input
                type="number"
                value={toSalary}
                onChange={(e) => setToSalary(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right"
              />
            </div>
            <div className="mb-4 lg:w-[150px]">
              <label className="block text-gray-700">% TAX </label>
              <input
                type="number"
                value={taxPerc}
                onChange={(e) => setTaxPerc(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right"
              />
            </div>
            <div className="mb-4 lg:w-[150px]">
              <label className="block text-gray-700">+ AMOUNT</label>
              <input
                type="number"
                value={addedAmount}
                onChange={(e) => setAddedAmount(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm text-right"
              />
            </div>
          </section>
          <div className="flex justify-end gap-2">
            <button
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
              onClick={() => {
                AddItems();
              }}
            >
              ADD
            </button>
            <button
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
              onClick={() => {
                RemoveItems();
              }}
            >
              REMOVE
            </button>
          </div>
        </div>

        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <div className="searchhhhhh">
              <p>Search:</p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>

            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>SNO. </td>
                  <td>FROM SALARY</td>
                  <td>TO SALARY</td>
                  <td>TAX PERCENTAGE </td>
                  <td>ADDED AMOUNT </td>
                  <td>RESIDENT / NON RESIDENT</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filtPayTaxBands.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="text-right">
                        {item.PM_TAX_INDEX ? item.PM_TAX_INDEX : ""}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_FROM_SALARY
                          ? item.PM_TAX_FROM_SALARY.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_TO_SALARY
                          ? item.PM_TAX_TO_SALARY.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_PERC ? item.PM_TAX_PERC : "0"}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_ADDED_AMOUNT
                          ? item.PM_TAX_ADDED_AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td>
                        {item.PM_TAX_RESIDENT_NON_RESIDENT
                          ? item.PM_TAX_RESIDENT_NON_RESIDENT
                          : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setFromSalary(item.PM_TAX_FROM_SALARY);
                          setToSalary(item.PM_TAX_TO_SALARY);
                          setTaxPerc(item.PM_TAX_PERC);
                          setAddedAmount(item.PM_TAX_ADDED_AMOUNT);
                          setSelResNonRes(item.PM_TAX_RESIDENT_NON_RESIDENT);
                          setSelectedRowIndex(index);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {" "}
                        <FaRegEdit size={25} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>

      {/* Print */}
      <div className="bg-slate-50 print:block hidden">
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>SNO. </td>
                  <td>FROM SALARY</td>
                  <td>TO SALARY</td>
                  <td>TAX PERCENTAGE </td>
                  <td>ADDED AMOUNT </td>
                  <td>RESIDENT / NON RESIDENT</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filtPayTaxBands.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="text-right">
                        {item.PM_TAX_INDEX ? item.PM_TAX_INDEX : ""}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_FROM_SALARY
                          ? item.PM_TAX_FROM_SALARY.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_TO_SALARY
                          ? item.PM_TAX_TO_SALARY.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_PERC ? item.PM_TAX_PERC : "0"}
                      </td>
                      <td className="text-right">
                        {item.PM_TAX_ADDED_AMOUNT
                          ? item.PM_TAX_ADDED_AMOUNT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </td>
                      <td>
                        {item.PM_TAX_RESIDENT_NON_RESIDENT
                          ? item.PM_TAX_RESIDENT_NON_RESIDENT
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PAYTAXBANDS;
