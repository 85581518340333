import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NavbarForAllPagesHR from "../components/NavbarForAllPagesHR";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import LoadingScreen from "../components/LoadingScreen";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
const LOANAPPLICATION = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);
  const user = state[0]?.email?.split("@")[0]?.toUpperCase();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [leaveType, setLeaveType] = useState("");
  const [listOfLeaveTypes, setListOfLeaveTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [loanDescription, setLoanDescription] = useState("");
  const [period, setPeriod] = useState("");
  const [deductionAmount, setDeductionAmount] = useState("");
  const hist = useRef(false);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [employeeList, setemployeeList] = useState(null);

  // Filters
  const filtLeaveTypes = listOfLeaveTypes?.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["OM_LEAVE_TYPE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    return textMatch;
  });

  useEffect(() => {
    getLeaveTypes();
  }, []);

  // Fetching List
  const getLeaveTypes = () => {
    axios
      .get(`${BASEURL}/getLeaveTypess`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((res) => {
        console.log(res?.data);
        setListOfLeaveTypes(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(
          "Error While fetching List Of Leave Types /getLeaveTypes",
          err
        );
      });
  };

  // Save
  async function Save() {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/insertLeaveType`,
        {
          LEAVETYPE: leaveType,
          LEAVETYPECREATEDBY: user,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setIsLoading(false);
      } else {
        alert(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(
        `Error while saving Leave Type Details /insertLeaveType`,
        error
      );
      alert(error.response.data.message);
    }
  }
  const employeeOptions = [
    { value: "john_doe", label: "John Doe" },
    { value: "jane_smith", label: "Jane Smith" },
    { value: "michael_johnson", label: "Michael Johnson" },
    // Add more employee options here
  ];
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div className="bg-slate-50">
      <div className="bg-slate-50 block print:hidden">
        <NavbarForAllPagesHR />
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">
            {menu ? menu : "LOAN APPLICATION"}
          </h1>
        </header>
        <div className="container mx-auto lg:w-[95%] p-4 shadow-lg bg-white">
          <div className=" flex lg:w-full gap-3 justify-end mb-2">
            <button
              onClick={() => {
                setLeaveType("");
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              NEW
            </button>
            <button
              onClick={async () => {
                if (!leaveType) {
                  alert("Kindly Enter Leave Type");
                } else {
                  await Save();
                  window.location.reload();
                }
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              SAVE
            </button>
            <button
              onClick={() => {
                window.print();
              }}
              className="px-4 h-9 bg-[#002d62] text-white rounded-md"
            >
              PRINT
            </button>
          </div>
          <section className="lg:flex justify-between w-full">
            <div className="mb-4 lg:w-[150px]">
              <label className="block text-gray-700">Loan No.</label>
              <div className="flex gap-3">
                <input
                  type="text"
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                />
                <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
                  ...
                </button>
              </div>
            </div>
            <div className="mb-4 lg:w-[400px]">
              <label className="block text-gray-700">Employee Name </label>
              <Select
                options={employeeOptions}
                value={employeeList}
                onChange={setemployeeList}
                className="w-full"
              />
            </div>
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700">Employee Code</label>
              <input
                type="test"
                value={employeeCode}
                onChange={(e) => setEmployeeCode(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700"> Date</label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>
          </section>
          <section className="lg:flex gap-5 w-full justify-between">
            <div className="mb-4 lg:w-[200px]">
              <label className="block text-gray-700">Loan Amount</label>
              <input
                type="number"
                value={loanAmount}
                onChange={(e) => setLoanAmount(e.target.value)}
                className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
              />
            </div>{" "}
            <div className="mb-4 lg:w-[800px]">
              <label className="block text-gray-700">Loan Description</label>
              <div className="flex gap-3">
                <textarea
                  type="text"
                  value={loanDescription}
                  onChange={(e) => setLoanDescription(e.target.value)}
                  className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
                />
              </div>
            </div>
          </section>
        </div>

        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded     gap-5">
          <h1 className="font-bold mt-2">Deduction Plan</h1>
          <div>
            <div className="searchhhhhh">
              <p>Search:</p>
              <input
                className="input_1"
                required
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></input>
              <div className="search__icon">
                {" "}
                <FaSearch />
              </div>
            </div>

            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>LOAN APPLICATION</td>
                  <td>EDIT</td>
                </thead>
                <tbody>
                  {filtLeaveTypes.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_LEAVE_TYPE ? item.OM_LEAVE_TYPE : "N/A"}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setLeaveType(
                            item.OM_LEAVE_TYPE ? item.OM_LEAVE_TYPE : ""
                          );
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {" "}
                        <FaRegEdit />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:w-[95%] p-4 shadow-lg bg-white">
        <section className="lg:flex gap-3 w-full items-center">
          <div className="mb-4 lg:w-[200px]">
            <label className="block text-gray-700"> Period</label>
            <input
              type="date"
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <div className="mb-4 lg:w-[200px]">
            <label className="block text-gray-700">Deduction Amout </label>
            <input
              type="test"
              value={deductionAmount}
              onChange={(e) => setDeductionAmount(e.target.value)}
              className="mt-1 block w-full px-3 py-1 border rounded-md shadow-sm"
            />
          </div>
          <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
            Add
          </button>
          <button className="px-4 h-9 bg-[#002d62] text-white rounded-md">
            Remove
          </button>
        </section>
      </div>
      {/* Print */}
      <div className="bg-slate-50 print:block hidden">
        <header className="lg:flex justify-center my-1 header__of__main">
          <h1 className="header__of__page">{menu}</h1>
        </header>
        <div className="lg:mx-5 bg-slate-50 p-1 px-3 rounded lg:flex justify-center   gap-5">
          <div>
            <aside className="table__video__app uppercase">
              <table class="custom-table">
                <thead class="header-cell">
                  <td>LEAVE TYPE</td>
                </thead>
                <tbody>
                  {filtLeaveTypes.map((item, index) => (
                    <tr key={index} className=" cursor-pointer">
                      <td className="lg:min-w-[300px]">
                        {item.OM_LEAVE_TYPE ? item.OM_LEAVE_TYPE : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LOANAPPLICATION;
