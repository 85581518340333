import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import ClientNavbar2 from "../../components/ClientNavbarAllPages";
import moment from "moment";
import LoadingScreen from "../../components/LoadingScreen";

import "./clientStyles.css";

import { BsArrowDownUp } from "react-icons/bs";
function ClientAttendance() {
  const [datas, setdatas] = useState([]);

  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  const state = useSelector((state) => state.loginreducer);
  console.log(state);
  console.log(state[0].employeeName); // Corrected the typo: console.log
  const [attenData, setAttenData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [nameCodeFilter, setNameCodeFilter] = useState("");
  const [desigFilter, setDesigFilter] = useState("");
  const [deptFilter, setDeptFilter] = useState("");
  // const [startDateFilter, setStartDateFilter] = useState("");
  const [exitFilter, setExitFilter] = useState(null);
  const [dateSortOrder, setDateSortOrder] = useState("asc");

  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [myTotalHours, setMyTotalHours] = useState("");
  const [fullTotalHours, setFullTotalHours] = useState("");
  // const axiosJWT = JwtRequestHandler();

  const today = new Date();
  function calculateFullTotalHours(data) {
    let totalHoursWorked = 0;
    data.forEach((item) => {
      const hoursWorked = calculateHours(item.INTIME, item.OUTIME);
      if (hoursWorked !== "Waiting...") {
        totalHoursWorked += convertHoursToMinutes(hoursWorked);
      }
    });
    return convertMinutesToHours(totalHoursWorked);
  }
  function EmpDetails() {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getIndividualPerformance`,
        {
          empcode: state[0].employeeCode,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setdatas(response.data);
        setAttenData(response.data);
        setIsLoading(false);

        const fullTotal = calculateFullTotalHours(response.data);
        setFullTotalHours(fullTotal);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        navigate("/");
      });
  }

  useEffect(EmpDetails, []);
  //
  // ...

  function handleStartDateChange(event) {
    setStartDateFilter(event.target.value);
  }

  function handleEndDateChange(event) {
    setEndDateFilter(event.target.value);
  }
  function handleDateSort() {
    const sortedData = [...filteredData];

    if (dateSortOrder === "asc") {
      sortedData.sort(
        (a, b) => dateValueExtractor(a.DATE) - dateValueExtractor(b.DATE)
      );
      setDateSortOrder("desc");
    } else {
      sortedData.sort(
        (a, b) => dateValueExtractor(b.DATE) - dateValueExtractor(a.DATE)
      );
      setDateSortOrder("asc");
    }

    setAttenData(sortedData); // Use setAttenData instead of setFilteredData
  }

  // ...

  const desigs = [...new Set(attenData.map((item) => item.DESIGNATION))];
  const depts = [...new Set(attenData.map((item) => item.DEPATRTMENT))];

  function dateValueExtractor(value) {
    var date = new Date(value);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }
  function calculateHours(intime, outime) {
    if (outime === null) {
      return "Waiting...";
    }
    const inTimeValue = new Date(intime);
    const ouTimeValue = new Date(outime);
    const inTime = inTimeValue.getTime();
    const ouTime = ouTimeValue.getTime();
    const timeDiff = ouTime - inTime;
    const dateObj = new Date(timeDiff);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();
    const timeString =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return timeString;
  }

  const filteredData = attenData
    .filter((item) => {
      if (nameCodeFilter === "") {
        return item;
      } else if (
        item.EMPCODE.toLowerCase().includes(nameCodeFilter.toLowerCase()) ||
        item.EMPLOYEENAME.toLowerCase().includes(nameCodeFilter.toLowerCase())
      ) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      if (desigFilter === "") {
        return item;
      } else if (item.DESIGNATION.toLowerCase() === desigFilter.toLowerCase()) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      if (deptFilter === "") {
        return item;
      } else if (item.DEPATRTMENT.toLowerCase() === deptFilter.toLowerCase()) {
        return item;
      } else {
        return null;
      }
    })
    // .filter((item) => {
    //   if (startDateFilter === "") {
    //     return item;
    //   } else if (
    //     dateValueExtractor(item.DATE) === dateValueExtractor(startDateFilter)
    //   ) {
    //     return item;
    //   } else {
    //     return null;
    //   }
    // })
    .filter((item) => {
      if ((exitFilter === "") | (exitFilter === null)) {
        return item;
      } else if (item.EXITED == exitFilter) {
        return item;
      } else {
        return null;
      }
    })
    .filter((item) => {
      // Check if the date falls within the selected date range
      if (startDateFilter && endDateFilter) {
        const dateValue = dateValueExtractor(item.DATE);
        const startDateValue = dateValueExtractor(startDateFilter);
        const endDateValue = dateValueExtractor(endDateFilter);

        return dateValue >= startDateValue && dateValue <= endDateValue;
      }

      return true;
    });

  // ... (rest of your code)
  function convertHoursToMinutes(hours) {
    const [hh, mm, ss] = hours.split(":");
    return parseInt(hh, 10) * 60 + parseInt(mm, 10) + parseInt(ss, 10) / 60;
  }

  function convertMinutesToHours(minutes) {
    const hh = String(Math.floor(minutes / 60)).padStart(2, "0");
    const mm = String(Math.floor(minutes % 60)).padStart(2, "0");
    const ss = String(Math.floor((minutes % 1) * 60)).padStart(2, "0");
    return `${hh}:${mm}:${ss}`;
  }

  let totalHoursWorked = 0;
  filteredData.forEach((item) => {
    const hoursWorked = calculateHours(item.INTIME, item.OUTIME);
    if (hoursWorked !== "Waiting...") {
      totalHoursWorked += convertHoursToMinutes(hoursWorked); // Convert hours to minutes and add
    }
  });
  //

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <ClientNavbar2 />
      <div className="end">
        <header className="header__of__main">
          <h1 className="header__of__page"> Employee Attendance</h1>
        </header>
      </div>

      <div>
        <h2 style={{ textAlign: "center" }} className="Titles"></h2>
      </div>

      {datas.length > 0 && (
        <>
          <div className="banner__align">
            <div className="background_color">
              <div className="client__datas">
                <div>
                  <label>EMPLOYEE NAME: </label>
                  <p>&nbsp; {datas[0].EMPLOYEENAME}</p>
                </div>
                <div>
                  <label>EMPLOYEE CODE: </label>
                  <p>&nbsp; {datas[0].EMPCODE}</p>
                </div>
              </div>
              <div className="client__datas">
                <div>
                  <label>Department: </label>
                  <p>&nbsp; {datas[0].DEPATRTMENT}</p>
                </div>
                <div>
                  <label>Designation: </label>
                  <p>&nbsp; {datas[0].DESIGNATION}</p>
                </div>
              </div>
              <div className="client__datas dates__select">
                {/* <div>
                  <label style={{ width: "150px" }}>Total Hours: </label>
                  <p>&nbsp;{fullTotalHours}</p>
                </div> */}

                <div>
                  <lable style={{ width: "80px" }}>Exited: </lable>
                  <select
                    className=""
                    onChange={(e) => {
                      setExitFilter(e.target.value);
                    }}
                  >
                    <option value={""}>Select</option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </select>
                </div>
              </div>
              <div className="client__datas dates__select">
                <div>
                  <label style={{ width: "80px" }}>From: </label>
                  <input
                    className=""
                    type="date"
                    value={startDateFilter}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div>
                  <label style={{ width: "80px" }}>To: </label>
                  <input
                    className=""
                    type="date"
                    value={endDateFilter}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="scroll">
        <div style={{ padding: "20px" }}>
          <table
            style={{ textTransform: "uppercase" }}
            className="employee_data_header"
          >
            <thead className="thead">
              <tr style={{ padding: "15px" }} className="employee_tr">
                <th
                  style={{
                    width: "130px",
                    padding: "0px 10px",
                    textAlign: "right",
                    cursor: "pointer", // Add this to make it look clickable
                  }}
                  onClick={handleDateSort} // Add this to handle the click
                >
                  DATE{" "}
                  {dateSortOrder === "asc" ? (
                    <span>&darr;</span>
                  ) : dateSortOrder === "desc" ? (
                    <span>&uarr;</span>
                  ) : (
                    <span>
                      <BsArrowDownUp />
                    </span>
                  )}
                </th>
                <th
                  style={{
                    width: "130px",
                    padding: "0px 10px",
                    textAlign: "right",
                  }}
                >
                  InTime
                </th>
                <th
                  style={{
                    width: "130px",
                    padding: "0px 10px",
                    textAlign: "right",
                  }}
                >
                  Outime
                </th>

                <th
                  style={{
                    width: "130px",
                    textAlign: "left",
                    padding: " 0px 10px",
                  }}
                >
                  Exited
                </th>
                {/* <th style={{ padding: "20px" }}>OnLeave</th>
              <th style={{ padding: "6px" }}>Leave Description</th> */}
                <th
                  style={{
                    width: "230px",
                    textAlign: "right",
                    padding: " 15px 10px",
                  }}
                >
                  Hours Worked
                </th>
              </tr>
            </thead>
            <tbody className="employee_tbody">
              {filteredData.map((item) => (
                <tr style={{ padding: "10px" }} className="employee_tbody_tr">
                  <td
                    style={{
                      width: "130px",
                      padding: "0px 10px",
                      textAlign: "right",
                    }}
                  >
                    {moment(item.DATE).format("DD/MM/YYYY")}
                  </td>
                  <td
                    className="table_align"
                    style={{
                      width: "130px",
                      padding: "0px 10px",
                      textAlign: "right",
                    }}
                  >
                    {item.INTIME ? item.INTIME.split("T")[1].slice(0, -5) : ""}
                  </td>
                  <td
                    style={{
                      width: "130px",
                      padding: "0px 10px",
                      textAlign: "right",
                    }}
                  >
                    {item.OUTIME ? item.OUTIME.split("T")[1].slice(0, -5) : ""}
                  </td>

                  {/* <td>{moment(item.DATE).format("DD/MM/YYYY")}</td>
                <td>{item.INTIME ? item.INTIME.split("T")[1].slice(0, -5) : ""}</td>
                <td>{item.OUTIME ? item.OUTIME.split("T")[1].slice(0, -5) : ""}</td> */}
                  <td
                    style={{
                      width: "130px",
                      textAlign: "left",
                      padding: " 0px 10px",
                    }}
                  >
                    {item.EXITED ? "Yes" : "No"}
                  </td>
                  {/* <td>{item.ONLEAVE}</td>
                <td>{item.LEAVEDESCRIPTION}</td> */}
                  <td
                    style={{
                      width: "230px",
                      textAlign: "right",
                      padding: " 15px 10px",
                    }}
                  >
                    {calculateHours(item.INTIME, item.OUTIME)}
                  </td>

                  <td></td>
                </tr>
              ))}
              <tr
                style={{
                  background: "#e8eafe",
                }}
              >
                <td
                  colSpan={4}
                  style={{
                    width: "230px",

                    padding: " 15px 10px",
                  }}
                >
                  Total Hours
                </td>
                <td
                  style={{
                    width: "230px",
                    textAlign: "right",
                    padding: " 15px 10px",
                  }}
                >
                  {convertMinutesToHours(totalHoursWorked)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ClientAttendance;
