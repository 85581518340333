import React, { useEffect, useState } from "react";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import { useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Chart } from "chart.js/auto";
import Select from "react-select";
import LoadingScreen from "../../components/LoadingScreen";
import Profile from "../Profile";
import Footer from "../../components/Footer";
import moment from "moment";
import axios from "axios";
import "../../styling/Performance.css";
import Navbar2 from "../../components/ClientNavbarAllPages";
import { useSelector } from "react-redux";
Chart.register(CategoryScale);

function ClientPerformance() {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [empData, setEmpData] = useState([]);
  const [deptDrop, setDeptDrop] = useState([]);
  const [perfDataVio, setPerfDataVio] = useState([]);
  const [perfDataAtten, setPerfDataAtten] = useState([]);
  const [datas, setdatas] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    startDate: moment(firstDayOfMonth).format("YYYY-MM-DD"),
    endDate: moment(lastDayOfMonth).format("YYYY-MM-DD"),
  });
  const initials = {
    empcode: "",
    empname: "",
  };
  const [searchInputs, setSearchInputs] = useState(initials);
  const [chartVisible, setChartVisible] = useState(false);

  //
  const state = useSelector((state) => state.loginreducer);
  console.log(state);
  console.log(state[0].employeeName); // Corrected the typo: console.log

  function EmpDetails() {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getEmployeePerformance`,
        {
          empName: state[0].employeeName,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setdatas(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        navigate("/");
      });
  }

  useEffect(EmpDetails, []);

  //

  function getEmpData() {
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/empdataforatten`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setEmpData(response.data);
        setIsLoading(false);
        axios
          .get(`${BASEURL}/alldepts`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setDeptDrop(response.data);
          })
          .catch((err) => {
            // if (axios.isCancel(err)) {
            // } else if (axios.AxiosError) {
            //   navigate("/authfailed");
            // }
          });
      })
      .catch((err) => {
        // if (axios.isCancel(err)) {
        // } else if (axios.AxiosError) {
        //   navigate("/authfailed");
        // }
      });
    return () => {
      source.cancel();
    };
  }
  useEffect(getEmpData, []);

  // function handleCodeInput(e) {
  //   setChartVisible(false);
  //   const selectedValue = e.value;
  //   const selectedOption = empData.find((obj) => obj.EMPCODE === selectedValue);
  //   setSearchInputs({
  //     ...searchInputs,
  //     empcode: selectedValue,
  //     empname: selectedOption.EMPNAME,
  //   });
  // }

  function handleNameInput() {
    setChartVisible(false);
    // const selectedValue = e.value;
    // const selectedOption = empData.find((obj) => obj.EMPNAME === selectedValue);
    setSearchInputs({
      ...searchInputs,
      empcode: state[0].employeeCode,
      empname: state[0].employeeName,
    });
  }
  useEffect(handleNameInput, []);
  function getPerformanceData(e) {
    e.preventDefault();
    const source = axios.CancelToken.source();
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/violationsForPerformance`,
        { empName: searchInputs.empname, dateFilter: dateFilter },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setPerfDataVio(response.data);
        axios
          .post(
            `${BASEURL}/attendanceForPerformance`,
            { empName: searchInputs.empname, dateFilter: dateFilter },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          )
          .then((response) => {
            setPerfDataAtten(response.data);
            setChartVisible(true);
            setIsLoading(false);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else if (axios.AxiosError) {
              navigate("/authfailed");
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });
    return () => {
      source.cancel();
    };
  }

  const data = {
    labels: ["Performance"],
    datasets: [
      {
        label: "Attendance",
        data: perfDataAtten.map((item) => item.DAYS),
        backgroundColor: "rgba(75,192,192,0.8)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Violations",
        data: perfDataVio.map((item) => item.VIOLATIONS),
        backgroundColor: "rgba(255,99,132,0.8)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 10,
      },
    ],
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: "bold",
          },
        },
        // backgroundColor: ['rgba(54, 162, 235, 0.8)', 'rgba(75, 192, 192, 0.8)', 'rgba(255, 159, 64, 0.8)'],
        // backgroundColor: "bck",
        // borderWidth: 0.3,
        // title: {
        //   display: true,
        //   text: 'Violation Type',
        //   font: {
        //     size: 14,
        //     weight: 'bold',
        //   }
        // }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Frequency",

          font: {
            size: 14,
            weight: "bold",
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    ticks: {
      font: {
        weight: "bold",
      },
    },
  };
  const chartContainerStyle = {
    width: "600px",
    height: "700px",
    margin: "20px auto",
    textAlign: "center",
    perspective: "1000px",
    paddingBottom: "40px",
  };
  if (isLoading) {
    return <LoadingScreen />;
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "10px",
      weight: 400,
      fontSize: "13px",
      border: "1.8px solid",
      "@media (max-width: 600px)": {
        // display: 'none',
        width: "230px",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <Navbar2 />
      {/* <Profile />Performance of Employees */}
      <div>
        <div className="end">
          <header className="header__of__main">
            <h1 className="header__of__page"> Employee Performance</h1>
          </header>
          <span>
            <Profile />
          </span>
        </div>
        <div>
          <p className="">
            <h4
              className="heading__enter__fiter"
              style={{ marginLeft: "30px" }}
            >
              Enter Details of Employee to Generate Performance Graph:
            </h4>
          </p>
        </div>

        <div className="for__perfomace__sort__chart">
          <table className="performace__table">
            <tr>
              <td>
                <label>Employee Code: </label>
              </td>
              <td>
                {/* <Select
                  name="empcode"
                  styles={customStyles}
                  value={
                    searchInputs.empcode
                      ? {
                          label: searchInputs.empcode,
                          value: searchInputs.empcode,
                        }
                      : { label: "Employee Code", value: "" }
                  }
                  options={empData.map((item) => ({
                    label: item.EMPCODE,
                    value: item.EMPCODE,
                  }))}
                  onChange={(e) => {
                    handleCodeInput(e);
                  }}
                /> */}
                {state[0].employeeCode}
              </td>
            </tr>
            <tr>
              <td>
                <label>Employee Name: </label>
              </td>
              <td>
                {/* <Select
                  name="empname"
                  styles={customStyles}
                  value={
                    searchInputs.empname
                      ? {
                          label: searchInputs.empname,
                          value: searchInputs.empname,
                        }
                      : { label: "Employee Name", value: "" }
                  }
                  options={datas.map((item) => ({
                    label: item.EMPLOYEENAME,
                    value: item.EMPLOYEENAME,
                  }))}
                  onChange={(e) => {
                    handleNameInput(e);
                  }}
                /> */}
                {state[0].employeeName}
              </td>
            </tr>
            <tr>
              <td>
                <label>Start Date:</label>
              </td>
              <td>
                <input
                  className="Graph__date"
                  type="date"
                  defaultValue={dateFilter.startDate}
                  onChange={(e) => {
                    setDateFilter({ ...dateFilter, startDate: e.target.value });
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label>End Date:</label>
              </td>
              <td>
                <input
                  className="Graph__date"
                  type="date"
                  defaultValue={dateFilter.endDate}
                  onChange={(e) => {
                    setDateFilter({ ...dateFilter, endDate: e.target.value });
                  }}
                ></input>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className="reset__performace">
                  <button
                    className="Various_search_btn"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="get_data"
                    onClick={(e) => {
                      getPerformanceData(e);
                    }}
                  >
                    Get Details
                  </button>
                </div>
              </td>
            </tr>
          </table>
          <div>
            {" "}
            {chartVisible ? (
              <div className="bar_graph">
                <div style={chartContainerStyle}>
                  {/* <div style={{ perspective: '1000px', paddingBottom: '40px' }}> */}
                  <Bar data={data} options={options} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ClientPerformance;
