import React from "react";
import { FaTools } from "react-icons/fa";
import ClientNavbar2 from "./ClientNavbarAllPages";
const UnderConstructionPage = () => {
  return (
    <div>
      <ClientNavbar2 />
      <div className="flex flex-col items-center justify-center h-[90vh] bg-gray-100 text-center p-4">
        <FaTools className="text-6xl text-yellow-500 mb-4" />
        <h1 className="text-4xl font-bold mb-2">Site Under Construction</h1>
        <p className="text-lg mb-4">
          We are working hard to bring you the best experience. Stay tuned!
        </p>
        <a href="/ClientHome" className="text-blue-500 underline">
          Go back to Home
        </a>
      </div>
    </div>
  );
};

export default UnderConstructionPage;
