import React, { useState, useEffect } from "react";
import "../../styling/AllEmployee.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import axios from "axios";
import ClientNavbar2 from "../../components/ClientNavbarAllPages";
import Select from "react-select";

function PaySlip() {
  const [EmployeesDatas, setEmployeesDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.loginreducer);
  console.log(user[0]?.employeeName);
  const loggedInuser = user[0]?.employeeName;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  useEffect(() => {
    axios
      .get(`${BASEURL}/allemployees`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setEmployeesDatas(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        navigate("/");
      });
  }, [BASEURL, authToken, navigate]);

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const employeeOptions = EmployeesDatas.map((employee) => ({
    value: employee.EMPCODE,
    label: employee.EMPNAME,
  }));

  const today = new Date().toISOString().split("T")[0];

  return (
    <div>
      <ClientNavbar2 />

      <header className="header__of__main">
        <h1 className="header__of__page">Pay Slip</h1>
      </header>

      <div className="lg:flex items-center my-4 mx-2 px-2 gap-5 ">
        <p>Period:</p>
        <input type="date" defaultValue={today} />
        <p>Select Employee:</p>
        <Select
          options={employeeOptions}
          onChange={handleEmployeeChange}
          className="lg:w-[400px] text-sm"
        />
        {selectedEmployee && (
          <div className="lg:flex items-center gap-3 ">
            <p>Employee Code:</p>
            <input
              type="text"
              value={selectedEmployee.value}
              readOnly
              className="p-1 border rounded"
            />
          </div>
        )}
      </div>

      <div className="mx-5 p-3 rounded bg-slate-50 flex">
        <table
          style={{ textTransform: "uppercase" }}
          className="border-solid border-[1px]  "
        >
          <tbody className="employee_tbody">
            <tr>
              <th
                className="p-2 lg:min-w-[300px]"
                style={{ textAlign: " left" }}
              >
                Employee Code:
              </th>
              <th className="p-2" style={{ textAlign: " right" }}>
                {selectedEmployee?.value}
              </th>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                National ID:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                987654321
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                NSSF No:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                NSSF123
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                NHIF No:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                NHIF123
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                KRA Pin:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                KRA123
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Payroll Period:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                June 2024
              </td>
            </tr>
            <tr>
              <td
                className="p-4"
                style={{ textAlign: " left" }}
                colSpan={2}
              ></td>
            </tr>
            <tr>
              <th className="p-2" style={{ textAlign: " left" }}>
                EARNINGS{" "}
              </th>
              <td className="p-2" style={{ textAlign: " right" }}></td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Basic Pay:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                50,000
              </td>
            </tr>
            <tr>
              <td
                className="p-4"
                style={{ textAlign: " left" }}
                colSpan={2}
              ></td>
            </tr>
            <tr>
              <th className="p-2" style={{ textAlign: " left" }}>
                Allowances:
              </th>
              <td className="p-2" style={{ textAlign: " right" }}></td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Cash Benefits:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                5,000
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Non-Cash Benefits:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                3,000
              </td>
            </tr>
            <tr>
              <td
                className="p-4"
                style={{ textAlign: " left" }}
                colSpan={2}
              ></td>
            </tr>
            <tr>
              <th className="p-2" style={{ textAlign: " left" }}>
                Gross Pay:
              </th>
              <th className="p-2" style={{ textAlign: " right" }}>
                68,000
              </th>
            </tr>
            <tr>
              <td
                className="p-4"
                style={{ textAlign: " left" }}
                colSpan={2}
              ></td>
            </tr>
            <tr>
              <th className="p-2" style={{ textAlign: " left" }}>
                DEDUCTION{" "}
              </th>
              <td className="p-2" style={{ textAlign: " right" }}></td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                PAYE:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                12,000
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                NSSF:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                2,000
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                NHIF:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                1,500
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Advance:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                5,000
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Loan:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                3,000
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Absenteeism:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                1,000
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Penalty:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                500
              </td>
            </tr>
            <tr>
              <td className="p-2" style={{ textAlign: " left" }}>
                Total Deductions:
              </td>
              <td className="p-2" style={{ textAlign: " right" }}>
                23,000
              </td>
            </tr>
            <tr>
              <td
                className="p-4"
                style={{ textAlign: " left" }}
                colSpan={2}
              ></td>
            </tr>
            <tr>
              <th className="p-2" style={{ textAlign: " left" }}>
                NET PAY:
              </th>
              <th className="p-2" style={{ textAlign: " right" }}>
                45,000
              </th>
            </tr>
            <tr>
              <td
                className="p-4"
                style={{ textAlign: " left" }}
                colSpan={2}
              ></td>
            </tr>{" "}
            <tr>
              <th className="p-2" style={{ textAlign: " left" }}>
                DATE:
              </th>
              <th className="p-2" style={{ textAlign: " right" }}>
                {today}{" "}
              </th>
            </tr>
            <tr>
              <th className="p-2" style={{ textAlign: " left" }}>
                Prepared By:
              </th>
              <th className="p-2" style={{ textAlign: " right" }}>
                {loggedInuser}{" "}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaySlip;
