import React from "react";
import { useState, useEffect } from "react";
import "../styling/AllEmployee.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { empDetails } from "../redux/actions/actions";
import Profile from "../pages/Profile";
// import { css } from "@emotion/react";
import LoadingScreen from "../components/LoadingScreen";
// import JwtRequestHandler from '../utils/JwtRequestHandler';
import axios from "axios";

function AllEmployees() {
  const [datas, setdatas] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const recordPerPage = 20;
  const [page, setPage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const user = useSelector((state) => state.loginreducer);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");

  function EmployeeDetails() {
    axios
      .get(`${BASEURL}/allemployees`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setdatas(response.data);
        setIsLoading(false);
      }) .catch(() => {
        alert("Internal server error! please try after some time");
        setIsLoading(false);
        navigate("/");
      });
  }

  useEffect(EmployeeDetails, []);

  const lastIndex = currentPage * recordPerPage;
  const firstIndex = lastIndex - recordPerPage;
  const records = datas.slice(firstIndex, lastIndex);

  //     const override = css`
  //     display: block;
  //     margin: 0 auto;
  //     border-color: red;
  //   `;

  // const override: CSSProperties = {
  //     display: "block",
  //     margin: "0 auto",
  //     borderColor: "red",
  // };
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <div className="end">
        <Profile />
      </div>
      <h1 style={{ textAlign: "center" }} className="Titles">
        All Employees Details
      </h1>
      <div className="scroll">
        <table
          style={{ textTransform: "uppercase" }}
          className="employee_data_header"
        >
          <thead className="thead">
            <tr className="employee_tr">
              <th style={{ padding: "0px 10px" }}>Employee Id </th>
              <th>Employee Code </th>
              <th style={{ textAlign: "left" }}>Employee Name </th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody className="employee_tbody">
            {records.map((item) => (
              <tr className="employee_tbody_tr" key={item.EMPID}>
                <td
                  className="td"
                  style={{
                    textAlign: "right",
                    margin: "4px 4px",
                    padding: "0px 74px 0px 0px",
                  }}
                >
                  {item.EMPID}
                </td>
                <td className="td">{item.EMPCODE}</td>
                <td className="td" style={{ textAlign: "left" }}>
                  {item.EMPNAME}
                </td>
                <td
                  className="td"
                  style={{ padding: "7px", textAlign: "center" }}
                >
                  <button
                    className="View"
                    onClick={() => {
                      dispatch(empDetails(item.EMPNAME));
                      navigate("/employeedetails");
                    }}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div>
          <button
            style={
              currentPage === 1
                ? { visibility: "hidden" }
                : { visibility: "visible" }
            }
            className="Violation_btn"
            onClick={() => {
              setcurrentPage(Number(currentPage) - 1);
              if (currentPage <= 1) {
                setcurrentPage(1);
              } else {
                setcurrentPage(Number(currentPage) - 1);
              }
            }}
          >
            &lt;
          </button>
        </div>
        <input
          className="enter_no"
          type="number"
          placeholder="Go-to Page"
          onChange={(e) => {
            setPage(e.target.value);
          }}
        ></input>
        <button
          className="View"
          onClick={() => {
            if (page <= 0) {
              setcurrentPage(1);
            } else if (page > Math.ceil(Number(datas.length / 20))) {
              setcurrentPage(Math.ceil(Number(datas.length / 20)));
            } else {
              setcurrentPage(page);
            }
          }}
        >
          Go-to
        </button>
        <div>
          <button
            style={
              currentPage === Math.ceil(Number(datas.length / 20))
                ? { visibility: "hidden" }
                : { visibility: "visible" }
            }
            className="Violation_btn"
            onClick={() => {
              setcurrentPage(Number(currentPage) + 1);
              if (currentPage >= Math.ceil(Number(datas.length / 20))) {
                setcurrentPage(Math.ceil(Number(datas.length / 20)));
              } else {
                setcurrentPage(Number(currentPage) + 1);
              }
            }}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}
export default AllEmployees;
