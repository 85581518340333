import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Profile from "../Profile";
import "../../styling/Home.css";
import Footer from "../../components/Footer";
import Navbar from "../../components/ClientNavbarDashboard";
import attendance from "../../Image/attendance.svg";
import business from "../../Image/business.svg";
import employee from "../../Image/employee.svg";
import error from "../../Image/error.svg";
import performance from "../../Image/performance.svg";
import request from "../../Image/request.svg";
import salaryearning from "../../Image/salary-earning.svg";
import ticket from "../../Image/ticket.svg";
import report from "../../Image/report.svg";
import email from "../../Image/email.svg";
import documentscanner from "../../Image/document-scanner.svg";
import receiptpayment from "../../Image/receipt-payment.svg";
import financial from "../../Image/financial.svg";
import payperclick from "../../Image/pay-per-click.svg";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./styles.css";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Grid,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
function ClientHome() {
  const [TestimonialsList, setTestimonialsList] = useState([]);
  const [AnnouncementData, setAnnouncementData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function FetchDetailsAnnouncements() {
    setIsLoading(true);
    axios
      .get(
        ` https://marstrackbackend.azurewebsites.net/companyReg/getLandingPageInfo`
      )
      .then((res) => {
        setIsLoading(false);
        setAnnouncementData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching :", error);
        setIsLoading(false);
      });
  }

  function FetchDetails() {
    setIsLoading(true);

    axios
      .get(
        ` https://marstrackbackend.azurewebsites.net/companyReg/getTestimonials`
      )
      .then((res) => {
        setIsLoading(false);
        setTestimonialsList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching Gallery List", err);
      });
  }
  useEffect(() => {
    FetchDetails();
    FetchDetailsAnnouncements();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="bg-slate-50">
      <Navbar />
      <div className="end">
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <header className="header__of__main">
            <h1 className="header__of__page"> Welcome to MarsTrack</h1>
          </header>
        </div>
        <span>
          <Profile />
        </span>
      </div>

      <div className="btn_link">
        <Link className="buttons" to={"/ClientProfile"}>
          <div>
            <img src={employee} alt="" />
          </div>
          <p> Employee Profile</p>
        </Link>

        <Link className="buttons" to={"/ClientAttendance"}>
          <div>
            <img src={attendance} alt="" />
          </div>
          <p> Employee Attendance</p>
        </Link>
        <Link className="buttons" to={"/ClientPerformance"}>
          <div>
            <img src={performance} alt="" />
          </div>
          <p> Employee Performance </p>
        </Link>
        <Link className="buttons" to={"/ClientViolations"}>
          <div>
            <img src={error} alt="" />
          </div>
          <p> Employee Violations</p>
        </Link>
        <Link className="buttons" to={"/ClientTrips"}>
          <div>
            <img src={business} alt="" />
          </div>
          <p> Employee Trips</p>
        </Link>
        <Link className="buttons" to={"/LeaveRequest"}>
          <div>
            <img src={request} alt="" />
          </div>
          <p>Leave Request</p>
        </Link>
        <Link className="buttons" to={"/UnderConstruction"}>
          <div>
            <img src={salaryearning} alt="" />
          </div>
          <p>View historical salaries</p>
        </Link>
        <Link className="buttons" to={"/PaySlip"}>
          <div>
            <img src={payperclick} alt="" />
          </div>
          <p>Payslip generation</p>
        </Link>
        <Link className="buttons" to={"/UploadDocument"}>
          <div>
            <img src={documentscanner} alt="" />
          </div>
          <p>Upload documents</p>
        </Link>
        <Link className="buttons" to={"/EmailToEmployer"}>
          <div>
            <img src={email} alt="" />
          </div>
          <p>Email to employer</p>
        </Link>
        <Link className="buttons" to={"/Expensespostingsbyoneself"}>
          <div>
            <img src={receiptpayment} alt="" />
          </div>
          <p> Expenses postings by oneself</p>
        </Link>
        <Link className="buttons" to={"/UnderConstruction"}>
          <div>
            <img src={financial} alt="" />
          </div>
          <p> View statement of expenses and reimbursement</p>
        </Link>
        <Link className="buttons" to={"/UnderConstruction"}>
          <div>
            <img src={ticket} alt="" />
          </div>
          <p> My tickets</p>
        </Link>
        <Link className="buttons" to={"/UnderConstruction"}>
          <div>
            <img src={report} alt="" />
          </div>
          <p> HR Report</p>
        </Link>
      </div>
      <main
        className="lg:flex   items-center justify-between  lg:px-[70px] lg:h-[60vh]  "
        style={{ zIndex: 99 }}
      >
        <section className="lg:flex lg:flex-col gap-[25px] w-full">
          <h1 className="font-bold text-[50px]"></h1>
          {AnnouncementData?.map((item, index) => (
            <div key={index}>
              <div className="py-2 px-4 w-[100%] bg-white rounded">
                {item["COMPANYINFORMATION"] ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item["COMPANYINFORMATION"],
                    }}
                  />
                ) : (
                  "N/A"
                )}
              </div>
            </div>
          ))}
        </section>
      </main>

      <main
        className="lg:flex lg:flex-col-reverse  items-center justify-between  lg:px-[70px] "
        style={{ zIndex: 99 }}
      >
        <div className=" w-full  bg-white rounded px-1">
          <div className=" my-[10px] w-full uppercase">
            <Swiper
              spaceBetween={30}
              centeredSlides={false}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: false,
              }}
              navigation={false}
              modules={[Autoplay, Navigation]}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 1,
                },
              }}
            >
              {TestimonialsList?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="lg:flex gap-5">
                    <section className="w-350px h-[300px] bg-white rounded-lg  flex justify-center items-center pb-5">
                      <img
                        className="w-300px h-[200px] object-fit rounded"
                        src={item?.TESTIMONIALIMAGE}
                        alt="GALLERY"
                      />
                    </section>
                    <div className="lg:flex gap-5 items-center">
                      <h1 className="text-center lg:text-left p-2 text-lg lg:text-3xl font-bold">
                        {item?.TESTIMONIALNARRATION}
                      </h1>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ClientHome;
