import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import ClientNavbar2 from "../../components/ClientNavbarAllPages";
import { BsArrowDownUp } from "react-icons/bs";
import LoadingScreen from "../../components/LoadingScreen";
function ClientViolations() {
  const [datas, setDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [journeySortOrder, setJourneySortOrder] = useState(null);
  const [dateSortOrder, setDateSortOrder] = useState("asc");
  const [uniqueViolationTypes, setUniqueViolationTypes] = useState([]);

  const navigate = useNavigate();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const state = useSelector((state) => state.loginreducer);

  function EmpDetails() {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/getDriverViolation`,
        {
          empCode: state[0].employeeCode,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        setDatas(response.data);
        setIsLoading(false);
        const types = [
          ...new Set(response.data.map((item) => item["VIOLATION TYPE"])),
        ];
        setUniqueViolationTypes(types);
      })
      .catch(() => {
        alert("Internal server error! Please try again later.");
        setIsLoading(false);
        navigate("/");
      });
  }

  useEffect(() => {
    EmpDetails();
  }, []);

  //
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // ... (existing handlers and functions)

  //
  const [selectedViolationType, setSelectedViolationType] = useState("");
  function handleViolationTypeFilter(event) {
    setSelectedViolationType(event.target.value);
    console.log("Selected violation type:", event.target.value);
  }

  function handleJourneySort() {
    const sortedDatas = [...datas].sort((a, b) =>
      journeySortOrder === "asc"
        ? a["JOURNEY NO"] - b["JOURNEY NO"]
        : b["JOURNEY NO"] - a["JOURNEY NO"]
    );
    setDatas(sortedDatas);
    setJourneySortOrder(journeySortOrder === "asc" ? "desc" : "asc");
    setDateSortOrder(null); // Reset dateSortOrder when journey is sorted
  }

  function handleDateSort() {
    const sortedDatas = [...datas].sort((a, b) =>
      dateSortOrder === "asc"
        ? new Date(a["DATE OF MONITORING"]).getTime() -
          new Date(b["DATE OF MONITORING"]).getTime()
        : new Date(b["DATE OF MONITORING"]).getTime() -
          new Date(a["DATE OF MONITORING"]).getTime()
    );
    setDatas(sortedDatas);
    setDateSortOrder(dateSortOrder === "asc" ? "desc" : "asc");
    setJourneySortOrder(null); // Reset journeySortOrder when date is sorted
  }
  const filteredData = datas.filter((item) => {
    const monitoringDate = new Date(item["DATE OF MONITORING"]);
    monitoringDate.setHours(0, 0, 0, 0); // Set time to midnight

    return (
      (selectedViolationType === "" ||
        item["VIOLATION TYPE"] === selectedViolationType) &&
      (startDate === "" || monitoringDate >= new Date(startDate)) &&
      (endDate === "" || monitoringDate <= new Date(endDate))
    );
  });

  console.log("Filtered data:", filteredData);
  //TOTAL VOILATION
  const [totalVoilation, setTotalVoilation] = useState(0);
  useEffect(() => {
    const activeVoilationCount = datas.filter(
      (item) => item["VIOLATION TYPE"] !== null
    ).length;
    setTotalVoilation(activeVoilationCount);
  }, [datas]);
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <ClientNavbar2 />

      <div className="end">
        <div>
          <header className="header__of__main">
            <h1 className="header__of__page"> Employee Violations </h1>
          </header>
        </div>
      </div>
      {/*  */}
      <div className="banner__align">
        <div className="background_color">
          <div className="client__datas">
            <div>
              <label>EMPLOYEE NAME: </label>

              {datas.length > 0 && <p>&nbsp;{datas[0]["DRIVER NAME"]}</p>}
            </div>
            <div>
              <label>EMPLOYEE CODE: </label>
              {datas.length > 0 && <p>&nbsp;{datas[0]["DRIVER CODE"]}</p>}
            </div>
          </div>

          <div className="Dates__filter__client">
            <div>
              <label style={{ display: "inline-block", width: "120px" }}>
                Start Date:{" "}
              </label>
              <input
                style={{ width: "154px" }}
                className="inputs "
                name="startDatePicker"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div>
              <label style={{ display: "inline-block", width: "120px" }}>
                End Date:{" "}
              </label>
              <input
                style={{ width: "154px" }}
                className="inputs "
                name="endDatePicker"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="client__datas">
            <div>
              <label>Violation Type: &nbsp;</label>
              <select
                className="inputs"
                name="violationTypeSelect"
                onChange={handleViolationTypeFilter}
              >
                <option value="">All</option>
                {uniqueViolationTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label>Total Voilation :</label>
              <p>&nbsp; {totalVoilation}</p>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="scroll">
        <div style={{ padding: "20px" }}>
          <table
            style={{ textTransform: "uppercase" }}
            className="employee_data_header new__width"
          >
            <thead className="thead">
              <tr className="employee_tr">
                <th
                  style={{
                    padding: "10px",
                    textAlign: "right",
                    paddingRight: "40px",
                    cursor: "pointer",
                  }}
                  onClick={handleJourneySort}
                >
                  <p className="voilation__jrno">
                    {" "}
                    JOURNEY NO{" "}
                    {journeySortOrder === "asc" ? (
                      <span>&darr;</span>
                    ) : journeySortOrder === "desc" ? (
                      <span>&uarr;</span>
                    ) : (
                      <span>
                        <BsArrowDownUp />
                      </span>
                    )}
                  </p>
                </th>

                <th>
                  {" "}
                  <p className="voilation__table">VIOLATION TYPE</p>
                </th>
                <th
                  onClick={handleDateSort}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <p className="voilation__table">
                    {" "}
                    DATE OF MONITORING{" "}
                    {dateSortOrder === "asc" ? (
                      <span>&darr;</span>
                    ) : dateSortOrder === "desc" ? (
                      <span>&uarr;</span>
                    ) : (
                      <span>
                        <BsArrowDownUp />
                      </span>
                    )}
                  </p>
                </th>

                <th>
                  <p className="voilation__table">DATE OF VIOLATION</p>
                </th>
                <th
                  style={{
                    padding: "10px",

                    width: "300px",
                  }}
                >
                  <p className="voilation__table">NARRATION</p>
                </th>
              </tr>
            </thead>
            <tbody className="employee_tbody">
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      padding: "10px",
                      paddingRight: "40px",
                      width: "130px",
                      textAlign: "right",
                    }}
                  >
                    {item["JOURNEY NO"]}
                  </td>
                  <td>{item["VIOLATION TYPE"]}</td>
                  <td>
                    {moment(item["DATE OF MONITORING"]).format("DD/MM/YYYY")},
                    &nbsp;
                    {moment(item["DATE OF MONITORING"]).format("HH:mm:ss")}
                  </td>
                  <td>
                    {item["DATE OF VIOLATION"] ? (
                      <>
                        {moment(item["DATE OF VIOLATION"]).format("DD/MM/YYYY")}
                        , &nbsp;
                        {moment(item["DATE OF VIOLATION"]).format("HH:mm:ss")}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td
                    style={{
                      padding: "10px",

                      width: "130px",
                    }}
                  >
                    {item["MONITORING NARRATION"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ClientViolations;
