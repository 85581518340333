import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import { Chart } from "chart.js/auto";
import Select from "react-select";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import axios from "axios";
import "../styling/Performance.css";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import Profile from "./Profile";
import Footer from "../components/Footer";
import moment from "moment";
import Navbar2 from "../components/NavbarForAllPagesHR";
Chart.register(CategoryScale);

function Analytics() {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // const axiosJWT = JwtRequestHandler();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [chartVisible, setChartVisible] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [deptDrop, setDeptDrop] = useState([]);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    startDate: moment(firstDayOfMonth).format("YYYY-MM-DD"),
    endDate: moment(lastDayOfMonth).format("YYYY-MM-DD"),
  });

  const initials = {
    empcode: "",
    empname: "",
  };
  const [searchInputs, setSearchInputs] = useState(initials);
  const [selectedDept, setSelectedDept] = useState("");
  const [empMode, setEmpMode] = useState(false);
  const [deptMode, setDeptMode] = useState(false);
  const [dateMode, setDateMode] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");

  function getEmpData() {
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/empdataforatten`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setEmpData(response.data);
        setIsLoading(false);
        axios
          .get(`${BASEURL}/alldepts`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setDeptDrop(response.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else if (axios.AxiosError) {
              navigate("/authfailed");
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });
    return () => {
      source.cancel();
    };
  }
  useEffect(getEmpData, []);

  function handleCodeInput(e) {
    setChartVisible(false);
    const selectedValue = e.value;
    const selectedOption = empData.find((obj) => obj.EMPCODE === selectedValue);
    setSearchInputs({
      ...searchInputs,
      empcode: selectedValue,
      empname: selectedOption.EMPNAME,
    });
  }

  function handleNameInput(e) {
    setChartVisible(false);
    const selectedValue = e.value;
    const selectedOption = empData.find((obj) => obj.EMPNAME === selectedValue);
    setSearchInputs({
      ...searchInputs,
      empcode: selectedOption.EMPCODE,
      empname: selectedValue,
    });
  }

  function getEmpPerformance(e) {
    e.preventDefault();
    if (searchInputs.empcode === "") {
      alert("Please select an employee code or name.");
    } else {
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/selecteddriverviolations`,
          { driver: searchInputs.empname },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setAnalyticsData(response.data);
          setIsLoading(false);
          setChartVisible(true);
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setIsLoading(false);
          navigate("/");
        });
    }
  }

  function getDeptPerformance(e) {
    e.preventDefault();
    if (selectedDept === "") {
      alert("Please select a department.");
    } else {
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/deptWiseAnalytics`,
          { department: selectedDept },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setAnalyticsData(response.data);
          setIsLoading(false);
          setChartVisible(true);
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setIsLoading(false);
          navigate("/");
        });
    }
  }

  function getDateBasedPerformance(e) {
    e.preventDefault();
    if (dateFilter.startDate === "" && dateFilter.endDate === "") {
      alert("Please fill in date values.");
    } else {
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/datebasedanalytics`,
          { dateFilter },
          {
            headers: {
              "auth-token": authToken,
            },
          }
        )
        .then((response) => {
          setAnalyticsData(response.data);
          setIsLoading(false);
          setChartVisible(true);
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setIsLoading(false);
          navigate("/");
        });
    }
  }

  const data = {
    labels: analyticsData.map((item) => item["VIOLATION TYPE"]),
    // labels : [1,2,3],
    datasets: [
      {
        label: "Frequency",
        data: analyticsData.map((item) => item.VIOLATIONS),
        // data: [1,2,3],
        backgroundColor: "rgba(75,192,192,0.6)",
      },
    ],
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Violation Type",
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Frequency",
          font: {
            size: 14,
            weight: "bold",
          },
        },
        grid: {
          display: false,
        },
      },
    },
  };

  // const chartContainerStyle = {
  //   width: "700px",
  //   height: "700px",
  //   margin: "20px auto",
  //   '@media (max-width: 720px)': {
  //     width: "390px",
  //     height: "400px",
  //     margin: "10px auto",

  //   },

  // };

  if (isLoading) {
    return <LoadingScreen />;
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "10px",
      weight: 400,
      fontSize: "13px",
      // width: "240px",
      border: "1.5px solid",
    }),
    menu: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };
  return (
    <div>
      <Navbar2 />
      <div style={{ minHeight: "100vh" }}>
        <div
          className={
            empMode === true || deptMode === true || dateMode === true
              ? "end__analytics"
              : "none__defoult"
          }
        >
          <div>
            <header className="header__of__main">
              <h1 className="header__of__page"> Analytics </h1>
            </header>
          </div>
          <div
            className={
              empMode === true || deptMode === true || dateMode === true
                ? "heading__one__active"
                : "heading__one__analytics"
            }
          >
            <p className="on">
              <h4 className="h4">Analytics Required For : &nbsp;</h4>
            </p>
            <div className="Analytics_btns">
              <div>
                <button
                  className="Analytics_btns_button"
                  onClick={() => {
                    setEmpMode(true);
                    setDateMode(false);
                    setDeptMode(false);
                    setChartVisible(false);
                  }}
                >
                  Employee
                </button>
              </div>
              <div>
                <button
                  className="Analytics_btns_button"
                  onClick={() => {
                    setDeptMode(true);
                    setEmpMode(false);
                    setDateMode(false);
                    setChartVisible(false);
                  }}
                >
                  Department
                </button>
              </div>
              <div>
                <button
                  className="Analytics_btns_button"
                  onClick={() => {
                    setDateMode(true);
                    setEmpMode(false);
                    setDeptMode(false);
                    setChartVisible(false);
                  }}
                >
                  Date Wise
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <div className='performance_of_employee'>
                        <h3>Analytics</h3>
                    </div> */}
          <div></div>
          <div className="for__perfomace__sort__chart">
            {empMode ? (
              <div>
                <div>
                  <h4 className="heading__enter">Enter Details of employee</h4>
                </div>

                <div className="for__perfomace__sort__chart">
                  <table className="analytics__table">
                    <tr>
                      <td className="align__select__td">
                        <p>Employee Code: </p>

                        <div className="align__select__analytics">
                          <Select
                            name="empcode"
                            styles={customStyles}
                            value={
                              searchInputs.empcode
                                ? {
                                    label: searchInputs.empcode,
                                    value: searchInputs.empcode,
                                  }
                                : { label: "Employee Code", value: "" }
                            }
                            options={empData.map((item) => ({
                              label: item.EMPCODE,
                              value: item.EMPCODE,
                            }))}
                            onChange={(e) => {
                              handleCodeInput(e);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align__select__td">
                        <p>Employee Name: </p>

                        <div className="align__select__analytics">
                          <Select
                            name="empname"
                            styles={customStyles}
                            value={
                              searchInputs.empname
                                ? {
                                    label: searchInputs.empname,
                                    value: searchInputs.empname,
                                  }
                                : { label: "Employee Name", value: "" }
                            }
                            options={empData.map((item) => ({
                              label: item.EMPNAME,
                              value: item.EMPNAME,
                            }))}
                            onChange={(e) => {
                              handleNameInput(e);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <button
                          className="get_data"
                          type="button"
                          onClick={(e) => {
                            getEmpPerformance(e);
                          }}
                        >
                          Get Data
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            ) : null}
            {deptMode ? (
              <div>
                <div>
                  <h4 className="heading__enter">
                    Enter Details of Department
                  </h4>
                </div>
                <div className="for__perfomace__sort__chart">
                  <table className="analytics__table">
                    <tr>
                      <td className="align__select__td">
                        <p>Select Department:</p>

                        <div className="align__select__analytics">
                          <Select
                            name="deptdrop"
                            styles={customStyles}
                            value={
                              selectedDept
                                ? { label: selectedDept, value: selectedDept }
                                : { label: "Select", value: "" }
                            }
                            options={deptDrop.map((item) => ({
                              label: item.OM_DEPARTMENT_NAME,
                              value: item.OM_DEPARTMENT_NAME,
                            }))}
                            onChange={(e) => {
                              setChartVisible(false);
                              setSelectedDept(e.value);
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}>
                        <button
                          className="get_data"
                          type="button"
                          onClick={(e) => {
                            getDeptPerformance(e);
                          }}
                        >
                          Get Data
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            ) : null}
            {dateMode ? (
              <div>
                <div>
                  <h4 className="heading__enter">Select Start and End date</h4>
                </div>
                <div className="for__perfomace__sort__chart">
                  <table className="analytics__table">
                    <tr>
                      <td>
                        <label>Start Date:</label>
                      </td>
                      <td>
                        <input
                          className="Graph__date"
                          type="date"
                          defaultValue={dateFilter.startDate}
                          onChange={(e) => {
                            setChartVisible(false);
                            setDateFilter({
                              ...dateFilter,
                              startDate: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>End Date:</label>
                      </td>
                      <td>
                        <input
                          className="Graph__date"
                          type="date"
                          defaultValue={dateFilter.endDate}
                          onChange={(e) => {
                            setChartVisible(false);
                            setDateFilter({
                              ...dateFilter,
                              endDate: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <button
                          className="View"
                          onClick={(e) => {
                            getDateBasedPerformance(e);
                          }}
                        >
                          Get Data
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            ) : null}
            {chartVisible ? (
              <div className="bar_graph">
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      // paddingRight: "190px",
                      paddingTop: "20px",
                    }}
                  >
                    {deptMode ? (
                      <span>Showing data for {selectedDept}</span>
                    ) : null}
                    {empMode ? (
                      <span>Showing data for {searchInputs.empname}</span>
                    ) : null}
                  </div>
                  <div className="scroll">
                    <div
                      className="chartContainerStyle"
                      // style={chartContainerStyle}
                    >
                      <Bar data={data} options={options} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Analytics;
