import React, { useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../styling/RegisterEmployee.css";
import Profile from "./Profile";
import LoadingScreen from "../components/LoadingScreen";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import Footer from "../components/Footer";
import Navbar2 from "../components/NavbarForAllPagesHR";
function Registration() {
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const initialValues = {
    empcode: "",
    empname: "",
    actno: "",
    // Joindate: "",
    NatId: "",
    email: "",
    telephno: "",
    overtime: 0,
    designation: "",
    dept: "",
    vehicleallocated: "",
    casual: 1, // Default to Yes (1)
    subcontract: 0, // Default to No (0)
    imagename: "",
    confirm: 1,
    portpass: 0,
    passno: "",
    // expdate: "",
    notes: "",
  };

  const [formInput, setFormInput] = useState(initialValues);
  const [joinDate, setJoinDate] = useState("");
  const [portPassExpDate, setPortPassExpDate] = useState("");
  const [section1, setSection1] = useState(true);
  const [desigVal, setDesigVal] = useState([]);
  const [deptVal, setDeptVal] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [dispPicture, setDispPicture] = useState("");
  const [picture, setPicture] = useState(null);
  const [cameraOn, setCameraOn] = useState(false);
  const [captured, setCaptured] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fileUpload, setFileUpload] = useState(null);
  const [filePreview, setFilePreview] = useState(false);
  const [hide, sethide] = useState(true);

  const handleInputItem = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    console.log(formInput);
  };

  function tofixnumber(e) {
    const input = e.target;
    const telephone = input.value.replace(/\D/g, "");

    if (telephone.length > 15) {
      const givenvalue = telephone.replace(/(\d{15})/);
      input.value = givenvalue;
    }
  }
  function getDropDowns() {
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/alldepts`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setDeptVal(response.data);
        axios
          .get(`${BASEURL}/alldesignations`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setDesigVal(response.data);
            axios
              .get(`${BASEURL}/vehicleallocated`, {
                headers: {
                  "auth-token": authToken,
                },
              })
              .then((response) => {
                setVehicles(response.data);
                setIsLoading(false);
              })
              .catch((err) => {
                if (axios.isCancel(err)) {
                } else if (axios.AxiosError) {
                  navigate("/authfailed");
                }
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else if (axios.AxiosError) {
              navigate("/authfailed");
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });

    return () => {
      source.cancel();
    };
  }
  useEffect(getDropDowns, []);

  //camera
  // const openCamera = () => {
  //   setSection1(false);
  //   setCameraOn(true);

  //   // Check for getUserMedia support
  //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  //     try {
  //       navigator.mediaDevices
  //         .getUserMedia({ video: true })
  //         .then((stream) => {
  //           videoRef.current.srcObject = stream;
  //           videoRef.current.play();
  //         })
  //         .catch((error) => {
  //           console.log("Error accessing camera", error);
  //           // Handle the error or show a message to the user
  //         });
  //     } catch (error) {
  //       console.log("Error accessing camera", error);
  //       // Handle the error or show a message to the user
  //     }
  //   } else {
  //     console.log("getUserMedia is not supported in this browser");
  //     // Handle the unsupported scenario or show a message to the user
  //   }
  // };
  const openCamera = async () => {
    setSection1(false);
    setCameraOn(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      // Handle the error or show a message to the user
    }
  };

  const capturePicture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !video.srcObject) {
      return;
    }

    // Rest of the code for capturing the picture
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    const pictureData = canvas.toDataURL();
    canvas.toBlob((blob) => {
      setPicture(blob);
    });
    setDispPicture(pictureData);

    // Stop video tracks
    const videoTracks = video.srcObject.getTracks();
    videoTracks.forEach((track) => track.stop());

    setCaptured(true);
    setCameraOn(false);
  };

  const retakePicture = () => {
    setPicture(null);
    setDispPicture("");
    setCaptured(false);
    openCamera();
  };
  function handlePictureUpload(e) {
    const file = e.target.files[0];
    setPicture(file);
    const imageURL = URL.createObjectURL(file);
    setDispPicture(imageURL);
  }
  function handleRegister(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    console.log(formData);
    formData.append("data", JSON.stringify(formInput));
    formData.append("joindate", joinDate);
    formData.append("portexpdate", portPassExpDate);
    formData.append("image", picture);
    axios
      .post(`${BASEURL}/registeremp`, formData, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        if (response.data.message === "niet") {
          alert("Employee code already exists!");
          setIsLoading(false);
        } else {
          alert(response.data.message);
          setIsLoading(false);
          navigate("/");
        }
      });
  }

  const renderFilePreview = () => {
    if (fileUpload) {
      const fileType = fileUpload.type.split("/")[1];

      if (fileType === "pdf") {
        return (
          <embed
            src={URL.createObjectURL(fileUpload)}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        );
      } else if (
        ["jpg", "jpeg", "png", "heic", "heif", "webp"].includes(fileType)
      ) {
        return <img src={URL.createObjectURL(fileUpload)} />;
      }

      return <p>Preview not available for this file type.</p>;
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <Navbar2 />

      {cameraOn ? (
        <div className="camera__align">
          <video ref={videoRef} />

          <button
            className="Capture"
            onClick={() => {
              capturePicture();
            }}
          >
            Capture
          </button>
          <canvas ref={canvasRef} />
        </div>
      ) : null}
      {captured ? (
        <div>
          <div>
            <img className="Image" src={dispPicture} />
          </div>

          <div className="camera_btn2">
            <button
              className="retake"
              onClick={() => {
                retakePicture();
              }}
            >
              Retake
            </button>
            <button
              className="Proceed"
              onClick={() => {
                setCameraOn(false);
                setCaptured(false);
                setSection1(true);
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      ) : // <div className="camera__align ">
      //   <div>
      //     <img className="Image" src={dispPicture} />
      //   </div>

      //   <div className="camera_btn2">
      //     <button
      //       className="Capture"
      //       onClick={() => {
      //         retakePicture();
      //       }}
      //     >
      //       Retake
      //     </button>
      //     <button
      //       className="Capture"
      //       onClick={() => {
      //         setCameraOn(false);
      //         setCaptured(false);
      //         setSection1(true);
      //       }}
      //     >
      //       Proceed
      //     </button>
      //   </div>
      // </div>
      null}

      {section1 ? (
        <form
          className="form"
          onSubmit={(e) => {
            const confirmBox = window.confirm(
              "Are you sure all the details are correct?"
            );
            if (confirmBox === true) {
              handleRegister(e);
            } else {
              e.preventDefault();
            }
          }}
        >
          <div className="registration-form-container">
            {/* <h2 className="h2">Employee Registration</h2> */}
            <header className="header__of__main">
              <h1 className="header__of__page"> Employee Registration</h1>
            </header>
            <div className="row">
              <div className="small-12 medium-2 large-2 columns">
                <div className="circle">
                  <div className="center__no__data">
                    <div>
                      <img
                        style={{ fontFamily: "Cursive" }}
                        src={dispPicture}
                        alt="Upload image here"
                        className={dispPicture ? "img" : "profile_pic"}
                      />
                    </div>
                  </div>
                </div>
                <div className="camera_upload">
                  <div className="camera__icon">
                    <button
                      className="camera_btn"
                      onClick={() => {
                        const confirmBox = window.confirm(
                          "Allow access to camera?"
                        );
                        if (confirmBox === true) {
                          setSection1(false);
                          setCameraOn(true);
                          openCamera();
                        } else {
                          return null;
                        }
                      }}
                    >
                      {" "}
                      <span>
                        <FaCamera />
                      </span>
                    </button>
                  </div>
                  <div>
                    <input
                      style={{ padding: "5px", display: "none" }}
                      id="file"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        handlePictureUpload(e);
                      }}
                    />
                    <div className="upload_file">
                      <label className="View" htmlFor="file">
                        Upload
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Detials">
              <div>
                <label className="label">EMPLOYEE CODE</label>
                <input
                  required
                  type="text"
                  name="empcode"
                  defaultValue={formInput.empcode}
                  onChange={(e) => handleInputItem(e)}
                />
              </div>
              <div>
                <label className="label">EMPLOYEE NAME</label>
                <input
                  required
                  type="text"
                  name="empname"
                  defaultValue={formInput.empname}
                  onChange={(e) => handleInputItem(e)}
                />
              </div>
              <div>
                <label className="label">ACCOUNT NO</label>
                <input
                  required
                  type="text"
                  name="actno"
                  defaultValue={formInput.actno}
                  onChange={(e) => handleInputItem(e)}
                />
              </div>
              <div>
                <label className="label">JOIN DATE</label>
                <input
                  required
                  type="date"
                  name="Joindate"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setJoinDate(moment(e.target.value).format("DD/MM/YYYY"));
                  }}
                />
              </div>
              <div>
                <label className="label">NATIONAL ID</label>
                <input
                  required
                  type="text"
                  name="NatId"
                  defaultValue={formInput.NatId}
                  onChange={(e) => handleInputItem(e)}
                />
              </div>
              <div>
                <label className="label">TELEPHONE</label>
                <input
                  required
                  type="number"
                  name="telephno"
                  defaultValue={formInput.telephno}
                  onChange={(e) => handleInputItem(e)}
                  onInput={tofixnumber}
                />
              </div>
              <div>
                <label className="label">EMAIL</label>
                <input
                  required
                  type="email"
                  name="email"
                  defaultValue={formInput.email}
                  onChange={(e) => handleInputItem(e)}
                />
              </div>
            </div>

            <div className="Info">
              <div>
                <label className="label">DEPARTMENT</label>
                <select
                  className="input_field"
                  required
                  name="dept"
                  onChange={(e) => handleInputItem(e)}
                >
                  <option value={""}>Select</option>
                  {deptVal.map((data) => (
                    <option value={data.OM_DEPARTMENT_NAME}>
                      {data.OM_DEPARTMENT_NAME}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="label">DESIGNATION</label>
                <select
                  // style={{ width: "164px" }}
                  className="input_field"
                  required
                  name="designation"
                  value={formInput.designation}
                  onChange={(e) => handleInputItem(e)}
                >
                  <option value={""}>Select</option>
                  {desigVal.map((data) => (
                    <option value={data.OM_DESIGNATION_NAME}>
                      {data.OM_DESIGNATION_NAME}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="label">VEHICLE ALLOCATED </label>
                <select
                  className="input_field"
                  name="vehicleallocated"
                  onChange={(e) => handleInputItem(e)}
                >
                  <option value={""}>Select</option>
                  {vehicles.map((vehi) => (
                    <option value={vehi["VEHICLE REG NO"]}>
                      {vehi["VEHICLE REG NO"]}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="label">OVERTIME</label>
                <div className="radio_btn">
                  <div>
                    <input
                      className="radio"
                      required
                      type="radio"
                      name="overtime"
                      onChange={(e) => handleInputItem(e)}
                      value={Number(1)}
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      className="radio"
                      required
                      type="radio"
                      name="overtime"
                      defaultChecked={true}
                      onChange={(e) => handleInputItem(e)}
                      value={Number(0)}
                    />
                    No
                  </div>
                </div>
              </div>
              <div>
                <label className="label">CASUAL</label>
                <div className="radio_btn">
                  <div>
                    <input
                      className="radio"
                      required
                      type="radio"
                      name="casual"
                      onChange={(e) => {
                        handleInputItem(e);
                        if (e.target.value === "0") {
                          // If Casual is No, set Subcontracted to Yes
                          setFormInput({
                            ...formInput,
                            casual: 0,
                            subcontract: 1,
                          });
                        } else {
                          // If Casual is Yes, set Subcontracted to No
                          setFormInput({
                            ...formInput,
                            casual: 1,
                            subcontract: 0,
                          });
                        }
                      }}
                      value={1}
                      checked={formInput.casual === 1}
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      className="radio"
                      required
                      type="radio"
                      name="casual"
                      onChange={(e) => {
                        handleInputItem(e);
                        if (e.target.value === "1") {
                          // If Casual is Yes, set Subcontracted to No
                          setFormInput({
                            ...formInput,
                            casual: 1,
                            subcontract: 0,
                          });
                        } else {
                          // If Casual is No, set Subcontracted to Yes
                          setFormInput({
                            ...formInput,
                            casual: 0,
                            subcontract: 1,
                          });
                        }
                      }}
                      value={0}
                      checked={formInput.casual === 0}
                    />
                    No
                  </div>
                </div>
              </div>
              <div>
                <label className="label">SUBCONTRACTED</label>
                <div className="radio_btn">
                  <div>
                    <input
                      required
                      className="radio"
                      type="radio"
                      name="subcontract"
                      onChange={(e) => {
                        handleInputItem(e);
                        if (e.target.value === "0") {
                          // If Subcontracted is No, set Casual to Yes
                          setFormInput({
                            ...formInput,
                            subcontract: 0,
                            casual: 1,
                          });
                        } else {
                          // If Subcontracted is Yes, set Casual to No
                          setFormInput({
                            ...formInput,
                            subcontract: 1,
                            casual: 0,
                          });
                        }
                      }}
                      value={1}
                      checked={formInput.subcontract === 1}
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      required
                      className="radio"
                      type="radio"
                      name="subcontract"
                      onChange={(e) => {
                        handleInputItem(e);
                        if (e.target.value === "1") {
                          // If Subcontracted is Yes, set Casual to No
                          setFormInput({
                            ...formInput,
                            subcontract: 1,
                            casual: 0,
                          });
                        } else {
                          // If Subcontracted is No, set Casual to Yes
                          setFormInput({
                            ...formInput,
                            subcontract: 0,
                            casual: 1,
                          });
                        }
                      }}
                      value={0}
                      checked={formInput.subcontract === 0}
                    />
                    No
                  </div>
                </div>
              </div>

              <div className="portDetails">
                <label className="label">PORT PASS</label>
                <div className="radio_btn">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      value={Number(1)}
                      name="portpass"
                      onChange={(e) => {
                        handleInputItem(e);
                        sethide(false);
                      }}
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      defaultChecked={true}
                      value={Number(0)}
                      name="portpass"
                      onChange={(e) => {
                        handleInputItem(e);
                        sethide(true);
                      }}
                    />
                    No
                  </div>
                </div>
              </div>
              <div className="Pass_port_no">
                <label className="label">PORT PASS NUMBER</label>
                <input
                  className="input_field"
                  type="text"
                  disabled={hide}
                  name="passno"
                  defaultValue={formInput.passno}
                  onChange={(e) => {
                    handleInputItem(e);
                  }}
                />
              </div>
              <div className="Pass_port_no">
                <label className="label">PORT PASS EXPIRY DATE</label>
                <input
                  className="input_field"
                  type="date"
                  disabled={hide}
                  name="expdate"
                  onChange={(e) => {
                    setPortPassExpDate(
                      moment(e.target.value).format("DD/MM/YYYY")
                    );
                  }}
                />
              </div>
              <div className="textarea_notes">
                <label className="label"> Notes</label>
                <textarea
                  required
                  className="Note"
                  type="text"
                  name="notes"
                  defaultValue={formInput.notes}
                  onChange={(e) => handleInputItem(e)}
                />
              </div>
              <div className="upload_file_for_register">
                <label>Upload file:</label>
                <input
                  style={{ width: "250px", backgroundColor: "#ecf1f2" }}
                  id="file"
                  type="file"
                  accept=".png, .jpg, .jpeg, .docx, .doc, .pdf, .xsl, .txt, .ppt, .pptx"
                  onChange={(e) => {
                    setFileUpload(e.target.files[0]);
                  }}
                ></input>
              </div>
              <span className="">
                <button
                  className="Preview"
                  type="button"
                  padding="10px 20px"
                  backgroundColor="#002d62"
                  color="white"
                  onClick={() => {
                    if (fileUpload !== null) {
                      setFilePreview(!filePreview);
                    } else {
                      alert("Please select a file first.");
                    }
                  }}
                >
                  {filePreview ? "Close" : "Preview"}
                </button>
              </span>

              {filePreview ? <div>{renderFilePreview()}</div> : null}
            </div>
            <div className="nxt">
              <button
                className="View new_view"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reset
              </button>

              <button className="View new_view" type="submit">
                Register
              </button>
            </div>
          </div>
        </form>
      ) : null}

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Registration;
