import React, { useState, useEffect, useRef } from "react";
import "../styling/Profile.css";
import { useDispatch, useSelector } from "react-redux";
import { empDetails, logIn, loginState } from "../redux/actions/actions";
import { useNavigate, Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import axios from "axios";

const Profile = () => {
  const dispatch = useDispatch();
  const [logoutVisible, setLogoutVisible] = useState(false);
  const ulRef = useRef(null);
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  const user = useSelector((state) => state.loginreducer);
  const BASEURL = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setLogoutVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const ProfileIcon = ({ onClick }) => (
    <div className="profile-icon" onClick={onClick}>
      {/* <img src="profile-icon.png" alt="Profile Icon" /> */}
      <CgProfile />
    </div>
  );

  const LogoutOption = () => (
    <span className="logout-option">
      <ul ref={ulRef}>
        <li>
          <Link style={{ textDecoration: "none" }} to="/home">
            Home
          </Link>
        </li>
        {/* <li>Logged in as : { }</li> */}
        <button
          className="logout_btn"
          onClick={() => {
            axios
              .post(
                `${BASEURL}/logout`,
                { token: user[0].refreshToken },
                {
                  headers: {
                    "auth-token": authToken,
                  },
                }
              )
              .then((response) => {
                alert(response.data.message);
              })
              .catch((err) => {
                if (axios.AxiosError) {

                }
              });
            dispatch(logIn([]));
            dispatch(loginState(false));
          }}
        >
          Logout
        </button>
      </ul>
    </span>
  );

  const toggleLogout = () => {
    setLogoutVisible(!logoutVisible);
  };

  const handleProfileClick = (event) => {
    event.stopPropagation(); // Prevent the event from propagating to the document click event listener
    toggleLogout();
  };

  return (
    <span>
      <ProfileIcon onClick={handleProfileClick} />
      {logoutVisible && <LogoutOption />}
    </span>
  );
};

export default Profile;
