import React, { useState, useEffect } from "react";
import Select from "react-select";
import ClientNavbar2 from "../../components/ClientNavbarAllPages";
function UploadDocument() {
  const [DocumentType, setDocumentType] = useState("");
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  return (
    <div>
      <ClientNavbar2 />
      <div className="end">
        <header className="header__of__main">
          <h1 className="header__of__page"> Upload Document</h1>
        </header>
      </div>
      <div className="banner__align mt-4">
        <div className="background_color">
          <div className="client__datas lg:flex">
            <div>
              <label>DOCUMENT TYPE: </label>
              <Select
                className="ml-2"
                styles={customStyless}
                onChange={(e) => {
                  if (e.value === "") {
                    alert("Kindly choose proper job status");
                  } else {
                    setDocumentType(e.value);
                  }
                }}
                // value={
                //   jobstatus
                //     ? {
                //         label: jobstatus,
                //         value: jobstatus,
                //       }
                //     : { label: "", value: "" }
                // }
                options={[
                  { value: "", label: "" },
                  //
                ]}
              />
            </div>
            <div>
              <label>UPLOAD FILE: </label>

              <input type="file" className="lg:ml-2 w-[80%] lg:w-auto"></input>
            </div>
            <p className="flex items-center">
              {" "}
              <button className="bg-blue-800 p-1 ml-2 text-white rounded">
                New
              </button>
              <button className="bg-blue-800 p-1 ml-2 text-white rounded">
                Submit
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadDocument;
