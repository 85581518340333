import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import Select from "react-select";
import LoadingScreen from "../components/LoadingScreen";
import "../styling/RecordAttendance.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar3 from "../components/Navbar3";
function RecordAttendance() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const user = useSelector((state) => state.loginreducer);
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  const initialValues = {
    empCode: "",
    empName: "",
    onleave: 0,
    narration: "",
    processedby: user[0].email,
  };
  const [attendDetails, setAttendDetails] = useState(initialValues);
  const [presetData, setPresetData] = useState([]);
  const [dataForClockout, setDataForClockout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setchecked] = useState(true);
  const [clockOutBool, setClockOutBool] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState([]);

  function getPresets() {
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/empdataforatten`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setPresetData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });

    return () => {
      source.cancel();
    };
  }
  useEffect(getPresets, []);

  const handleCodeInput = (e) => {
    const selectedValue = e.value;
    const selectedOption = presetData.find(
      (obj) => obj.EMPCODE === selectedValue
    );
    setAttendDetails({
      ...attendDetails,
      empCode: selectedValue,
      empName: selectedOption.EMPNAME,
    });
  };

  const handleNameInput = (e) => {
    const selectedValue = e.value;
    const selectedOption = presetData.find(
      (obj) => obj.EMPNAME === selectedValue
    );
    setAttendDetails({
      ...attendDetails,
      empCode: selectedOption.EMPCODE,
      empName: selectedValue,
    });
  };

  const handleAttendance = (e) => {
    const { name, value } = e.target;
    setAttendDetails({ ...attendDetails, [name]: value });
  };

  const isNarrationEmpty = attendDetails.narration.trim() === "";
  function clockIn(e) {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/empattenclockin`,
        { data: attendDetails },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        alert(response.data.message);
        setIsLoading(false);
      });
  }

  function getClockOutData() {
    if (checked === true) {
      setIsLoading(true);
      setClockOutBool(true);
      axios
        .get(`${BASEURL}/dataForClockOut`, {
          headers: {
            "auth-token": authToken,
          },
        })
        .then((response) => {
          setDataForClockout(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (axios.AxiosError) {
            alert("Server Error.");
            setClockOutBool(false);
            setIsLoading(false);
          }
        });
    } else {
      alert("You can't Clock Out you have selected 'On Leave' ");
    }
  }

  const handleCheckBoxInput = (value) => {
    const exists = selectedEmp.includes(value);

    if (exists) {
      setSelectedEmp((prev) => prev.filter((empCode) => empCode !== value));
    } else {
      setSelectedEmp([...selectedEmp, value]);
    }
  };

  const handleTableRowClick = (empCode, exited) => {
    if (!exited) {
      const updatedSelectedEmp = selectedEmp.includes(empCode)
        ? selectedEmp.filter((code) => code !== empCode)
        : [...selectedEmp, empCode];
      setSelectedEmp(updatedSelectedEmp);
    }
  };

  async function clockOut() {
    if (selectedEmp.length === 0) {
      alert("Please select an employee.");
      return;
    }

    setIsLoading(true);
    const clockOutRequests = selectedEmp.map((empCode) => {
      return axios.post(
        `${BASEURL}/empattenclockout`,
        { data: { empCode } }, // Send empCode as an object
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
    });

    try {
      await Promise.all(clockOutRequests);
      alert("Clocked Out Successfully");
      window.location.reload();
    } catch (error) {
      alert("An Error Occurred. Please retry.");
    } finally {
      setIsLoading(false);
    }
  }

  function onLeave(e) {
    setIsLoading(true);
    axios
      .post(
        `${BASEURL}/empattenonleave`,
        { data: attendDetails },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      )
      .then((response) => {
        alert(response.data.message);
        setIsLoading(false);
      });
  }
  if (isLoading) {
    return <LoadingScreen />;
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderBottom: "1px solid",
      height: "10px",
      weight: 400,
      fontSize: "13px",
      width: "350px",
      border: "1.5px solid",
      "@media (max-width: 600px)": {
        // display: 'none',
        width: "230px",
      },
    }),
    option: (provided) => ({
      ...provided,
      width: "100%", // Set the width of the options here
      whiteSpace: "nowrap", // Prevent line breaks inside the option
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Add ellipsis (...) to indicate text overflow
    }),
    menu: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };
  return (
    <div>
      <Navbar3 />
      <div className="empdetials">
        <div className="attendance_heading">
          <header className="header__of__main">
            <h1 className="header__of__page"> Record Attendance</h1>
          </header>
        </div>
        {clockOutBool ? null : (
          <form className="attendance_record">
            <div className="attendance_btn">
              <div className="media_query_btn">
                <button
                  className="buttons"
                  type="button"
                  onClick={(e) => {
                    if (checked === true) {
                      if (attendDetails.empCode === "") {
                        alert("Please enter employee code or name.");
                      } else {
                        const confirmBox = window.confirm(
                          "Are you sure you want to clock in?"
                        );
                        if (confirmBox === true) {
                          clockIn(e);
                        } else {
                          e.preventDefault();
                        }
                      }
                    } else {
                      alert("You can't Clock in you have selected 'On Leave'");
                    }
                  }}
                >
                  CLOCK IN MORNING
                </button>
              </div>
              <div className="media_query_btn">
                <button
                  className="buttons"
                  style={{ height: "50px" }}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    getClockOutData();
                  }}
                >
                  CLOCK OUT FOR THE DAY
                </button>
              </div>
            </div>
            <div className="attendance_inputs">
              <div>
                <label className="empcode">Employee Code</label>
                <Select
                  className="empcode_select"
                  styles={customStyles}
                  required
                  name="empCode"
                  value={
                    attendDetails.empCode
                      ? {
                          label: attendDetails.empCode,
                          value: attendDetails.empCode,
                        }
                      : { label: "Employee Code", value: "" }
                  }
                  onChange={(e) => {
                    handleCodeInput(e);
                  }}
                  options={presetData.map((item) => ({
                    label: item.EMPCODE,
                    value: item.EMPCODE,
                  }))}
                />
              </div>
              <div>
                <label>Employee Name</label>
                <Select
                  styles={customStyles}
                  className="empcode_select"
                  name="empName"
                  value={
                    attendDetails.empName
                      ? {
                          label: attendDetails.empName,
                          value: attendDetails.empName,
                        }
                      : { label: "Employee Name", value: "" }
                  }
                  options={presetData.map((item) => ({
                    label: item.EMPNAME,
                    value: item.EMPNAME,
                  }))}
                  onChange={(e) => {
                    handleNameInput(e);
                  }}
                />
              </div>
              <div className="radio__align">
                <label className="onleave">On Leave</label>
                <input
                  type="radio"
                  name="onleave"
                  value={Number(1)}
                  onChange={(e) => {
                    setchecked(false);
                  }}
                />
                Yes
                <input
                  type="radio"
                  name="onleave"
                  value={Number(0)}
                  defaultChecked
                  onChange={(e) => {
                    setchecked(true);
                  }}
                />
                No
              </div>
              <div>
                <label>Leave Narration</label>
                <textarea
                  className="textarea"
                  type="text"
                  name="narration"
                  disabled={checked}
                  onChange={(e) => handleAttendance(e)}
                ></textarea>
              </div>

              <div className="for_attendance_submit">
                <button
                  className="Various_search_btn"
                  onClick={(e) => {
                    if (isNarrationEmpty) {
                      alert(
                        "Please write something in the narration before submitting leave."
                      );
                      return;
                    }
                    const confirmBox = window.confirm(
                      "Are you sure you want to submit leave?"
                    );
                    if (confirmBox === true) {
                      onLeave(e);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  disabled={checked}
                  type="button"
                >
                  Submit Leave
                </button>
              </div>
            </div>
          </form>
        )}
        {clockOutBool ? (
          <div>
            <p className="clocked_out">
              <button
                onClick={() => {
                  setClockOutBool(false);
                }}
                className="back__clock__out"
              >
                Back
              </button>
              <h4 className="clock__out__heading">
                Please select employee's that you want to clock out:
              </h4>
            </p>
            <div className="scroll">
              <div>
                <table
                  style={{ textTransform: "uppercase" }}
                  className="employee_data_header"
                >
                  <thead className="thead">
                    <tr className="employee_tr">
                      <th style={{ width: "80px ", paddingLeft: "10px" }}>
                        Select
                      </th>
                      <th>Date</th>
                      <th style={{ width: "190px ", paddingLeft: "10px" }}>
                        Employee Name
                      </th>
                      <th>Clock In Time</th>
                      <th>Clock Out Time</th>
                      <th style={{ width: "89px" }}>Exited</th>
                    </tr>
                  </thead>
                  <tbody className="employee_tbody">
                    {dataForClockout.map((item) => (
                      <tr
                        key={item.EMPNAME}
                        className="employee_tbody_tr"
                        onClick={() => {
                          handleTableRowClick(item.EMPCODE, item.EXITED);
                        }}
                      >
                        <td style={{ padding: "10px 10px 10px 21px" }}>
                          <input
                            type="checkbox"
                            disabled={item.EXITED}
                            checked={selectedEmp.includes(item.EMPCODE)}
                          />
                        </td>
                        <td> {moment(item.INTIME).format("DD/MM/YYYY")}</td>

                        <td style={{ paddingLeft: "10px" }}>
                          {item.EMPLOYEENAME}
                        </td>
                        <td>{item.INTIME.split("T")[1].slice(0, -5)}</td>
                        <td>
                          {item.OUTIME
                            ? item.OUTIME.split("T")[1].slice(0, -5)
                            : ""}
                        </td>
                        <td>{item.EXITED ? "Yes" : "No"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="clouk_out_btn">
                  <button
                    className="View"
                    onClick={(e) => {
                      e.preventDefault();
                      const confirmBox = window.confirm(
                        "Are you sure you want to submit?"
                      );
                      if (confirmBox === true) {
                        clockOut();
                      }
                    }}
                  >
                    Clock Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default RecordAttendance;
