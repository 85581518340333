import React from "react";
import "../styling/Footer.css";
const Footer = () => {
    const d = new Date();
    const year = d.getFullYear();
    return (
        <div className="footer">
            <div>
                <hr></hr>
                <div className="parent_div">
                    <div className="child_div1">
                        <h1 className="brand"> TeCH23 </h1>
                        <p className="details"> Email: Support@tech23.net</p>
                        <p className="details"> Contact: +254 798074411</p>
                    </div>
                    <div className="child_div2">
                        <h3 className="use"> Terms of Use</h3>
                        <p className="para">
                            This Software Program is protected by the copyright law and international treaties. Unauthorized reproduction or redistribution of this program or any portion of it may result in severe civil and penalties, and wil be prosecuted to maximum extent possible under the law.
                        </p>
                    </div>
                </div>
                <div className="copyright">
                    <p>Copyright © {year} TeCH23 : All Rights Reserved</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
