import React, { useEffect } from "react";
import Select from "react-select";
import { useState } from "react";
// import JwtRequestHandler from "../utils/JwtRequestHandler";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import "../styling/NewViolation.css";
import Profile from "./Profile";
import Footer from "../components/Footer";
import Navbar3 from "../components/Navbar3";

function NewViolation() {
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  const user = useSelector((state) => state.loginreducer);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [empData, setEmpData] = useState([]);
  const [journeyData, setJourneyData] = useState([]);
  const [violationDrop, setViolationDrop] = useState([]);

  const initialValues = {
    empcode: "",
    empname: "",
    journeyno: "",
    violation: "",
    violationDate: "",
    narration: "",
    processedby: user[0].email,
  };
  const [violationInputs, setViolationInputs] = useState(initialValues);

  function handleInputs(e) {
    const { name, value } = e.target;
    setViolationInputs({ ...violationInputs, [name]: value });
  }

  function handleEmpCode(e) {
    const selectedValue = e.value;
    const selectedOption = empData.find((obj) => obj.EMPCODE === selectedValue);
    setViolationInputs({
      ...violationInputs,
      empcode: selectedValue,
      empname: selectedOption.EMPNAME,
    });
  }

  function handleEmpName(e) {
    const selectedValue = e.value;
    const selectedOption = empData.find((obj) => obj.EMPNAME === selectedValue);
    setViolationInputs({
      ...violationInputs,
      empcode: selectedOption.EMPCODE,
      empname: selectedValue,
    });
  }

  function getData() {
    const source = axios.CancelToken.source();
    axios
      .get(`${BASEURL}/empdataforatten`, {
        headers: {
          "auth-token": authToken,
        },
      })
      .then((response) => {
        setEmpData(response.data);
        axios
          .get(`${BASEURL}/generalViolations`, {
            headers: {
              "auth-token": authToken,
            },
          })
          .then((response) => {
            setViolationDrop(response.data);
            axios
              .get(`${BASEURL}/journeyNumbers`, {
                headers: {
                  "auth-token": authToken,
                },
              })
              .then((response) => {
                setJourneyData(response.data);
                setIsLoading(false);
              })
              .catch((err) => {
                if (axios.isCancel(err)) {
                } else if (axios.AxiosError) {
                  navigate("/authfailed");
                }
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else if (axios.AxiosError) {
              navigate("/authfailed");
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else if (axios.AxiosError) {
          navigate("/authfailed");
        }
      });

    return () => {
      source.cancel();
    };
  }
  useEffect(getData, []);
  //
  function submitData(e) {
    e.preventDefault();
    if ((violationInputs.empcode === "") | (violationInputs.empname === "")) {
      alert("Please Fill in employee details.");
    } else {
      const confirmBox = window.confirm("Are you sure you want to save?");
      if (confirmBox === true) {
        setIsLoading(true);
        axios
          .post(
            `${BASEURL}/insertnewviolation`,
            { data: violationInputs },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          )
          .then((response) => {
            setIsLoading(false);
            alert(response.data.message);
          })
          .catch((err) => {
            alert(err.response.data.message);
            setIsLoading(false);
          });
      }
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }
  // const customStyle = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: "none",
  //     borderBottom: "1px solid",
  //     height: "10px",
  //     weight: 400,
  //     fontSize: "13px",
  //     width: "fitContent",
  //   }),
  //   menu: (provided, state) => ({
  //     ...provided,
  //     textAlign: "left",
  //     width: "200px",
  //   }),
  // };
  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      height: "10px",
      weight: 400,
      fontSize: "13px",
      border: "1.8px solid",
      // width: "240px"
      "@media (max-width: 600px)": {
        // display: 'none',
        width: "200px",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      textAlign: "left",
    }),
  };
  return (
    <div>
      <Navbar3 />
      <div className="end">
        <header className="header__of__main">
          <h1 className="header__of__page"> Violations </h1>
        </header>
        <span>
          <Profile />
        </span>
      </div>
      <div className="New_Violation">
        {/* <h2>New Violation</h2> */}
        <div className="new_violation_form">
          <form
            className="Violation_form"
            onSubmit={(e) => {
              submitData(e);
            }}
          >
            <div>
              <label>Employee Code :</label>
              <Select
                className="width__select__voilation"
                required
                name="empcode"
                styles={customStyle}
                value={
                  violationInputs.empcode
                    ? {
                        label: violationInputs.empcode,
                        value: violationInputs.empcode,
                      }
                    : { label: "Select Employee Code", value: "" }
                }
                onChange={(e) => {
                  handleEmpCode(e);
                }}
                options={empData.map((item) => ({
                  label: item.EMPCODE,
                  value: item.EMPCODE,
                }))}
              />
            </div>
            <div>
              <label>Employee Name :</label>
              <Select
                className="width__select__voilation"
                required
                name="empname"
                styles={customStyle}
                value={
                  violationInputs.empname
                    ? {
                        label: violationInputs.empname,
                        value: violationInputs.empname,
                      }
                    : { label: "Select Employee Name", value: "" }
                }
                onChange={(e) => {
                  handleEmpName(e);
                }}
                options={empData.map((item) => ({
                  label: item.EMPNAME,
                  value: item.EMPNAME,
                }))}
              />
            </div>
            <div>
              <label>Journey No :</label>
              <Select
                className="width__select__voilation"
                name="journeyno"
                styles={customStyle}
                placeholder="Select Journey Number"
                onChange={(e) => {
                  setViolationInputs({
                    ...violationInputs,
                    journeyno: e.value,
                  });
                }}
                options={journeyData.map((item) => ({
                  label: item.JOURNEYNO,
                  value: item.JOURNEYNO,
                }))}
              />
            </div>
            <div>
              <label>Violation Type :</label>
              <Select
                className="width__select__voilation"
                required
                placeholder="Select Violation Type"
                styles={customStyle}
                name="violation"
                onChange={(e) => {
                  setViolationInputs({
                    ...violationInputs,
                    violation: e.value,
                  });
                }}
                options={violationDrop.map((item) => ({
                  label: item.OM_VIOLATION_NAME,
                  value: item.OM_VIOLATION_NAME,
                }))}
              />
            </div>
            <div>
              <label>Violation Date :</label>
              <input
                className="new_Violation_date"
                required
                type="datetime-local"
                name="violationDate"
                onChange={(e) => {
                  handleInputs(e);
                }}
              />
            </div>
            <div>
              <label>Narration :</label>
              <textarea
                className="Note_1"
                required
                name="narration"
                onChange={(e) => {
                  handleInputs(e);
                }}
              ></textarea>
            </div>
            <div className="New_Violation_save_btn">
              <button className="View" type="submit">
                Save
              </button>
              <button
                className="Various_search_btn"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default NewViolation;
