export const logIn = (data) => {
    return { type: "LOGIN", data: data };
};

export const loginState = (data) => {
    return { type: "LOGINSTATE", data: data };
};

export const empDetails = (data) =>{
    return { type: "EMPNAME", data: data};
}

export const refreshLogin = (accessToken,refreshToken) =>{
    return { type: "REFRESH", data: {accessToken, refreshToken}}
}
