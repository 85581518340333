import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import EMPLOYEEMASTER from "./pages/Registration";
import EmployeeDatabase from "./pages/EmployeeDatabase";
import EmployeeDetails from "./pages/EmployeeDetails";
import Login from "./pages/Login";
import { useSelector } from "react-redux";
import NotFound from "./components/NotFound";
import AllEmployees from "./pages/AllEmployees";
import Home from "./pages/Home";
import ViewViolations from "./pages/ViewViolations";
import EmpAttendance from "./pages/EmpAttendance";
import RecordAttendance from "./pages/RecordAttendance";
import Forbidden from "./components/Forbidden";
import NewViolation from "./pages/NewViolation";
import EmpDocumentation from "./pages/EmpDocumentation";
import Analytics from "./pages/Analytics";
import Performance from "./pages/Performance";
import Navbar from "./components/NavbarDashboardHR";
import ClientHome from "./pages/Client/ClientHome";
import ClientProfile from "./pages/Client/ClientProfile";
import ClientAttendance from "./pages/Client/ClientAttendance";
import ClientPerformance from "./pages/Client/ClientPerformance";
import ClientViolations from "./pages/Client/ClientViolations";
import ClientTrips from "./pages/Client/ClientTrips";
import LeaveRequest from "./pages/Client/LeaveRequest";
import TERMSCREATIONOFFERRELATED from "./pages/TERMSCREATION-OFFERRELATED";
import LEAVEAPPLICABLESETUPFORTHEYEAR from "./pages/LEAVEAPPLICABLESETUPFORTHEYEAR";
import LETTEROFOFFER from "./pages/LETTEROFOFFER";
import PAYTAXBANDS from "./pages/PAYTAXBANDS";
import BENEFITMASTER from "./pages/BENEFITMASTER";
import MONTHLYDEDUCTIONS from "./pages/MONTHLYDEDUCTIONS";

import LEAVETYPESETUP from "./pages/LEAVETYPESETUP";
import EMPLOYEESALARYDETAILS from "./pages/EMPLOYEESALARYDETAILS";
import LOANAPPLICATION from "./pages/LOANAPPLICATION";
import PAYROLLPROCESSING from "./pages/PAYROLLPROCESSING";
import EMPLOYEELEAVEREQUEST from "./pages/EMPLOYEELEAVEREQUEST";

import PaySlip from "./pages/Client/PaySlip";

import UploadDocument from "./pages/Client/UploadDocument";
import Expensespostingsbyoneself from "./pages/Client/Expensespostingsbyoneself";
import EmailToEmployer from "./pages/Client/EmailToEmployer";
import UnderConstruction from "../src/components/UnderConstruction";
import DepartmentMaster from "./pages/DepartmentMaster";
import DesignationMaster from "./pages/DesignationMaster";
function App() {
  const isLoggedIn = useSelector((state) => state.loginstatereducer);

  const ProtectRoute = ({ children }) => {
    return isLoggedIn ? children : <Navigate to={"/"} />;
  };

  const RouteLogin = ({ children }) => {
    return isLoggedIn ? <Navigate to={"/home"} /> : children;
  };

  function sensitiveFunctionDontEdit() {
    const expTime = 60 * 60 * 1000;
    let timer = null;

    function resetTimer() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        sessionStorage.removeItem("persistantState");
        window.location.reload(false);
      }, expTime);
    }
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);
    window.addEventListener("touchmove", resetTimer);
    window.addEventListener("touchstart", resetTimer);
    window.addEventListener("touchcancel", resetTimer);
    window.addEventListener("touchend", resetTimer);
    resetTimer();
  }
  useEffect(sensitiveFunctionDontEdit, []);

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<NotFound />} />
        <Route path="/authfailed" element={<Forbidden />} />
        <Route path="/Navbar" element={<Navbar />} />
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectRoute>
              <Home />
            </ProtectRoute>
          }
        />
        <Route
          path="/employeedb"
          element={
            <ProtectRoute>
              <EmployeeDatabase />
            </ProtectRoute>
          }
        />
        <Route
          path="/EMPLOYEEMASTER"
          element={
            <ProtectRoute>
              <EMPLOYEEMASTER />
            </ProtectRoute>
          }
        />
        <Route
          path="/EmployeeDetails"
          element={
            <ProtectRoute>
              <EmployeeDetails />
            </ProtectRoute>
          }
        />
        <Route
          path="/AllEmployees"
          element={
            <ProtectRoute>
              <AllEmployees />
            </ProtectRoute>
          }
        />
        <Route
          path="/violations"
          element={
            <ProtectRoute>
              <ViewViolations />
            </ProtectRoute>
          }
        />
        <Route
          path="/empattendance"
          element={
            <ProtectRoute>
              <EmpAttendance />
            </ProtectRoute>
          }
        />
        <Route
          path="/RecordAttendance"
          element={
            <ProtectRoute>
              <RecordAttendance />
            </ProtectRoute>
          }
        />
        <Route
          path="/newviolation"
          element={
            <ProtectRoute>
              <NewViolation />
            </ProtectRoute>
          }
        />
        <Route
          path="/analytics"
          element={
            <ProtectRoute>
              <Analytics />
            </ProtectRoute>
          }
        />
        <Route
          path="/empdocumentation"
          element={
            <ProtectRoute>
              <EmpDocumentation />
            </ProtectRoute>
          }
        />
        <Route
          path="/empPerformance"
          element={
            <ProtectRoute>
              <Performance />
            </ProtectRoute>
          }
        />
        {/* client */}
        <Route
          path="/ClientHome"
          element={
            <ProtectRoute>
              <ClientHome />
            </ProtectRoute>
          }
        />
        <Route
          path="/ClientProfile"
          element={
            <ProtectRoute>
              <ClientProfile />
            </ProtectRoute>
          }
        />
        <Route
          path="/ClientAttendance"
          element={
            <ProtectRoute>
              <ClientAttendance />
            </ProtectRoute>
          }
        />
        <Route
          path="/ClientPerformance"
          element={
            <ProtectRoute>
              <ClientPerformance />
            </ProtectRoute>
          }
        />
        <Route
          path="/ClientViolations"
          element={
            <ProtectRoute>
              <ClientViolations />
            </ProtectRoute>
          }
        />
        <Route
          path="/ClientTrips"
          element={
            <ProtectRoute>
              <ClientTrips />
            </ProtectRoute>
          }
        />
        <Route
          path="/LeaveRequest"
          element={
            <ProtectRoute>
              <LeaveRequest />
            </ProtectRoute>
          }
        />
        <Route
          path="/UploadDocument"
          element={
            <ProtectRoute>
              <UploadDocument />
            </ProtectRoute>
          }
        />
        <Route
          path="/Expensespostingsbyoneself"
          element={
            <ProtectRoute>
              <Expensespostingsbyoneself />
            </ProtectRoute>
          }
        />
        <Route
          path="/EmailToEmployer"
          element={
            <ProtectRoute>
              <EmailToEmployer />
            </ProtectRoute>
          }
        />
        <Route
          path="/UnderConstruction"
          element={
            <ProtectRoute>
              <UnderConstruction />
            </ProtectRoute>
          }
        />
        <Route
          path="/*"
          element={
            <ProtectRoute>
              <NotFound />
            </ProtectRoute>
          }
        />
        <Route
          path="/PaySlip"
          element={
            <ProtectRoute>
              <PaySlip />
            </ProtectRoute>
          }
        />
        <Route
          path="/LEAVETYPESETUP"
          element={
            <ProtectRoute>
              <LEAVETYPESETUP />
            </ProtectRoute>
          }
        />
        <Route
          path="/TERMSCREATION-OFFERRELATED"
          element={
            <ProtectRoute>
              <TERMSCREATIONOFFERRELATED />
            </ProtectRoute>
          }
        />
        <Route
          path="/LETTEROFOFFER"
          element={
            <ProtectRoute>
              <LETTEROFOFFER />
            </ProtectRoute>
          }
        />
        <Route
          path="/DEPARTMENTSMASTER"
          element={
            <ProtectRoute>
              <DepartmentMaster />
            </ProtectRoute>
          }
        />
        <Route
          path="/LEAVEAPPLICABLESETUPFORTHEYEAR"
          element={
            <ProtectRoute>
              <LEAVEAPPLICABLESETUPFORTHEYEAR />
            </ProtectRoute>
          }
        />
        <Route
          path="/DESIGNATIONMASTER"
          element={
            <ProtectRoute>
              <DesignationMaster />
            </ProtectRoute>
          }
        />
        <Route
          path="/PAYTAXBANDS"
          element={
            <ProtectRoute>
              <PAYTAXBANDS />
            </ProtectRoute>
          }
        />
        <Route
          path="/BENEFITMASTER"
          element={
            <ProtectRoute>
              <BENEFITMASTER />
            </ProtectRoute>
          }
        />
        <Route
          path="/EMPLOYEESALARYDETAILS"
          element={
            <ProtectRoute>
              <EMPLOYEESALARYDETAILS />
            </ProtectRoute>
          }
        />
        <Route
          path="/LOANAPPLICATION"
          element={
            <ProtectRoute>
              <LOANAPPLICATION />
            </ProtectRoute>
          }
        />
        <Route
          path="/PAYROLLPROCESSING"
          element={
            <ProtectRoute>
              <PAYROLLPROCESSING />
            </ProtectRoute>
          }
        />
        <Route
          path="/EMPLOYEELEAVEREQUEST"
          element={
            <ProtectRoute>
              <EMPLOYEELEAVEREQUEST />
            </ProtectRoute>
          }
        />
        <Route
          path="/MONTHLYDEDUCTIONS"
          element={
            <ProtectRoute>
              <MONTHLYDEDUCTIONS />
            </ProtectRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
